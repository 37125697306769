import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { BoxConfigInputsModule } from '../box-config-inputs/box-config-inputs.module';
import { WidgetsModule } from '../widgets/widgets.module';
import { AppSelectionModule } from '../../shared/app-selection/app-selection.module'
import { ListPanelComponent } from './list-panel/list-panel.component';
import { SearchPanelComponent } from './search-panel/search-panel.component';
import { SearchPanelDialogComponent } from './search-panel/search-panel-dialog/search-panel-dialog.component';
import { ListPanelDialogComponent } from './list-panel/list-panel-dialog/list-panel-dialog.component';
import { NavigationPanelComponent } from './list-panel/navigation-panel/navigation-panel.component';
import { AttributeMappingComponent } from './list-panel/attribute-mapping/attribute-mapping.component';
// import { DataTableComponent, NavigateConfirmationComponent } from './list-panel/data-table/data-table.component';
import { FormPanelComponent } from './form-panel/form-panel.component';
import { DetailsPanelComponent } from './details-panel/details-panel.component';
import { DetailsPanelDialogComponent } from './details-panel/details-panel-dialog/details-panel-dialog.component';
import { CollectOptionsDialogComponent } from './list-panel/collect-options-dialog/collect-options-dialog.component'

import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { FormPanelDialogComponent } from './form-panel/form-panel-dialog/form-panel-dialog.component';
// import { PanelFilterConfigComponent } from './shared-components/panel-filter-config.component';
import { ConnectionSelectionModule } from 'src/app/shared/connection-selection/connection-selection.module';
import { ActionSelectionModule } from 'src/app/shared/action-selection/action-selection.module';
import { ListPanelModule } from './list-panel/list-panel.module';
import { ParameterInputsModule } from 'src/app/shared/parameter-inputs/parameter-inputs.module';
import { SecurityConfigModule } from "../../shared/security/config/security-config/security-config.module";
import { BaseSelectionModule } from 'src/app/shared/base-selection/base-selection.module';
import { FilterConfigModule } from 'src/app/shared/query-components/filter-config/filter-config.module';
import { SortConfigModule } from 'src/app/shared/query-components/sort-config/sort-config.module';
import { WidgetBuilderModule } from '../widget-builder/widget-builder.module';
import { WidgetsWrapperModule } from '../widget-wrapper/widget-wrapper.module';
// import { SharedSpecializedComponentsModule } from './shared-components/shared-components.module';
import { AttributeSelectionModule } from 'src/app/shared/query-components/attribute-selection/attribute-selection.module';


@NgModule({
  declarations: [
    // ListPanelComponent,
    SearchPanelComponent,
    SearchPanelDialogComponent,
    // ListPanelDialogComponent,
    // NavigationPanelComponent,
    // AttributeMappingComponent,
    // DataTableComponent,
    FormPanelComponent,
    FormPanelDialogComponent,
    DetailsPanelComponent,
    DetailsPanelDialogComponent,
    CollectOptionsDialogComponent,
    // NavigateConfirmationComponent
    // PanelFilterConfigComponent,
    // PanelSortConfigComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SpinnerModule,
    BoxConfigInputsModule,
    WidgetsModule,
    AppSelectionModule,
    ConnectionSelectionModule,
    ActionSelectionModule,
    // ListPanelModule,
    ParameterInputsModule,
    SecurityConfigModule,
    BaseSelectionModule,
    // SharedSpecializedComponentsModule
    // QueryComponentsModule
    FilterConfigModule,
    SortConfigModule,
    WidgetBuilderModule,
    WidgetsWrapperModule,
    AttributeSelectionModule
  ],
  // entryComponents: [NavigateConfirmationComponent],
  providers: [SpinnerService],
  exports: [
    // ListPanelComponent,
    SearchPanelComponent,
    SearchPanelDialogComponent,
    // ListPanelDialogComponent,
    // NavigationPanelComponent,
    // AttributeMappingComponent,
    // DataTableComponent,
    FormPanelDialogComponent,
    FormPanelComponent,
    DetailsPanelComponent,
    DetailsPanelDialogComponent,
    // PanelFilterConfigComponent,
    // PanelSortConfigComponent
  ]
})
export class SpecializedPanelsModule { }
