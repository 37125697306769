import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, OnInit, Output, EventEmitter, ElementRef, Input } from '@angular/core';
import { WidgetService } from '../../services/widget-service.service';
import { environment } from 'src/environments/environment';
import { PageService } from '../../services/page-service.service';
import { DeviceInfoService } from 'src/app/shared/services/device-info.service';

@Component({
  selector: 'app-builder-menu-tools',
  templateUrl: './builder-menu-tools.component.html',
  styleUrls: ['./builder-menu-tools.component.css'],
  // host: {
  //   class: "container-with-thin-scrollbar"
  // }
})
export class BuilderMenuToolsComponent implements OnInit {

  data: any = [];
  boundEvent: any;
  starchPanelOpenState:boolean = false
  panelPanelOpenState:boolean = false;
  connectionPanelOpenState: boolean = false;
  sectionsVisibilityMap = {
    display: true,
    standard: true,
    dateTime: true,
    media: true,
    charts: true,
    connections: true,
    panels: true,
    starch: true
  }
  environment = environment

  @Input() sectionsToHide: any;

  constructor(
    private elementRef:ElementRef,
    public widgetService: WidgetService,
    public deviceInfo: DeviceInfoService,
    public pageService: PageService){}

  ngOnInit(): void {
    console.log("sections to hide", this.sectionsToHide)
    if(this.sectionsToHide?.length){
      this.sectionsToHide.forEach(section => {
        this.sectionsVisibilityMap[section] = false
      })
    }
    console.log("sections vis map", this.sectionsVisibilityMap)
  }

  @Output() createPanel = new EventEmitter<string>();

  dropWidget(event: CdkDragDrop<string[]>, type) {
    // console.log("drop event", event, type)
    // this.widgetService.widgetDragNotifier(type)
    this.pageService.newWidgetDragEnd.next(event)
  }

  dragStarted(){
    console.log("drag started")
    this.pageService.widgetSelectedForDrag({ widgetId: "__NEW__" })
  }

  dragMove(event){
    // event.stopPropagation()
    // console.log("dragMove fired", event)
    this.pageService.newWidgetDragMove.next(event)
  }

  // ----------methods----------
  newWidget(widgetName: string){
    console.log("widgetName", widgetName)
    this.createPanel.emit(widgetName)
  }
  newSearchPanel(){
    console.log("new search panel clicked")
    this.createPanel.emit("searchPanel")
  }
  onMouseDown(event){
    console.log("onMouseDown fired", event)
    this.boundEvent = this.elementRef.nativeElement.querySelector('#labelbutton').addEventListener('mousemove', (e)=>{
      console.log("mousemove detected", e)
    })

    this.elementRef.nativeElement.querySelector('#labelbutton').addEventListener('mouseup', ()=>{
      console.log("time to remove event listener")
    })

  }
}
