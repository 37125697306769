import { Component, Input, NgZone, OnInit } from '@angular/core';
import { FormService } from '../form.service';
import { WidgetUtilityService } from 'src/app/bloom/services/widget-utility.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BoxService } from 'src/app/bloom/services/box-service.service';
import { WidgetManager } from 'src/app/bloom/models/WidgetManager';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-form-responses',
  templateUrl: './responses.component.html',
  styleUrls: ['./responses.component.scss']
})
export class ResponsesComponent implements OnInit {

  attributes: any = []
  // formCode: any
  form: any
  formPanel: any
  boxId: any
  boxObjectId: any
  token: any;
  conType: any
  listPanel: any
  isReady: boolean = false
  isBrowser: any;

  @Input() formCode: any;

  constructor(
    private formService: FormService,
    private widgetUtilityService: WidgetUtilityService,
    private spinnerService: SpinnerService,
    public authService: AuthServiceService,
    public connectionService: ConnectionService,
    public router: Router,
    private boxService: BoxService,
    private _snackbar: MatSnackBar,
    @Inject(PLATFORM_ID) platformId?: Object
    ) {
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;
  }

  ngOnInit(): void {

    this.spinnerService.hide()
    this.loadForm()
  }

  async loadForm(){
    console.log("responses loaded")
    this.form = this.formService.formMeta.value;
    if(this.formCode && !this.form){
      try{
        this.form = await this.formService.getFormByCode(this.formCode, null, "published")
      }catch(e){
        console.log("error in fetching form", e)
      }
    }
    console.log("form loaded", this.form)

    // find the form panel within
    this.form.panels.forEach(panel => {
      if(panel.type == "formpanel"){
        this.formPanel = panel

        // set local values
        this.boxId = this.formPanel.boxId != "starch" ? this.formPanel.boxId : this.formPanel.baseMap?.box_id
        this.boxObjectId = this.formPanel.boxObjectId
        this.token = this.formPanel.boxConfigToken
        this.conType = 'token';
        if(this.formPanel.boxId != "starch"){
          this.conType = '';
        }
      }
    })
    await this.createListPanel()
  }

  async createListPanel(){
    // 1. fetch attributes
    await this.getAttributes()

    // 2. create list attributes
    let intelliSensed = this.widgetUtilityService.intelliSenseAttribute(this.attributes, 5);
      intelliSensed.forEach(attr => {
      attr['sortEnabled'] = false
      attr['show_hide'] = false
      attr['fieldType'] = 'attribute'
      attr['widgetType'] = 'label'
      attr['isColumnSelected'] = true
    });
    let listAttributes = intelliSensed;

    // 3. create list panel
    let primaryAttribute = this.attributes.find(attr => attr.primary);
    let panelConfig = this.listPanelConfig(primaryAttribute);
    panelConfig.listAttributes = listAttributes;
    panelConfig.listWidgetSet = this.generateWidgetSet(listAttributes);
    this.listPanel = panelConfig
    this.isReady = true
    console.log("list panel config created", panelConfig)
  }

  async getAttributes(){
    let payloadOptions = {}
    if(this.formPanel.type == "formpanel"){
      payloadOptions["relationObject"] = "starch_relationship"
    }
    let res: any = await this.boxService.getAttributes(this.token, this.boxId, this.boxObjectId, this.formPanel.attributeOptions, null, this.conType, payloadOptions)
    this.attributes = res.result
    if(this.form.attribute_name_map){
      this.attributes.forEach(element => {
        for (const key in this.form.attribute_name_map) {
          if(element.__id == key) element.name = this.form.attribute_name_map[key].wName;
        }
      });
    }

    console.log("attributes fetched", this.attributes)
    return this.attributes
  }

  listPanelConfig(primaryAttribute){
    let basicConfig:any = {
      "type": "listpanel",
      "layout": "flex-start",
      "widgets": [],
      "boxObjectId": this.boxObjectId,
      "listAttributes": [],
      "aditionalAttributesAllowed": false,
      "pageSize": 20,
      "loadInitialData": true,
      "paginationEnabled": true,
      "paginationType": "pagebypage",
      "defaultListSize": 10,
      "noDataMessage": "No form response recorded.",
      "attributeOptions": [],
      "getFnOptions": [],
      "listPanelTitle": `Responses for ${this.form.title}`,
      'hideTitle': false,
      background: {color: "#FFFFFF"},
      "viewTypes": {
        "views": [
          "table",
          "card",
          "board"
        ],
        "defaultView": "table",
        "boardStatusColumn": "",
        "userCanChoose": true,
        "canSelectFields": true,
        "table": {
          "name": "table",
          "displayName": "Table",
          "userCanChoose": true,
          "icon": "list"
        },
        "card": {
          "name": "card",
          "displayName": "Card",
          "userCanChoose": true,
          "icon": "grid_on"
        },
        "board": {
          "name": "board",
          "displayName": "Board",
          "userCanChoose": false,
          "icon": "view_kanban"
        }
      },
      "primaryAttribute": primaryAttribute,
      "filter": {
        "filterEnabled": false,
        "filterItems": []
      },
      "sort": {
        "sortEnabled": false,
        "sortAttributes": []
      },
      "listWidgetSet": {},
      "userFilterEnabled": true,
      "userSortEnabled": true
    }

    if(this.formPanel.boxId == "starch"){
      basicConfig.boxId = "starch";
      basicConfig.baseMap = {
        "box_id": this.formPanel.baseMap.box_id,
        "base_id": this.formPanel.baseMap.base_id
      }
      basicConfig.baseId = this.formPanel.baseMap.base_id;
      basicConfig.boxConfigToken = this.token;
    } else {
      basicConfig.boxId = this.formPanel.boxId;
      basicConfig.boxName = this.formPanel.boxName || "";
      basicConfig.connectionId = this.formPanel.connectionId || "";
    }
    return basicConfig;
  }

  generateWidgetSet(listAttributes){
    let newWidgetSet: any = {}

    for (let i = 0; i < listAttributes.length; i++) {
      let widgetType: string = listAttributes[i].widgetType == 'NA' ? listAttributes[i].fieldType : listAttributes[i].widgetType;
      const attrId = listAttributes[i].__id;
      newWidgetSet[attrId] = WidgetManager.getWidget(widgetType || 'label')
    };
    console.log("widget set prepared", newWidgetSet)
    return newWidgetSet
  }

  goBack(){
    console.log("will navigate to build/", this.formCode)
    this.router.navigate(["form", "build", this.formCode], { fragment: "edit" })
  }

}
