
import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { FlowService } from '../../flow.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConnectionDialogComponent } from "../../../shared/dialog/connection-dialog/connection.dialog";
import { ThemeService } from 'src/app/shared/services/theme.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { FlowPublishDialogComponent } from '../flow-publish-dialog/flow-publish-dialog.component';
import { PLATFORM_ID } from '@angular/core';
import { Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import panzoom from 'panzoom';
import { WebSocketService } from 'src/app/core/services/SocketService';
import { FlowConfigurationComponent } from '../common/flow-configuration/flow-configuration.component';
import { ScrollService } from 'src/app/core/services/ScrollService';
import { FlowContentService } from '../../flow-content.service';


const uuid = require('uuid');

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss']
})
export class SetupComponent implements OnInit {

  selectedFlowId: string = "";
  isEditFlow: boolean = false;
  isShowExecute: boolean = false;

  sourceFields: any = [];

  sourceFieldMap: any = {
    sourceFields: [],
    stepSourceFields: []
  }

  intentMap:any = {

  }
  ready: boolean = false
  saveUntouched: boolean = false;
  editMode: boolean = true;

  loadedFlow: any;
  isPublishedFetched: boolean = false;
  publishedFlow: any;
  isBrowser: any;

  @ViewChild('canvas') canvasElement: ElementRef;
  currentRunningStep: any;
  userChangesSubscriptionFlows: any;
  isPeriodicSave: boolean;
  isFlowConfigure: boolean;
  @ViewChild('flowConfigurationComponent') flowConfigurationComponent: FlowConfigurationComponent;
  panzoomInitialized: any;
  isFlowNameChange: boolean;
  isFlowTriggerConfigure: boolean;
  isPublishedFlowLoaded: boolean = true;

  constructor(
    private dialog: MatDialog,
    private spinner: SpinnerService,
    public fs: FlowService,
    private route: ActivatedRoute,
    public router: Router,
    public snackBar: MatSnackBar,
    private themeService: ThemeService,
    private connectionService: ConnectionService,
    private ngZone: NgZone,
    private authService: AuthServiceService,
    private cd: ChangeDetectorRef,
    @Inject(PLATFORM_ID) platformId: Object,
    private renderer: Renderer2,
    private websocketService: WebSocketService,
    private cdr: ChangeDetectorRef,
    private scrollService: ScrollService,
    private flowContentService: FlowContentService
    ) {
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;
  }

  flowMap: any = {
    name: "New Flow",
    description: "",
    isActive: false,
    trigger: {}
  };

  stepMap: any = {
    step_type: "action",
    box_id: "",
    action: "",
    connection: ""
  }

  selectedTab: any = 0;
  stepTabs: any = { 0: "action", 1: "control" }
  triggers: any = this.flowContentService.triggers;

  boxEvents: any = [];
  boxEvent: any;
  selectedEventBox: any;
  selectedTrigger: any;
  activeStepIndex: number = -1;
  stepLoaded: boolean[] = []; // Array to track loaded steps
  loadedSteps: { [stepIndex: number]: boolean } = {};

  toolDragSub: any;
  triggerConfigOpen: boolean = false
  generalInfoOpen: boolean = false
  @ViewChild('zoomableElement', { static: true }) zoomableElement!: ElementRef;
  private panZoomInstance: any;

  async ngOnInit() {
    // this.scrollService.hideScrollBar(true);

    this.fs.flowNameChange.subscribe((data: any) => {
      if(data){
        console.log("flowNameChange req", data);
        this.isFlowNameChange = true;
        this.isShowExecute = false;
        this.isFlowConfigure = false;
        this.cdr.detectChanges();
      }
    })

    this.isFlowConfigure = true;
    this.userChangesSubscriptionFlows = this.fs.$userMadeChanges.subscribe((isChanged) => {
      if(this.editMode && this.flowMap._id && isChanged) {
        this.periodicSaveInit();
      }
    })

    this.fs.$updatedFlowMeta.subscribe((data: any) => {
      if(this.editMode && this.flowMap._id == data._id){
        this.flowMap = data
      } else if (this.publishedFlow._id == data._id){
        this.publishedFlow = data
      }
    })

    this.route.params.subscribe(params => {
      console.log("params", params)
      if (params['id']) {
        this.selectedFlowId = params['id'];
        this.isEditFlow = true;
      }
    })

    if (
      !this.connectionService.selectedWorkSpace &&
      !this.authService.loggedIn
    ) {
      this.authService.authCheck();
      this.authService.authCheckPositive.subscribe((authStatus) => {
        //if logged in
        if (authStatus) {
          this.spinner.show();
          console.log('logged in');
          this.themeService.loadTheme();
          this.themeService
            .getExistingTheme(this.authService.profile._id)
            .then((res: any) => {
              if (res?.data != null) {
                console.log(res);
                this.themeService.settings_Id = res?.data[0]._id;
                let theme = res.data[0].themeSetting.theme;
                this.themeService.setTheme(theme);
                this.themeService.currentLocale = res.data[0].localeSetting;
                this.themeService.textDirection =
                  res.data[0].themeSetting.direction;
                if (res.data[0].themeSetting.direction == 'rtl') {
                  this.themeService.enableDirMode('rtl');
                }
              }
            });
            this.initialize()
        } else {
          // redirect to login page

          this.ngZone.run(() => {
            this.router.navigate(['../']);
          });

          return;
        }
      });
    } else if (this.connectionService.selectedWorkSpace) {
      this.initialize()
    } else {
      let res = await this.connectionService.getWorkSpaceId()
      this.initialize()
    }

    this.toolDragSub = this.fs.flowToolCreation.subscribe(async (tool) => {
      this.handleCreateTool(tool)
    })

    this.websocketService.serverEvent$.subscribe((data) => {
      // Use this data to update UI or perform other actions
      if(data?.action == 'ends') {
        this.focusCurrentStep(true);
      } else if (data?.action == 'starts') {
        this.currentRunningStep = 0;
        this.focusCurrentStep();
      } else {
        if(data?.data?.steps){
          let length =  data?.data?.steps.length || 0;
          this.currentRunningStep = length > 0 ? length : 0;
          this.focusCurrentStep();
        }
      }

    });

    this.isFlowConfigure = false;
  }

  ngOnDestroy() {
    this.scrollService.hideScrollBar(false);
    if (this.panZoomInstance) {
      this.panZoomInstance.dispose();
    }
  }

  editTrigger(){

    this.isFlowTriggerConfigure = true;
    console.log("isFlowTriggerConfigure", this.isFlowTriggerConfigure)
    this.isFlowConfigure = false;
    this.isFlowNameChange = false;
    this.activeStepIndex = -1;
  }

  selectedConfigureFlow(step, index){
    this.isFlowConfigure = true;
    this.isShowExecute = false;
    this.isFlowTriggerConfigure = false;
    this.cdr.detectChanges();
    console.log("[setup select] step, index", step, index, this.sourceFieldMap)
    let sourceFields = this.sourceFieldMap.stepSourceFields[index];
    this.flowConfigurationComponent.selectedFlow(step, index, sourceFields)
  }

  handleCreateTool(event?: any){
    console.log("tool creation event", event)
    if(event.eventType == "dragstart") return
    if(!this.fs.draggedToolData) return
    let tool = this.fs.draggedToolData
    this.fs.resetDragToolData()
    console.log("reached setup", tool);
    // console.log("edit mode", this.editMode)
    if(!this.editMode) {
      console.log("non edit mode, cant add step")
      this.snackBar.open("Can not edit view-only flow", "", {duration: 3000})
      return
    }
    // console.log("will add step")
    let dummyStepIndex = this.loadedFlow?.steps?.findIndex(step =>step.step_type == "placeholder")
    // console.log("dummy step index is", dummyStepIndex)
    let spliceIndex = dummyStepIndex - 1
    if(dummyStepIndex < 0) spliceIndex = this.loadedFlow?.steps?.length - 1 || 0
    this.addNewStep(spliceIndex, tool.toolType, tool)
  }

  // getLoadedFlow(){
  //   return this.loadedFlow
  // }

  async initialize(){
    if (this.isEditFlow) {
      this.spinner.show();
      let flow;
      console.log("editMode", this.editMode);
      if(this.editMode){
        console.log("flowMap", this.flowMap)
        console.log("selectedFlowId", this.selectedFlowId)
        if(!this.flowMap) this.flowMap = await this.fs.getFlow(this.selectedFlowId);
        flow = this.flowMap;
        if(!this.publishedFlow) this.fetchPublishedFlow()
        this.triggerConfigOpen = true
        this.generalInfoOpen = false
      }else{
        if(!this.publishedFlow) await this.fetchPublishedFlow()
        flow = this.publishedFlow
      }
      this.loadedFlow = flow
      console.log("flow loaded", this.loadedFlow)

      await this.olderFlowMigration()

      this.cd.detectChanges();
      this.spinner.hide();
      if(flow?.trigger_type) this.selectedTrigger = this.triggers.find(trigger => trigger.id === flow?.trigger_type);
      if(flow?.trigger_type == 'webhook'){
        if(!flow.trigger.payload_data) flow.trigger.payload_data = [];
        if(!flow.trigger.query_data) flow.trigger.query_data = [];
        this.sourceFields = flow.trigger.payload_data.concat(flow.trigger.query_data)
      }
      this.ready = true

    }else{
      try {
        const totalCount: number = this.fs.totalFlowCount;
        this.flowMap = {
          name: `New Flow ${totalCount + 1}`,
          description: "",
          isActive: false,
          trigger: {},
          trigger_type: "schedule",
          code: uuid.v4()
        };
        this.loadedFlow = this.flowMap
        console.log("loadedFlow initialized", JSON.parse(JSON.stringify(this.loadedFlow)))
        // this.selectedTrigger =
        await this.onSelectTrigger({
          id: "schedule",
          title: "Schedule",
          icon: "schedule"
        });
        this.ready = true
        this.saveUntouched = true;

      } catch (error) {
        console.error("Error fetching total flow count:", error);
      }
    }

    this.cdr.detectChanges();
    setTimeout(() => this.initializePanZoom(), 0);
  }

  async olderFlowMigration(){


    /**
     * 1. check if flow is old
     * 2. create a published flow record
     * 3. consider old record as draft and update with published_flow_id
     * 4. schedule further jobs with published flow id
     */
    if(this.flowMap.code) return  // no action needed for new flows
    console.log("old flow migration")
    let newCode = uuid.v4()
    let toBePublished = JSON.parse(JSON.stringify(this.flowMap))
    delete toBePublished['_id']
    toBePublished['is_published'] = true
    toBePublished['isActive'] = false
    toBePublished['code'] = newCode
    toBePublished['published_at'] = new Date().toISOString() + "|date";
    toBePublished['created_at'] = new Date().toISOString() + "|date";
    toBePublished['modified_at'] = new Date().toISOString() + "|date";
    let publishedId = await this.fs.createFlow(toBePublished)
    toBePublished['_id'] = publishedId
    this.publishedFlow = toBePublished
    this.flowMap['published_flow_id'] = publishedId
    this.flowMap['code'] = newCode
    this.flowMap['isActive'] = false
    await this.fs.saveFlow(JSON.parse(JSON.stringify(this.flowMap)), false)
  }

  ngAfterViewChecked(): void {}
  ngAfterViewInit(): void {}


   focusCurrentStep(isDisableFocus:boolean = false) {
    // Ensure all other steps are not focused
    this.loadedFlow?.steps?.forEach((_, index) => {
      const stepElement = document.getElementById(`step-${index}`);
      if (stepElement) {
        this.renderer.removeClass(stepElement, 'focused');
      }
    });

    // Focus the current step
    if(!isDisableFocus){
      const currentStepElement = document.getElementById(`step-${this.currentRunningStep}`);
      if (currentStepElement) {
        this.renderer.addClass(currentStepElement, 'focused');
        currentStepElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }



  addDummyStep(index: number){
    // console.log("add dummy step hit", index, "dragToolData", this.fs.draggedToolData)
    if(!this.fs.draggedToolData.toolType) return
    this.removeAllDummySteps()

    // console.log("before: loaded steps", JSON.parse(JSON.stringify(this.loadedFlow.steps || {})))
    if(index == undefined){
      index = this.loadedFlow.steps?.length || 0
    }
    let stepMap:any = {
      step_code: uuid.v4().substring(0, 5),
      step_type: "placeholder",
      box_id: "",
      action: "",
      connection: "",
      mapping: []
    }
    if(!this.loadedFlow.steps) {
      this.loadedFlow['steps'] = [stepMap]
      console.log("steps array initialized", JSON.parse(JSON.stringify(this.loadedFlow.steps)))
      return
    }
    this.loadedFlow.steps.splice(index, 0, stepMap)
    // console.log("after: loaded steps", JSON.parse(JSON.stringify(this.loadedFlow.steps)))
  }

  removeAllDummySteps(){
    if(!this.loadedFlow?.steps) return
    let filteredSteps = this.loadedFlow.steps.filter(step => step.step_type != 'placeholder')
    this.loadedFlow.steps = filteredSteps
  }

  clearDragToolData(){
    this.fs.resetDragToolData()
    this.removeAllDummySteps()
  }


  expandStep(index: number): void {
    console.log("expandStep: index", index)
    console.log("expandStep: activeStepIndex", this.activeStepIndex)
    if (this.activeStepIndex === index) {
      // return;
      this.activeStepIndex = -1;
    } else {
      this.activeStepIndex = index;
      console.log("activeStepIndex set", this.activeStepIndex)
      this.loadStep(index);
      this.loadedSteps[index] = true;
      console.log("loadedSteps", this.loadedSteps)
    }
  }


  loadStep(index: number): void {
    this.stepLoaded[index] = true;
  }

  async onSelectedTrigger(trigger){
    this.selectedTrigger = trigger;
    if(this.flowMap.trigger_type == "schedule"){
      this.getSourceFields();
    }
  }

  async onSelectTrigger(trigger: any){
    if(!this.editMode) return
    this.selectedTrigger = trigger;
    this.flowMap.trigger_type = trigger.id;
    if(this.flowMap.trigger_type == "schedule"){
      this.getSourceFields();
    }

    if(this.isEditFlow){
      this.triggerConfigOpen = true
      this.generalInfoOpen = false
    } else {
      this.triggerConfigOpen = false;
      this.generalInfoOpen = true;
    }

    this.isFlowTriggerConfigure = false;
    this.fs.userMadeChanges.next(true)
    if (!this.selectedFlowId) await this.insertOrUpdateFlow(true);
  }

  // async deleteStep(steps: any, i: any) {
  //   let dialogRef = this.dialog.open(ConnectionDialogComponent, {
  //     data: { name: "this Step" },
  //   });
  //   var diologResult = await dialogRef.afterClosed().toPromise();
  //   if (diologResult) {
  //     steps.splice(i, 1);
  //     this.sourceFieldMap.sourceFields.splice(i + 1, 1);
  //     this.constructStepSourceFields();
  //   }
  //   console.log("steps", steps)
  // }

  async stepDeleted(i, steps){
    console.log("deleted e, steps", i, steps)
    let dialogRef = this.dialog.open(ConnectionDialogComponent, {
      data: { name: "this Step" },
    });
    var diologResult = await dialogRef.afterClosed().toPromise();
    if (diologResult) {
      steps.splice(i, 1);
      this.sourceFieldMap.sourceFields.splice(i + 1, 1);
      this.constructStepSourceFields();
    }
    console.log("steps", steps)
    if(i == this.activeStepIndex && this.isFlowConfigure){
      this.isFlowConfigure = false;
      this.activeStepIndex = -1;
    }
    this.fs.userMadeChanges.next(true)
  }

  setStep(e, step){
    step = e;
    console.log("step now", step)
    this.fs.userMadeChanges.next(true)
  }

  addNewStep(i, stepType, stepData?: any) {
    if(!this.loadedFlow) return
    // console.log("add new step hit", i, stepType)
    // console.log("loaded flow", this.loadedFlow)
    if(!this.editMode) return
    console.log("i", i)
    this.removeAllDummySteps()
    // console.log("loadedFlow", JSON.parse(JSON.stringify(this.loadedFlow)))
    if (!this.loadedFlow.steps) this.loadedFlow.steps = [];

    if(this.loadedFlow.trigger_type == 'webhook'){
      this.sourceFields = this.loadedFlow.trigger.payload_data.concat(this.loadedFlow.trigger.query_data)
    }

    let stepMap:any = {
      step_code: uuid.v4().substring(0, 5),
      step_type: stepType || "action",
      box_id: stepData?.['__id'] || "",
      box_name: stepData?.['name'] || "",
      box_logo_url: stepData?.['logo'] || "",
      action: "",
      connection: "",
      mapping: []
    }

    if(this.intentMap.isIntent){
      stepMap.intentMap = {};
      stepMap.intentMap.isIntent = true;
      stepMap.intentMap.parentStep = this.intentMap.parentStep;
    }

    this.loadedFlow.steps.splice(i + 1, 0, stepMap);

    // console.log("after adding", JSON.parse(JSON.stringify(this.loadedFlow)))
    if (this.loadedFlow.steps.length === 1) {
      this.expandStep(0);
      this.selectedConfigureFlow(this.loadedFlow.steps[0], 0)
    }else{
      const newStepIndex = i + 1;
      this.expandStep(newStepIndex);
      this.selectedConfigureFlow(this.loadedFlow.steps[newStepIndex], newStepIndex)
    }
    this.fs.userMadeChanges.next(true)
}


  addNewBranchStep(i, stepType, step, branchCondition){
    console.log("addNewBranchStep i", i)
    console.log("addNewBranchStep stepType", stepType)
    console.log("addNewBranchStep step", step)
    console.log("addNewBranchStep branchCondition", branchCondition)
    if(!this.editMode) return
    let stepMap:any = {
      step_code: uuid.v4().substring(0, 5),
      step_type: stepType || "action",
      box_id: "",
      action: "",
      connection: "",
      mapping: []
    }

    let controlStep = step;

    //find the control step;
    if(step?.step_type == "action"){
      let controlStepCode = step?.control_step?.step;
      for (let index = 0; index < this.flowMap.steps.length; index++) {
        const element = this.flowMap.steps[index];
        if(element.step_code == controlStepCode){
          controlStep = element;
          break;
        }
      }
    }

    console.log("controlStep", controlStep)

    stepMap.control_step = {
      step: controlStep.step_code,
      condition: branchCondition
    }
    controlStep.control.branch[branchCondition].steps.push({step: stepMap.step_code})

    let increament = 1;
    this.flowMap.steps.splice(i + increament, 0, stepMap);

    console.log("this.flowMap.steps", this.flowMap.steps)
    this.fs.userMadeChanges.next(true)
  }

  drop(e){
    if(!this.editMode) return
    console.log("draged", e);
    this.array_move(this.flowMap.steps, e.previousIndex, e.currentIndex);
    this.fs.userMadeChanges.next(true)
  }

  array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  };

  cancel() {
    this.router.navigate(["flow"]);
  }



  intentAdded(type, step, index){
    if(!this.editMode) return
    if(type == "add"){
      if(!step.intentMap) step.intentMap = {}
      step.intentMap.isIntent = true;
      if(!this.intentMap.parentStep) {
        step.intentMap.parentIntent = true
      }
      step.intentMap.isIntentRemoved = false;
      this.intentMap.isIntent = true;
      this.intentMap.parentStep = step.step_code;

      this.flowMap.steps.forEach((element, i) => {
        if(i > index){
          this.flowMap.steps[i].intentMap = {
            isIntent: true,
            parentStep: this.intentMap.parentStep
          }
        }
      });

    } else if (type == "remove"){
      this.intentMap.isIntent = false;
      this.intentMap.parentStep = null;
      if(step.intentMap.parentIntent) step.intentMap.isIntent = false;
      if(!step.intentMap.parentIntent)step.intentMap.isIntentRemoved = true;

      this.flowMap.steps.forEach((element, i) => {
        if(i > index){
          this.flowMap.steps[i].intentMap = {
            isIntent: false,
            parentStep: null
          }
        }
      });

    }
    this.fs.userMadeChanges.next(true)
  }


  setSourceFields(i, fields){
    console.log("[setSourceFields] i, fields", i, fields)
    console.log("source field map", this.sourceFieldMap);
    this.sourceFieldMap.sourceFields[i] = fields;
    this.constructStepSourceFields();
  }

  setSourceFieldsConfigure(e){
    console.log("setSourceFieldsConfigure =====>", e)
    this.setSourceFields(e.i, e.fields);
  }

  setStepConfigure(e){
    console.log("setStepConfigure =====>", e)
    this.setStep(e.e, e.step);
  }

  actionChanged(action, step){
    console.log("actionChanged =====>", action)
    if(!step.intentMap) step.intentMap = {};
    if(action.crudType == 'R' || action.functionName == 'get') step.intentMap.isIntentAvailable = true;
    this.fs.userMadeChanges.next(true)
  }

  stepActionChangedConfigure(e){
    console.log("stepActionChangedConfigure =====>", e)
    this.actionChanged(e.action, e.step)
  }

  userMadeChanges(e, i) {
    this.fs.userMadeChanges.next(true);
  }

  constructStepSourceFields(){
    this.sourceFieldMap.stepSourceFields = [];
    this.sourceFieldMap.sourceFields.forEach((element, i) => {
      let array = [];
      if(i == 0 ){
        array = this.sourceFieldMap.sourceFields[i];
      } else array = this.sourceFieldMap.stepSourceFields[i - 1]?.concat(this.sourceFieldMap.sourceFields[i]);
      this.sourceFieldMap.stepSourceFields.push(array);
    });

    console.log("this.sourceFieldMap", this.sourceFieldMap)
  }


  getSourceFields() {
    let flow = this.editMode ? this.flowMap : this.publishedFlow
    if(flow.trigger_type == "app_event"){
      let event = this.boxEvents.find(
        (event) => event.__id == flow.trigger?.event
      );
      this.boxEvent = event;
      if(this.boxEvent?.options){
        if(!flow.trigger?.webhook) flow.trigger.webhook = {optionMap: {}};
        if(!flow.trigger.webhook?.optionMap) flow.trigger.webhook.optionMap = {};
      }
      this.sourceFields = event.fields;
      this.sourceFieldMap.sourceFields[0] = this.sourceFields;
    }
  }

  //on event app selected
  async appSelected(box: any) {
    if (!this.selectedFlowId) this.insertOrUpdateFlow(true);
  }

  stepTabSelected(e, step) {
    step.step_type = this.stepTabs[e];
  }

  setSelectedIndex(type) {
    var tabs = this.swap(this.stepTabs)
    return tabs[type];
  }

  async insertOrUpdateFlow(isNoNotify?: boolean) {
    if(!this.editMode) return
    console.log("insert or update hit")
    var result: any;
    try {
      this.spinner.show();

      if (this.selectedFlowId) {
        console.log("selected flow exists", this.selectedFlowId)
        // if(this.flowMap.trigger_type == 'app_event') this.flowMap.trigger.webhook = await this.setupTrigger();
        // if(this.flowMap.trigger_type == 'schedule') await this.scheduleJobs();
        this.flowMap.created_at = this.flowMap.created_at + "|date";
        this.flowMap.modified_at = new Date().toISOString() + "|date";
        result = await this.fs.saveFlow(this.flowMap, false);
      } else {
        console.log("first save")
        await this.firstPublish()
      }
      console.log("result", result)
      if (!isNoNotify) this.snackBar.open("Flow successfully saved")._dismissAfter(3000)
      this.spinner.hide();
      this.fs.userMadeChanges.next(false)
      if (!isNoNotify) this.router.navigate(["flow"]);
    } catch (er) {
      console.log("er: ", er);
      this.spinner.hide();
    }
  }

  async periodicSaveInit() {
    console.log("periodicSaveInit", this.isPeriodicSave)
    if(this.isPeriodicSave) return;
    this.isPeriodicSave = true;
    await new Promise(resolve => setTimeout(resolve, 3000));
    try{
      await this.insertOrUpdateFlow(true);
      this.isPeriodicSave = false;
            //reset the userMadeChanges variable to sense subsequent changes as fresh
      console.log("updated", this.isPeriodicSave)
      this.fs.userMadeChanges.next(false);
    } catch(e){
      console.error("error in updating flow meta")
    }
  }

/**
 * 1. create a record with isPublished true
 * 2. pick up published id
 * 3. create a new draft with published_flow_id = newID
 * 4. replace the current flowMap with new draft record
 *
 */
  async firstPublish(){
    console.log("firstPublish hit")
    let publishedFlow = await this.createPublishedFlow()
    console.log("new published id", publishedFlow)

    let newDraft = await this.createDraft(publishedFlow)
    console.log("new draft", newDraft)

    this.flowMap = this.loadedFlow = newDraft
    this.selectedFlowId = this.flowMap._id;
    // this.router.navigate(['flow/edit', this.flowMap._id]);
    console.log("first publish routine done")
  }

  /**
   * creates the first draft flow record
   * @param publishedFlow
   * @returns newly created draft flow record
   */
  async createDraft(publishedFlow: any){
    let newDraft = JSON.parse(JSON.stringify(this.flowMap))
    console.log("new draft assigned", JSON.parse(JSON.stringify(newDraft)))

    newDraft.modified_at = new Date().toISOString() + "|date";
    newDraft.created_at = new Date().toISOString() + "|date";

    newDraft['published_flow_id'] = publishedFlow._id
    newDraft['isActive'] = true

    try{
      let res = await this.fs.createFlow(newDraft)
      console.log("new draft record created", res)
      newDraft['_id'] = res
    }catch(e){
      console.log("new draft flow record could not be created", e)
    }
    return newDraft
  }


  /**
   * creates published flow record
   * @returns newly created record object
   */
  async createPublishedFlow(){

    let publishedFlow = JSON.parse(JSON.stringify(this.flowMap))

    // publishedFlow['is_published'] = true
    publishedFlow['isActive'] = true

    publishedFlow['modified_at'] = new Date().toISOString() + "|date";
    publishedFlow['created_at'] = new Date().toISOString() + "|date";

    try{
      console.log("ready to create published flow", publishedFlow)
      let response = await this.fs.createFlow(publishedFlow)
      console.log("published created successfully", response)
      publishedFlow['_id'] = response
    }catch(e){
      console.log("published flow could not be updated", e)
      throw e
    }

    return publishedFlow
  }


  swap(json) {
    var ret = {};
    for (var key in json) {
      ret[json[key]] = key;
    }
    return ret;
  }

  async navigateToExecutionPage() {
    if(!this.publishedFlow) await this.fetchPublishedFlow()
    const url = `/flow/instance/${this.publishedFlow._id}`;
    window.open(url, '_blank');
  }

  openPublishDialog(){
    this.isPublishedFlowLoaded = false;
    console.log("openPublishDialog hit", this.editMode)
    if(!this.editMode) {
      this.snackBar.open("Switch to Draft tab to publish.", "Ok", {duration: 2000})
    }
    console.log("publish flow hit", this.flowMap)
    let dialogRef = this.dialog.open(FlowPublishDialogComponent, {
      minWidth: '60vw',
      minHeight: '30vh',
      data: {
        flow: this.flowMap,
        boxEvents: this.boxEvents
      },
    })

    dialogRef.afterClosed().subscribe(async data => {
      console.log("publish config dialog resolved", data)
      await this.fetchPublishedFlow();
      this.isPublishedFlowLoaded = true;
      this.isEditFlow = true;
      if (!data) {
        console.log("publish configuration dialog closed unexpectedly")
        return
      }
    })
  }

  toolbarAction(action: any){
    console.log("toolbar action detected", action)
    if(action.actionType == 'save'){
      this.insertOrUpdateFlow(true)
    }else if(action.actionType == 'executions'){
      this.navigateToExecutionPage()
    }else if(action.actionType == 'publish'){
      this.openPublishDialog()
    }
  }

  async tabChanged(data){
    console.log("tab changed", data)
    if(data.index == 0){
      this.editMode = true
      this.isShowExecute = false;
      this.loadedFlow = this.flowMap
      this.initialize()
    }else if(data.index == 1){
      this.editMode = false;
      if(!this.publishedFlow) this.isShowExecute = false;
      // if(!this.publishedFlow) await this.fetchPublishedFlow()
      this.loadedFlow = this.publishedFlow
      await this.initialize();

      this.isShowExecute = true;
    }
  }

  async activeToggleChanged(event){
    console.log("active toggle changed", event)
    this.publishedFlow['isActive'] = event.checked
    try{
      if(!this.publishedFlow) await this.fetchPublishedFlow()
      console.log("published flow fetched", this.publishedFlow)
      this.publishedFlow['isActive'] = event.checked
      let res = await this.fs.updateFlow(this.publishedFlow)
      console.log("active state updated")
    }catch(e){
      console.error("could not change active status")
    }
  }

  async fetchPublishedFlow(){
    try{
      this.publishedFlow = await this.fs.getFlow(this.flowMap?.published_flow_id)
      console.log("published flow fetched", this.publishedFlow)
    }catch(e){
      console.error("could not fetch published flow", e)
    }
    return this.publishedFlow
  }

  initializePanZoom() {
    console.log("this.zoomableElement", this.zoomableElement)
    if (this.zoomableElement && this.zoomableElement.nativeElement) {
      this.panZoomInstance = panzoom(this.zoomableElement.nativeElement, {
        zoomSpeed: 0.1,
        minZoom: 0.5,
        maxZoom: 5,
        bounds: true,
        boundsPadding: 0.1,
        // beforeWheel: (event) => !event.ctrlKey, // Disable zoom unless Ctrl is pressed
        // beforeMouseDown: (event) => !event.ctrlKey // Prevent double-click zoom
      });

      // Handle wheel events for scrolling and zooming
      this.zoomableElement.nativeElement.addEventListener('wheel', (event: WheelEvent) => {
        if (event.ctrlKey) {
          // Prevent default scroll behavior when zooming
          event.preventDefault();
          const scaleFactor = event.deltaY < 0 ? 1.1 : 0.9;
          if (this.panZoomInstance && typeof this.panZoomInstance.zoom === 'function') {
            const currentZoom = this.panZoomInstance.getTransform().scale;
            this.panZoomInstance.zoom(currentZoom * scaleFactor, { animate: true });
          } else {
            console.error('Zoom method not available on panzoom instance');
          }
        } else {
          // Allow vertical scrolling when Ctrl is not pressed
          this.zoomableElement.nativeElement.scrollTop += event.deltaY;
          event.stopPropagation(); // Ensure scrolling only affects this element
        }
      });

       // Prevent double-click zoom
       this.zoomableElement.nativeElement.addEventListener('dblclick', (event: MouseEvent) => {
        event.preventDefault();
      });
      this.panzoomInitialized = true;
    } else {
      console.error('zoomableElement is not defined or not available');
    }
  }


}

