<div class="spcover"
[ngStyle]="margin? {'margin-left': margin.left + '%', 'margin-right': margin.right + '%'} : {'margin-left': '0%', 'margin-right': '0%'}"
>
  <!----------------------------------------------------------------------------------------->
    <!-- <h2 class="searchTitleViewMode">Search area</h2> -->
    <div class="viewMode" fxLayout="column" fxLayoutAlign="space-between stretch">



      <div class="headingArea" fxLayout="row" fxLayoutAlign="center center">
        <div class="headingText" *ngIf="!panelMeta.hideTitle">{{ panelMeta.searchPanelTitle || 'Search' }}</div>
      </div>

      <div *ngIf="builderMode" fxLayout="row" fxLayoutAlign="end center">
        <mat-icon class="settings" style="cursor: pointer; opacity: 50%;" (click)="openSettings()">
          settings
        </mat-icon>
      </div>

      <!-- <div fxLayout fxLayoutAlign="space-between center">

      </div> -->


      <div
        class="inputFieldsWrap"
        fxLayout="row wrap" fxLayoutAlign="{{panelMeta.alignment || 'flex-start'}} center"
      >
        <div
          *ngFor="let widgetMeta of searchInputWidgets; let i = index"
          class="wid"
          [fxFlex]="widgetMeta.gridX * 8.3" [fxFlex.lt-md]="widgetMeta.gridX * 2 * 8.3" [fxFlex.lt-sm]="12 * 8.3"
        >
          <!-- <mat-icon [class.invisible]="(selectedWidgetId == widget.id || hoveredWidget == widget.id) ? false : true" class="draghandle" cdkDragHandle>drag_indicator</mat-icon> -->

          <ng-container *ngIf="builderMode; else elseTemplate">
            <app-widget-builder
              [widgetMeta]="widgetMeta"
              [panelMeta]="panelMeta"
              [builderMode]="builderMode"
              (newWidgetMeta)="emitNewWidgetMeta($event, i)"
              (userInputReceived)="searchValueIncoming($event, widgetMeta)"
              style="width: 100%; height: 100%;"
              >
            </app-widget-builder>
          </ng-container>
          <ng-template #elseTemplate>
            <app-widget-wrapper
              [widgetMeta]="widgetMeta"
              [panelId]="panelMeta.id"
              [builderMode]="builderMode"
              (userInputReceived)="searchValueIncoming($event, widgetMeta)"
              style="width: 100%; height: 100%;"
            >
            </app-widget-wrapper>
          </ng-template>
        </div>
      </div>

      <ng-container *ngIf="panelMeta.sortAttributes?.length">
        <!-- <div class="subheading">sort by</div> -->
        <ng-container *ngFor="let sort of panelMeta.sortAttributes">
          <div
            class="wid"
            fxLayout="row" fxLayoutAlign="start center"
          >
          <mat-checkbox  [checked]="sort.checked" (change)="sortAttributeChanged($event, sort)" color="primary">
            Sort by {{ sort.name || sort.__id }} </mat-checkbox>
            <mat-icon matTooltip="Descending Order" color="accent" style="cursor: pointer;" (click)="sortOrderChange(sort, 'DESC')" *ngIf="!sort.order || sort.order === 'ASC'">south</mat-icon>
            <mat-icon matTooltip="Ascending Order" color="accent"  style="cursor: pointer;" (click)="sortOrderChange(sort, 'ASC')" *ngIf="sort.order && sort.order === 'DESC'">north</mat-icon>
            <!-- <mat-icon [class.invisible]="(selectedWidgetId == widget.id || hoveredWidget == widget.id) ? false : true" class="draghandle" cdkDragHandle>drag_indicator</mat-icon> -->
            <!-- <app-checkbox
              [widgetMeta]="widgetMeta"
              [panelId]="panelMeta.id"
              [builderMode]="false"
              (raiseCheckboxChange)="sortAttributeChanged($event)"
            >
            </app-checkbox> -->
          </div>
        </ng-container>
      </ng-container>

      <div fxLayoutAlign="{{panelMeta.alignment || 'flex-start'}} center" style="margin-top: -10px;">
        <!-- BUTTON -->
        <div
          class="wid"
          fxLayout="row" fxLayoutAlign="start center"
        >
          <app-button
            #widgets
            (raiseButtonClicked)="executeManualSearch()"
            [widgetMeta]="searchButtonWidgetMeta"
            [panelId]="panelMeta.id"
            [builderMode]="false"
          >
          </app-button>
        </div>

        <div>
          <div *ngIf="showClear" class="showClearButton" (click)="clearWidgetValues()">Clear</div>
        </div>
      </div>
    </div>
</div>
