<mat-nav-list>
    <!-- <mat-form-field appearance="outline" *ngIf="
        sidenavService.leftSidenavDesktopWidth > 4.5 ||
        sidenavService.mobileQuery?.matches
      ">
      <mat-label>Organization</mat-label>
      <mat-select [ngModel]="authService.selectedOrganization">
        <mat-option *ngFor="let org of authService.organizations" [value]="org.id">
          {{ org.name }}
        </mat-option>
      </mat-select>
    </mat-form-field> -->
    <!-- <mat-divider></mat-divider> -->
    <div class="itemwrap">
      <mat-list-item (click)="onHomeClicked()" fxLayout="row" fxLayoutAlign="start center" class="navitem-admin">
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon *ngIf="sidenavService.leftSidenavDesktopWidth == 4.5;
                else homeTooltip" matTooltip="Home" style="color:rgb(21, 161, 212)">home</mat-icon>
            <ng-template #homeTooltip>
              <mat-icon class="navitem-home" style="color:rgb(21, 161, 212)">home</mat-icon>
            </ng-template>
            <span style="margin-left: 15px" [fxHide]="
                sidenavService.leftSidenavDesktopWidth == 4.5 &&
                !sidenavService.mobileQuery?.matches
              ">
              Home
            </span>
          </div>
      </mat-list-item>
    </div>

    <div class="itemwrap">
      <mat-list-item (click)="onConnectionClicked()" fxLayout="row" fxLayoutAlign="start center" class="list-item">
        <div fxLayout="row" fxLayoutAlign="start center">
          <img [src]="baseURL + 'connection/appiworks-connection-logo.png'" style="height: 30px; width: 30px" *ngIf="sidenavService.leftSidenavDesktopWidth == 4.5;
              else connectionTooltip" matTooltip="Connection">
          <ng-template #connectionTooltip>
            <img [src]="baseURL + 'connection/appiworks-connection-logo.png'" style="height: 30px; width: 30px">
          </ng-template>
          <span class="connectiontextSpan" [fxHide]="
              sidenavService.leftSidenavDesktopWidth == 4.5 &&
              !sidenavService.mobileQuery?.matches
            ">
            Connection
          </span>
        </div>
      </mat-list-item>
    </div>

    <div class="itemwrap">
      <mat-list-item (click)="onStarchClicked()" fxLayout="row" fxLayoutAlign="start center" class="list-item">
        <div fxLayout="row" fxLayoutAlign="start center">
          <img [src]="baseURL + 'starch/appiworks-starch-logo.png'" style="height: 30px; width: 30px" *ngIf="sidenavService.leftSidenavDesktopWidth == 4.5;
              else starchTooltip" matTooltip="Starch">
          <ng-template #starchTooltip>
            <img [src]="baseURL + 'starch/appiworks-starch-logo.png'" style="height: 30px; width: 30px">
          </ng-template>
          <span class="connectiontextSpan" [fxHide]="
              sidenavService.leftSidenavDesktopWidth == 4.5 &&
              !sidenavService.mobileQuery?.matches
            ">
            Starch
          </span>
        </div>
      </mat-list-item>
    </div>


    <div class="itemwrap">
      <mat-list-item (click)="onFormClicked()" fxLayout="row" fxLayoutAlign="start center" class="list-item">
        <div fxLayout="row" fxLayoutAlign="start center">
          <img [src]="baseURL + 'form-builder/aw-form-builder-logo.png'" style="height: 30px; width: 30px" *ngIf="sidenavService.leftSidenavDesktopWidth == 4.5; else FormTooltip" matTooltip="Form">
          <ng-template #FormTooltip>
            <img [src]="baseURL + 'form-builder/aw-form-builder-logo.png'" style="height: 30px; width: 30px">
          </ng-template>
          <span class="flowtextSpan" [fxHide]="
              sidenavService.leftSidenavDesktopWidth == 4.5 &&
              !sidenavService.mobileQuery?.matches
            ">Form</span>
        </div>
      </mat-list-item>
    </div>

    <div class="itemwrap">
      <mat-list-item (click)="onBloomClicked()" fxLayout="row" fxLayoutAlign="start center" class="list-item">
        <div fxLayout="row" fxLayoutAlign="start center">
          <img [src]="baseURL + 'bloom/appiworks-bloom-logo.png'" style="height: 30px; width: 30px" *ngIf="sidenavService.leftSidenavDesktopWidth == 4.5; else BloomTooltip" matTooltip="Bloom">
          <ng-template #BloomTooltip>
            <img [src]="baseURL + 'bloom/appiworks-bloom-logo.png'" style="height: 30px; width: 30px">
          </ng-template>
          <span class="bloomtextSpan" [fxHide]="
              sidenavService.leftSidenavDesktopWidth == 4.5 &&
              !sidenavService.mobileQuery?.matches
            ">Bloom</span>
        </div>
      </mat-list-item>
    </div>

    <div class="itemwrap">
      <mat-list-item (click)="onFlowClicked()" fxLayout="row" fxLayoutAlign="start center" class="list-item">
        <div fxLayout="row" fxLayoutAlign="start center">
          <img [src]="baseURL + 'flow/appiworks-flow-logo.png'" style="height: 30px; width: 30px" *ngIf="sidenavService.leftSidenavDesktopWidth == 4.5; else FlowTooltip" matTooltip="Flow">
          <ng-template #FlowTooltip>
            <img [src]="baseURL + 'flow/appiworks-flow-logo.png'" style="height: 30px; width: 30px">
          </ng-template>
          <span class="flowtextSpan" [fxHide]="
              sidenavService.leftSidenavDesktopWidth == 4.5 &&
              !sidenavService.mobileQuery?.matches
            ">Flow</span>
        </div>
      </mat-list-item>
    </div>


  <mat-accordion *ngIf="permission.hasAccess(['super.admin'])">
    <mat-expansion-panel class="mat-elevation-z0" hideToggle [ngClass]="{
        adminsize:
          sidenavService.leftSidenavDesktopWidth == 4.5 &&
          !sidenavService.mobileQuery?.matches
      }">
      <mat-expansion-panel-header class="navitem-admin-content">
        <mat-list-item fxlayoutAlign="start center" class="navitem-admin">
          <mat-icon *ngIf="sidenavService.leftSidenavDesktopWidth == 4.5;
              else AdminTooltip" matTooltip="Admin" style="color:rgb(21, 161, 212)">admin_panel_settings
          </mat-icon>
          <ng-template #AdminTooltip>
            <mat-icon style="color:rgb(21, 161, 212)">admin_panel_settings</mat-icon>
          </ng-template>
          <span class="adminSpan" [fxHide]="
              sidenavService.leftSidenavDesktopWidth == 4.5 &&
              !sidenavService.mobileQuery?.matches
            ">
            Admin
          </span>
        </mat-list-item>
      </mat-expansion-panel-header>
      <mat-selection-list [multiple]="false">
        <mat-list-option *ngIf="permission.hasAccess(['super.admin'])"
         matTooltip="Roles"
         (click)="router.navigate(['roles'],{queryParams:{workspaceid: this.connectionService.workSpaceId,
          authorization: encodeURIComponent(
            this.connectionService.preAuthenticatedToken
          )}})">
          <span fxLayout="row" fxLayoutAlign="start center"
            *ngIf="sidenavService.leftSidenavDesktopWidth != 4.5">
            <mat-icon class="adminIcon">manage_accounts</mat-icon>
            <span class="adminTextSpan">Roles</span>
          </span>
          <span fxLayout="row" fxLayoutAlign="center center" *ngIf="sidenavService.leftSidenavDesktopWidth == 4.5">
            <mat-icon class="adminIcon">manage_accounts</mat-icon>
            <span [fxHide]="sidenavService.leftSidenavDesktopWidth == 4.5 && !sidenavService.mobileQuery?.matches">
              Roles
            </span>
          </span>
        </mat-list-option>
        <mat-list-option *ngIf="permission.hasAccess(['super.admin'])" matTooltip="Privileges" (click)="router.navigate(['privileges'],{queryParams:{workspaceid: this.connectionService.workSpaceId,
          authorization: encodeURIComponent(
            this.connectionService.preAuthenticatedToken
          )}})">
          <span fxLayout="row" fxLayoutAlign="start center"
            *ngIf="sidenavService.leftSidenavDesktopWidth != 4.5">
            <mat-icon class="adminIcon">gavel</mat-icon>
            <span class="adminTextSpan">Privileges</span>
          </span>
          <span fxLayout="row" fxLayoutAlign="center center" *ngIf="sidenavService.leftSidenavDesktopWidth == 4.5">
            <mat-icon class="adminIcon">gavel</mat-icon>
            <span [fxHide]="sidenavService.leftSidenavDesktopWidth == 4.5 && !sidenavService.mobileQuery?.matches">
              Privileges
            </span>
          </span>
        </mat-list-option>
      </mat-selection-list>
    </mat-expansion-panel>
  </mat-accordion>
</mat-nav-list>
