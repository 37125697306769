<div class="queryWrapper">
  <div fxLayoutAlign="start center">Configure your query</div>

  <div *ngIf="componentsToShow.attributes" class="componentWrap attributeSelectionWrap">
    <div class="componentTitle">Attributes</div>
    <app-attribute-selection
      [boxObjectAttributes]="boxObjectAttributes"
      [listAttributes]="existingConfig?.attributesConfig?.attributes || recommended || []"
      (listAttributeSelected)="attributesSelectionReceived($event)"
    ></app-attribute-selection>
    <mat-divider></mat-divider>
    <div style="height: .5rem"></div>
  </div>

  <div *ngIf="componentsToShow.filter" class="componentWrap filterWrap">
    <div class="componentTitle">Filters</div>
    <app-filter-config
      style="width: 100%;"
      [filter]="existingConfig?.filterConfig || { filterItems: [] }"
      [boxObjectAttributes]="boxObjectAttributes"
      [hideToggle]="false"
      [lockFilterType]="true"
      [fieldListingMap]="fieldListingMap"
      (selectedFilter)="filtersReceived($event)"
    >
    </app-filter-config>
    <mat-divider></mat-divider>
    <div style="height: .5rem"></div>
  </div>

  <div *ngIf="componentsToShow.sort" class="componentWrap sortWrap">
    <div class="componentTitle">Sort</div>
    <app-sort-config
      style="width: 100%;"
      [sort]="existingConfig?.sortConfig || { sortAttributes: [] }"
      [boxObjectAttributes]="boxObjectAttributes"
      [hideToggle]="false"
      (selectedSort)="sortReceived($event)"
    >
    </app-sort-config>
    <mat-divider></mat-divider>
    <div style="height: .5rem"></div>
  </div>


  <div *ngIf="componentsToShow.group" class="componentWrap groupByWrap">
    <div class="componentTitle">Group</div>
    <app-group-config
      style="width: 100%;"
      [group]="existingConfig?.groupConfig || { groupEnabled: true, attributes: [] }"
      [boxObjectAttributes]="boxObjectAttributes"
      [hideToggle]="true"
      (newGroupConfig)="groupReceived($event)"
    >
    </app-group-config>
    <mat-divider></mat-divider>
    <div style="height: .5rem"></div>
  </div>


  <div *ngIf="componentsToShow.limit" class="componentWrap limitWrap">
    <div class="componentTitle">Limit</div>
    <div style="padding: .5rem;">
      <mat-form-field style="width: 100%;">
        <mat-label>Number of records to fetch</mat-label>
        <input matInput type="number" [value]="existingConfig?.limit" (keyup)="limitReceived($event)">
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="supportApplyButton" class="emitButtonWrap" fxLayoutAlign="end center">
    <button mat-raised-button (click)="emitQuery()">Apply query</button>
  </div>
</div>
