import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  private sidenavs: any = {};
  showSideNav = true;
  mobileQuery: MediaQueryList;
  public leftSidenavDesktopWidth = 4.5;
  public sideNavModes = ['show', 'show-details', 'hide'];
  public currentSideNavMode = this.sideNavModes[0];
  constructor() { }

  public toggle(direction): void {
    this.sidenavs[direction].toggle();
  }

  public setSidenav(sidenav: MatSidenav, direction) {
    this.sidenavs[direction] = sidenav;
  }

  /**
   * setSidenavModes
   */
  public setSidenavWithModes(setSidenav?: any) {
    var sideNavMode = '';
    if (setSidenav) {
      sideNavMode = setSidenav;
    } else {
      sideNavMode = this.currentSideNavMode;
    }

    console.log('Current Sidenav mode =>', sideNavMode);

    var modeIdx = this.sideNavModes.indexOf(sideNavMode);
    var nextIdx = modeIdx + 1;

    var newIdx = nextIdx == this.sideNavModes.length ? 0 : nextIdx;
    sideNavMode = this.sideNavModes[newIdx];

    console.log('Next sidenav mode =>', sideNavMode);

    if (sideNavMode == 'show') {
      this.leftSidenavDesktopWidth = 4.5;
      this.toggle('left');
      console.log('show');
    } else if (sideNavMode == 'show-details') {
      console.log('show-details');
      this.close('left');
      this.leftSidenavDesktopWidth = 15;
      this.toggle('left');
      window.dispatchEvent(new Event('resize'));
    } else {
      this.leftSidenavDesktopWidth = 4.5;
      this.close('left');
      console.log('hide');
    }

    this.currentSideNavMode = sideNavMode;
  }

  public close(direction) {
    return this.sidenavs[direction].close();
  }

  public open(direction) {
    return this.sidenavs[direction].open();
  }
}
