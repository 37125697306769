import { Widget } from "./baseWidget";
import { DEFAULT_VALUE } from "./dynamicDefaultValues";

export class Time extends Widget {
  type = 'time';
  gridX = 6;
  minGridX = 3;
  config = {
    props: [
      "placeholder",
      "timeValue",
      'tooltip',
      'viewOnly',
      'defaultValue',
      'customOutputFormat'
    ],
    placeholder: {
      displayName: "Time Placeholder",
      value: "Enter time"
    },
    timeValue: {
      displayName: "Time Value",
      value: "",
      resetValue: '',
      type: 'text'
    },
    tooltip: {
      displayName: 'Tooltip',
      value: "",
      type: 'text',
      enabled: true
    },
    customOutputFormat: {
      displayName: "Custom output time format",
      // value: 'h:mm a',
      type: 'date-time-format-input',
      enabled: false,
      customTime: true,
      customTimeFormat: 'h:mm a'
    },
    viewOnly: {
      displayName: 'View Only Mode',
      description: 'When turned on, user can not change the value',
      value: false,
      type: 'boolean',
      resetValue: false
    },
    defaultValue: DEFAULT_VALUE,
  }

  constructor(id: number, name: string) {
    super(id, name)
    this.attachCommonConfig(this)
  }

  public getWidgetConfig(){
    let map = {
      valuePath: "config.timeValue.value"
    }
    return map;
  }

  public getWidgetMeta() {
    return this
  }
}
