import { Component, OnInit, Inject, Input, AfterViewInit, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { TokenUtil } from '../../../core/services/TokenUtil.service'
import { MetaService } from '../../services/meta-service';
import { PageService } from '../../services/page-service.service';
import { BoxService } from '../../services/box-service.service';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-widget-data-config',
  templateUrl: './widget-data-config.component.html',
  styleUrls: ['./widget-data-config.component.css']
})
export class WidgetDataConfigComponent implements OnInit, OnDestroy {

  @Input() widgetMeta;
  @Input() panelId;

  @Output() settingsChanged: EventEmitter<any> = new EventEmitter();

  // showSelectableConfiguration: boolean = false
  dataConfigType = 'regular' // regular || selection-type-widgets || chart-data-source

  data: any = {}
  pageMeta: any;
  boxObjectList: any = [];
  boxObjectFunctions: any;
  boxConfigToken: any;
  boxName: any;
  selectedConnectionId: any;
  selectedBoxId: any;
  selectedBoxName: any;
  selectedBoxAttribute: any = '';
  selectedBoxObjectId: any = '';
  selectedObjectFunction: any;
  selectedBoxObjectFunction: any;
  isDataReady: boolean = false;
  isBoxObjectListReady: boolean = false;
  isAttributesReady: boolean = false;
  isAttributesFormReady: boolean = false;
  isGotBoxObjectFunction: boolean = false;
  isReadyToSave: boolean = false;

  boxObjectFunctionInputLists: any = []

  isBoxSelected: boolean = false;
  isBoxObjectSelected: boolean = false;
  boxObjectAttributes: any = {
    names: []
  }
  attributesForm: UntypedFormGroup;
  boxUrl: string = environment.BOX_URL;

  // filteredBoxes: Observable<string>[];
  connectionList: any[] = []
  connectionListRaw: any[] = []
  connectionListLength: number = 0
  isReceivedConnections: boolean = false;

  boxObjects: any = [];
  isReceivedBoxObjects: boolean = false;

  filteredBoxes: any;
  filteredBoxObjects: any;

  selectBoxControl = new UntypedFormControl();
  selectBoxObjectControl = new UntypedFormControl();

  // ----------------------------- NEW VARS --------------------
  isBoxConfigError: boolean = false;
  boxConfigError: any;
  isBoxObjectConfigError: boolean = false;
  boxObjectConfigError: any;

  boxFunctions: any[]
  canGetBoxObjects: boolean = false
  firstHit: boolean = true;
  terminationError: boolean = false;
  terminationErrorMessage: string;
  attributeError: string;
  attributeOptions: any[]
  isOptionsToCollect: boolean = false;

  selectAttributeControl = new UntypedFormControl();
  filteredAttributes: any;
  isAttributeSpinner: boolean = false;
  gettingObjFunSpinner: boolean = false

  @ViewChild('attributeInput') attributeInput;

  constructor(
    // public dialogRef: MatDialogRef<AddConnectionDialogComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private MetaService: MetaService,
    private _snackBar: MatSnackBar,
    private http: HttpClient,
    private TokenUtil: TokenUtil,
    private pageService: PageService,
    private boxService: BoxService,
    private route: ActivatedRoute,
    private router: Router,
    private connectionService: ConnectionService
  ) { }

  ngOnInit(): void {
    // if(
    //   this.widgetMeta.type == 'select' ||
    //   this.widgetMeta.type == 'checkbox' ||
    //   this.widgetMeta.type == 'autocomplete' ||
    //   this.widgetMeta.type == 'chips' ||
    //   this.widgetMeta.type == 'radio'
    // ){
    //   this.showSelectableConfiguration = true
    // }
    this.widgetMeta = JSON.parse(JSON.stringify(this.widgetMeta))

    console.log("this.widgetMeta.config.props", this.widgetMeta.config)
    if(this.widgetMeta.config.props.findIndex(prop => this.widgetMeta.config[prop] && this.widgetMeta.config[prop]?.type == 'selection-widgets-options-config') > -1){
      // this.showSelectableConfiguration = true
      this.dataConfigType = 'selection-type-widgets'
    }
    if(this.widgetMeta.type == 'chart'){
      this.dataConfigType = 'chart-data-source'
    }

    this.MetaService.pageMeta.subscribe(meta => {
      this.pageMeta = meta;
      // this.generateBoxObjectList()
    })
    this.data.boxFunctionId = 'getobjects'

    this.filteredBoxes = this.selectBoxControl.valueChanges.pipe(
      startWith(''),
      map(value => this._boxFilter(value))
    );

    this.filteredBoxObjects = this.selectBoxObjectControl.valueChanges.pipe(
      startWith(''),
      map(value => this._boxObjectFilter(value))
    );

    if(this.widgetMeta.dataBindConfig){
      this.firstHit = false;
      let oldConfig = this.widgetMeta.dataBindConfig
      console.log("existing data bind config found", oldConfig)

      this.selectedBoxId = oldConfig.boxId
      this.selectedBoxName = oldConfig.boxName
      this.selectedBoxObjectId = oldConfig.boxObjectId
      this.selectedBoxAttribute = oldConfig.boxAttribute
      this.boxConfigToken = oldConfig.boxConfigToken
      this.selectedConnectionId = oldConfig.connectionId

      this.isBoxSelected = true
      this.isBoxObjectSelected = true

      // this.getBoxFunctions()
      this.getAttributes()
    }

    // FILTER FOR BOX ATTRIBUTES
    this.filteredAttributes = this.selectAttributeControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.__id)),
      map(value => this._attributeFilter(value))
    );

    //initialize boxName based on dataBindConfig in widgetMeta
    // if(this.widgetMeta.dataBindConfig !== undefined){
    //   this.pageMeta.connections.names.forEach(name => {
    //     if(this.pageMeta.connections[name]._id == this.widgetMeta.dataBindConfig.connectionId){
    //       console.log("found name", name)
    //       this.boxName = name
    //     }
    //   });
    // }

    // console.log("widget data config ngOnInit fired")
    //fetch all the connections (boxes)
    // this.getAllConnection()
  }

  ngOnDestroy() {
    // console.log("onDestroy fired for widget data config")
  }

  //-----------------------------------------FILTERS------------------------------------

  private _attributeFilter(value: string): string[] {
    // console.log("unselected in filter", this.unselectedListAttributes)
    const filterValue = value.toLowerCase();

    return this.boxObjectAttributes.filter(option => option.__id.toLowerCase().includes(filterValue));
  }

  //filter for box names autocomplete from the connections
  private _boxFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    let boxes: any = []

    //create an array of connectionTypes/boxIds by going over boxObjectList
    // this.pageMeta?.connections?.names.forEach(box => {
    //   boxes.push(box)
    // });

    this.connectionList.forEach(box => {
      boxes.push(box.name)
    })

    return boxes.filter(option => option.toLowerCase().includes(filterValue));
  }


  //filter for box Objects autocomplete for the selected box
  private _boxObjectFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    let boxObjectIds: any = []
    // console.log()

    //create an array of connectionTypes/boxIds by going over boxObjectList
    // this.pageMeta?.connections[this.selectedBoxName]?.boxObjects.forEach(boxObject => {
    //   // if(boxObject == this.selectedBox)
    //   boxObjects.push(boxObject)
    // });
    // console.log("box objects array:", boxObjects)

    // let boxObjectIds: any = []
    // boxObjects.forEach(b => {
    //   boxObjectIds.push(b.id)
    // });

    this.boxObjects.forEach(boxObject => {
      boxObjectIds.push(boxObject.__id)
    });

    return boxObjectIds.filter(option => option.toLowerCase().includes(filterValue));
  }


  //-----------------------------------------FUNCTIONS--------------------------------------

  displayFn(attr) {
    return attr && attr.__id ? attr.__id : ''
  }

  getAllConnection() {
    console.log('IN SEARCH PANEL CONFIG: HIT GETALLCONNECTIONS')
    if (!this.connectionService.workSpaceId) {
      console.log("workspace ID not found in organization service, cant fetch connections, need to sign in")

      //close the dialog by simulating click on close button
      // this.closeButton._elementRef.nativeElement.click()

      //redirect to homepagecd
      // console.log("navigating to home")
      // this.router.navigate(['../../'], { relativeTo: this.route })

      return
    }
    let that = this;
    let url = `${environment.SERVER_BASE_URL}/connection/${this.connectionService.workSpaceId}`;
    let token = this.TokenUtil.getStatelessToken()
    const headers = new HttpHeaders().set(
      'Authorization',
      'PreAuthenticatedToken ' + this.connectionService.preAuthenticatedToken
    );

    that.http.get(url, { headers }).subscribe(
      (response: any) => {
        console.log('IN SEARCH PANEL CONFIG: CONNECTIONS RECEIVED', response.data)
        this.connectionListLength = response.data?.length | 0;
        this.connectionListRaw = response.data;

        response.data.forEach(connection => {
          let connectionData = {
            name: connection.name,
            boxId: connection.box_id,
            _id: connection._id,
            boxObjects: [],
            boxConfigToken: connection.box_token
          }
          this.connectionList.push(connectionData)
        });

        console.log("connectionList initialized", this.connectionList)

        this.isReceivedConnections = true
      },
      (error) => {
        console.log(error);
      }
    );
  }

  /**
   * runs when a box is selected and further calls this.getBoxObjects() fn
   * @param event
   */
  // boxSelected(event: any) {
  //   console.log("selected box", event.option.value)
  //   this.selectedBoxName = event.option.value
  //   // this.selectedConnectionId = this.pageMeta.connections[this.selectedBoxName]._id
  //   // this.selectedBox = this.pageMeta.connections[this.selectedBoxName].boxId

  //   this.connectionList.forEach(box => {
  //     if (box.name == this.selectedBoxName) {
  //       this.selectedBoxId = box.boxId
  //       this.selectedBoxName = box.boxName
  //       this.boxConfigToken = box.boxConfigToken
  //     }
  //   })
  //   this.isBoxSelected = true;
  //   this.getBoxObjects()
  // }

   /** --------------------------------------boxSelected---------------------------
   * fires when a box is selected in box-select component
   * @param box the selected box will come from boxSelect component's output boxInput
   */
    boxSelected(box: any) {
      console.log("selected box", box)
      this.selectedBoxName = box.name
      this.selectedBoxId = box.box_id
      this.boxConfigToken = box.box_token
      this.selectedConnectionId = box._id

      // this.configChanged = true

      this.getBoxFunctions()

      // if box was selected before, now the input is changed. in that case, selected box object and attributes are invalid
      if (this.isBoxSelected) {
        // this.isBoxObjectSelected = false
        // this.isAtrributeReady = false
      } else {
        this.isBoxSelected = true;
      }
    }

    async getBoxFunctions(){
      this.gettingObjFunSpinner = true
      let res = await this.boxService.getBoxFunctions(this.selectedBoxId, this.boxConfigToken)
      this.gettingObjFunSpinner = false
      console.log("box functions received", res)
      this.boxFunctions = res

      // if boxFuntions has getobjects, enable/render boxObject selection component (*ngIf on isBoxSelected)
      if(!this.boxFunctions.find(fn => fn.__id == 'getobjects')){
        this.canGetBoxObjects = false
        this.isBoxObjectConfigError = true
        this.boxObjectConfigError['error'] = {}
        this.boxObjectConfigError['error']['message'] = "'getobjects' not available in box functions"
      }else if(!this.boxFunctions.find(fn => fn.__id == 'getattributes')){
        this.canGetBoxObjects = false
        this.isBoxObjectConfigError = true
        this.boxObjectConfigError['error'] = {}
        this.boxObjectConfigError['error']['message'] = "'getattributes' not available in box functions"
      }else{
        console.log("can get boxObjects, will enable box object selection component")
        this.canGetBoxObjects = true
      }
    }


  /** --------------------------------------getBoxObjects----------------------------------
   * once box is selected, this fn is called to get the box objects of the selected box
   * uses this.selectedBoxId and this.boxConfigToken
   */
  getBoxObjects() {
    if (!this.isBoxSelected) {
      console.log("box not selected, cant fetch box objects")
      return
    }

    this.boxService.getBoxObjects(this.selectedConnectionId)
      .then(boxObjects => {
        console.log("box objects received", boxObjects)
        this.boxObjects = boxObjects;
        this.isReceivedBoxObjects = true
      })
      .catch(err => {
        console.error("searchPanelDialog: getBoxObjects()", err)
        throw err
      })
  }


  /**
   * fires when a boxObject is selected from boxObject autocomplete
   * saves the boxObjectId in this.selectedBoxObjectId
   * also fetches the boxAttributes for selected boxObjectId
   * @param event
   */
  // boxObjectSelected(event: any) {
  //   console.log("box object selected", event.option.value)
  //   this.selectedBoxObjectId = event.option.value
  //   this.isBoxObjectSelected = true

  //   this.getAttributes()
  //     .then(res => {
  //       console.log("fetchAttributes() successful")
  //     })
  //     .catch(err => {
  //       console.error("error occured while fetching attributes", err)
  //     })
  // }


  /**
   * fires when a boxObject is selected from boxObject autocomplete
   * saves the boxObjectId in this.selectedBoxObjectId
   * also calls the getAttributes() once boxObject is selected
   * @param boxObject
   */
   async boxObjectSelected(boxObject: any) {
    console.log("box object selected", boxObject.__id)
    this.selectedBoxObjectId = boxObject.__id
    this.isBoxObjectSelected = true

    let objectFuntions: any = await this.boxService.getBoxObjectFuntions(this.selectedBoxId, this.selectedBoxObjectId, this.selectedConnectionId)
    console.log("box object functions received", objectFuntions)
    console.log("checking if array", Array.isArray(objectFuntions))
    // let getFn: any;
    // objectFuntions.forEach(fn => {
    //   if(fn.__id == 'get'){
    //     getFn = fn
    //   }
    // });
    let i = objectFuntions.findIndex(fn => fn.__id == 'get')
    let getFn = i >= 0 ? objectFuntions[i] : null
    console.log("if filter.options.filter", getFn)
    if(getFn && getFn.options && !getFn.options.filter){
      // this.panelMeta.loadInitialData = true
      // this.lockLoadInitialData = true
      console.log("filter not available. locked loadInitialData to true, getFn:", getFn)
      this.terminationError = false
      this.checkOptionsToCollect()
    } else if(!getFn){
      console.log("get function not found")
      this.terminationError = true
      this.terminationErrorMessage = `'get' function not found on ${this.selectedBoxObjectId} of ${this.selectedBoxId}.`
    } else{
      this.terminationError = false
      await this.checkOptionsToCollect()    // this will set array called attributeOptions
      if(!this.attributeOptions.length){
        this.getAttributes()
      }
    }
  }


  /**
   * looks for getAttributes in boxFuntions and does necessary steps to collect inputs before calling getAttributes
   */
   async checkOptionsToCollect(){
    console.log("box functions in checkOptionsToCollect", this.boxFunctions)
    if(!this.boxFunctions){
      await this.getBoxFunctions()
    }
    let i = this.boxFunctions.findIndex(fn => fn.__id == 'getattributes')
    let fn = i >= 0 ? this.boxFunctions[i] : null

    if(fn){
      // let input: any;
      // fn.input.list.forEach(ip => {
      //   if(ip == 'options'){
      //     input = ip
      //   }
      // });
      // if(input){
      if(fn.input.list.find(input => input == 'options')){
        console.log("options exists inside input")
        fn.input['options'].list.forEach((optionItem: string) => {
          fn.input['options'][optionItem]['value'] = ''
          this.attributeOptions.push(fn.input['options'][optionItem])
          this.isOptionsToCollect = true
        });
      } else{
        this.isOptionsToCollect = false
        this.attributeOptions = []
      }
      console.log("options to collect", this.attributeOptions)
    }else{
      this.attributeError = 'getting attributes not available on this connection and object'
    }
  }

  /**
   * scans through pageMeta.connections and generates a list of objects
   * { boxType, boxObject}
   * @returns works on this.boxObjectList
   */
  generateBoxObjectList() {
    this.boxObjectList = []
    this.pageMeta?.connections?.names.forEach(conn => {
      this.pageMeta?.connections?.[conn]?.boxObjects?.forEach(boxObject => {
        let obj = {
          connectionType: this.pageMeta?.connections?.[conn].boxId,
          connectionName: conn,
          boxObject: boxObject,
          boxConfigToken: this.pageMeta?.connections?.[conn].boxConfigToken
        }
        this.boxObjectList.push(obj)
      });
    });
    this.isBoxObjectListReady = true;
    console.log("boxObjectList generated", this.boxObjectList)
  }

  callGetAttributes(){
    console.log("options given", this.attributeOptions)
    this.getAttributes()
  }

  printConfig() {
    let url = this.boxUrl
      + '/' + this.data.boxId
      + '/' + this.data.boxObject;
    url = url + '/' + this.data.boxObjectFunction;
    this.data.url = url
  }

  /**
   * fires when a connection is selected by the user
   */
  onNgModelChange(event: any) {
    this.isDataReady = true
    console.log("boxObject selected", event)
    this.data.boxObject = event[0].boxObject
    this.data.boxId = event[0].connectionType
    this.data.boxConfigToken = event[0].boxConfigToken
    console.log("data updated", this.data)

    //using available data, fetch box object funtions
    this.getBoxObjectFunctions()
      .then((res: any) => {
        console.log("object functions received", res)
      })
      .catch(error => {
        console.error('ERROR OCCURED IN FETCHING OBJECT FUNCTIONS', error)
      })
  }


  /**
   * fetches available box object functions for selected box object
   * @param uses the information in this.data.boxObject
   * @returns populates this.boxObjectFunctions array and sets isGotBoxObjectFunction
   */
  async getBoxObjectFunctions() {
    console.log("getBoxObjectFunctions hit", this.selectedBoxObjectId, "AND", typeof this.selectedBoxObjectId)
    if (this.selectedBoxObjectId) {
      let that = this;
      // that.pgService.spinner = true;
      var url = `${this.boxUrl}/${this.selectedBoxId}/${this.selectedBoxObjectId}/functions`;
      console.log(url);

      var token = this.TokenUtil.getStatelessToken();
      const headers = new HttpHeaders()
        .set('Authorization', `Bearer ${token}`);

      await that.http.get(url, { headers: headers }).subscribe(
        (response: any) => {
          if (response) {
            // this.isConfig = true;
            console.log('getBoxObjectFunctions response', response);
            this.boxObjectFunctions = response.functions;
            this.isGotBoxObjectFunction = true;


            //check if get function exists and initialize boxObjectFunction as get by default
            response.functions.forEach(funcObject => {
              if (funcObject.__id == 'get') {
                this.selectedBoxObjectFunction = funcObject
                console.log("selectedBoxObjectFunction", this.selectedBoxObjectFunction)

                this.onChangeBoxObjectFunction()
              }
            });

          }
          // that.pgService.spinner = false;
        },
        (error) => {
          console.log('Error while getting Box object functions', error);
          this.boxObjectFunctions = [];
          this._snackBar.open('Error while getting Box object functions!!', 'OK');
          // that.pgService.spinner = false;
        }
      );
    } else {
      this.boxObjectFunctions = [];
      this._snackBar.open('box object not set');
    }
  }


  // /**
  //  * fires when user selects a boxObjectFunction from the dropdown
  //  * @param event
  //  * @returns
  //  */
  // boxObjectFunctionSelection(event: any){
  //   console.log("boxObjectfunctionSelection hit", event)
  //   this.selectedBoxObjectFunction = event.value;
  //   this.isDataReady = true;
  //   console.log("data updated", this.data)

  //   //remove the previous attributes form
  //   this.attributesForm.reset()
  //   this.isAttributesFormReady = false

  //   if(this.selectedBoxObjectFunction == 'create' || this.selectedBoxObjectFunction == 'update'){
  //     console.log("boxObjectFunction matched create or update")
  //     this.getAttributes()
  //   }else{
  //     console.log("not matched, need reset")
  //     if(this.attributesForm){
  //       console.log("entered not undefined")
  //     }
  //   }
  // }

  /**
  * fetches the attributes associated with selected boxObjectFunction
  */
  async getAttributes() {
    // if (!this.isBoxObjectSelected) {
    //   console.log("boxObject not selected")
    //   return
    // }
    // console.log("get attributes hit, current box object", this.selectedBoxId)
    // let payload = {
    //   parameters: {
    //     object: this.selectedBoxObjectId
    //   }
    // }
    // // console.log("payload prepared", payload)

    // let token = this.TokenUtil.getStatelessToken()
    // let headers = new HttpHeaders()
    //   .set('Authorization', `Bearer ${token}`)
    //   .set('boxConfigToken', this.boxConfigToken);

    // // let boxId = this.pageMeta.connections[this.selectedBox].boxId

    // let url = `${this.boxUrl}/${this.selectedBoxId}/getattributes`
    // console.log('url to hit', url)ks
    // console.log('payload', payload);
    // console.log('headers', headers);
    this.isAttributeSpinner = true
    await this.boxService.getAttributes(this.selectedConnectionId, this.selectedBoxId, this.selectedBoxObjectId, this.attributeOptions)
    .then((response: any) => {
      this.boxObjectAttributes = response.result
      this.isAttributesReady = true
      this.isAttributeSpinner = false

      console.log("attributes fetched", this.boxObjectAttributes)
      // this.filteredAttributes = this.boxObjectAttributes
      // this.filteredAttributes = this.boxObjectAttributes
      if(this.selectedBoxAttribute){
        this.selectAttributeControl.patchValue(this.selectAttributeControl)
        this.attributeInput.value = this.selectedBoxAttribute
      }
    })
    // return new Promise((resolve, reject) => {
    //   this.http
    //     .post(url, payload, { headers: headers })
    //     .subscribe(
    //       (response: any) => {
    //         if (!response) {
    //           console.log("invalid no response received")
    //           return
    //         }
    //         console.log('Attributes received', response);

    //         //map the response in suitable data structure
    //         let boxObjectAttributes = {
    //           names: []
    //         }
    //         response.result.forEach(attrObj => {
    //           boxObjectAttributes.names.push(attrObj.name)
    //           boxObjectAttributes[attrObj.name] = {
    //             dataType: attrObj.dataType,
    //             value: ''
    //           }
    //         });
    //         this.boxObjectAttributes = boxObjectAttributes
    //         this.isAtrributeReady = true;

    //         // this.createAttributeForm(this.boxObjectAttributes)

    //         console.log("check map", this.boxObjectAttributes)

    //         resolve(boxObjectAttributes)

    //       },
    //       (error) => {
    //         console.log('Error while getting attributes', error);
    //         reject(error)
    //       }
    //     );
    // })
  }


  /**
   * based on the given boxObjectAttributes, a reactive form will be generated to take in the values of attributes
   * @param boxObjectAttributes
   */
  // createAttributeForm(boxObjectAttributes: any) {
  //   let formControls = {}

  //   //build one formControl for each attribute
  //   boxObjectAttributes.names.forEach(attr => {
  //     let temp = new FormControl('')
  //     formControls[attr] = temp
  //   });

  //   //create formgroup with the generated formcontrol
  //   this.attributesForm = new FormGroup(formControls)

  //   this.isAttributesFormReady = true
  // }


  /**
   *
   * @param event
   */
  boxObjectAttributeSelection(event: any) {
    console.log("box object attributes selected", event.value)

    this.isReadyToSave = true;

    //get box object functions
    // this.getBoxObjectFunctions()
  }

  attributeSelected(event){
    console.log("attribute selected", event.option.value)
    this.selectedBoxAttribute = event.option.value.__id
    this.isReadyToSave = true
  }

  clearBoxObjectFunction() {
    this.selectedBoxObjectId = '';
    this.selectedObjectFunction = '';
    this.boxObjectFunctions = [];
    this.boxObjectFunctionInputLists = [];
  }
  onChangeBoxObjectFunction() {
    this.boxObjectFunctionInputLists = [];
    let lists = this.selectedBoxObjectFunction?.input?.list || [];
    for (var i = 0; i < lists.length; i++) {
      var obj = {};
      obj['name'] = lists[i];
      obj['value'] = '';
      this.boxObjectFunctionInputLists.push(obj);
    }
    console.log('boxObjectFunctionInputLists', this.boxObjectFunctionInputLists);

    this.isReadyToSave = true;
  }


  /**
   * AS A SIDE EFFECT, IT UPDATES THE DATA MODEL ALSO ONCE THE PAGE META IS CHANGED
   */
  saveDataConfig() {
    console.log("execute box object function hit")

    let dataConfig = {
      connectionId: this.selectedConnectionId,
      boxId: this.selectedBoxId,
      boxName: this.selectedBoxName,
      boxObjectId: this.selectedBoxObjectId,
      boxFunction: 'getobjects',
      boxAttributeId: this.selectedBoxAttribute,
      boxConfigToken: this.boxConfigToken
      // boxObjectFunction: this.selectedBoxObjectFunction.__id,
      // inputLists: this.boxObjectFunctionInputLists
    }
    console.log("data bind config initialized", dataConfig)
    // let url = `${this.boxUrl}/${dataConfig.boxId}/${dataConfig.boxObject}/${dataConfig.boxObjectFunction}`
    // console.log("url is", url)

    //reflect the widget meta change in the page meta
    this.widgetMeta['dataBindConfig'] = dataConfig

    //search through the the panel meta in hand and update the appropriate widget meta and then next() into pageMeta
    this.pageMeta.panels.forEach(panelMeta => {
      if (panelMeta.id == this.panelId) {
        console.log("checking panel", panelMeta)
        let widgets = this.pageService.getWidgetsFromPanel(panelMeta);
        widgets.forEach(widgetMeta => {
          console.log("checking widget meta", widgetMeta)
          if (widgetMeta.id == this.widgetMeta.id) {
            console.log("widget found")
            widgetMeta = this.widgetMeta
          }
        });
      }

      this.MetaService.pageMeta.next(this.pageMeta)

      //save in database
      // this.MetaService.update(this.pageMeta)
      // .then(res=>{
      //   console.log("saved data bind config")

      //   //update the model also
      //   // this.pageService.bindWidgetToDataModel(this.pageMeta)
      // })
      // .catch(error=>{
      //   console.error("error occured in saving data bind config", error)
      // })
    });

    // this.executeBoxObjectFunction(url)
  }

  executeBoxObjectFunction(url: any) {
    var payload = { parameters: {} };
    for (var i = 0; i < this.boxObjectFunctionInputLists.length; i++) {
      var list = this.boxObjectFunctionInputLists[i];
      let value: any = list.value || '';
      if (value) {
        console.log('list.value', value);
        value = JSON.parse(value);
      }
      payload.parameters[list.name] = value;
    }

    let token = this.TokenUtil.getStatelessToken()
    const headers = new HttpHeaders()
      .set('boxConfigToken', this.boxConfigToken)
      .set('Authorization', `Bearer ${token}`);

    console.log("will hit with payload", payload)
    this.http.post(url, payload, { headers: headers })
      .subscribe(res => {
        console.log("boxObjectFunction Executed", res)
      })

  }

  // =============================================== NEW IMPLEMENTATION ==================
  boxSelectionError(event){
    console.log("box selection error", event)
    this.isBoxConfigError = true
    this.boxConfigError = event
  }

  boxObjectSelectionError(event){
    console.log("box object selection error", event)
    this.isBoxObjectConfigError = true
    this.boxObjectConfigError = event
  }

  newOptions(widgetMeta){
    console.log("new options received", widgetMeta)
    this.widgetMeta.config.availableOptions = widgetMeta.config.availableOptions
    this.settingsChanged.emit(this.widgetMeta)
  }

  chartDataSourceChanged(data){
    console.log("chart data source updated", data)
    // this.widgetMeta.config.dataSource = data
    this.widgetMeta = data
    console.log("WidgetMeta updated", this.widgetMeta)
    this.settingsChanged.emit(this.widgetMeta)
  }

}
