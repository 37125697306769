<spinner *ngIf="spinner" [selfSpin]="true" [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>

<ng-container *ngIf="widgetMeta && widgetMeta.config">
  <div
    #outerCover
    *ngIf="builderMode"
    class="outerCover"
    [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
    [class.selectedNow]="selectedWidgetId === widgetMeta.id"
    [class.hoveredNow]="selectedWidgetId === widgetMeta.id? false: hoveredNow"
    (mouseenter)="widgetMouseenter()"
    (mouseleave)="widgetMouseleave()"
    (click)="onOuterClick()"
    [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
    [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
  >
    <widget-drag-handle [widgetMeta]="widgetMeta" [class.invisible]="(selectedWidgetId !== widgetMeta.id && !hoveredNow)"></widget-drag-handle>


    <div
      *ngIf="!buttonEditMode && securityMap.show; else buttonInput; "
      [ngSwitch]="widgetMeta.config.buttonType.value"
      class="innerCover"
      fxLayout fxLayoutAlign="center center"
    >
      <button *ngSwitchCase="'Stroked'" [ngStyle]="widgetMeta.effectStyle || styles" mat-stroked-button
        [color]="widgetMeta.config.buttonColorType.value" class="toggler" (click)="onClick($event)"
        (dblclick)="onDoubleButtonClicked()" (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)">
        <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
      </button>
      <button *ngSwitchCase="'Raised'" [ngStyle]="widgetMeta.effectStyle || styles" mat-raised-button
        [color]="widgetMeta.config.buttonColorType.value" class="toggler" (click)="onClick($event)"
        (dblclick)="onDoubleButtonClicked()" (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)">
        <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
      </button>
      <button *ngSwitchCase="'Flat'" [ngStyle]="widgetMeta.effectStyle || styles" mat-flat-button [color]="widgetMeta.config.buttonColorType.value"
        class="toggler" (click)="onClick($event)" (dblclick)="onDoubleButtonClicked()"
        (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)">
        <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
      </button>
      <button *ngSwitchCase="'Icon'" [ngStyle]="widgetMeta.effectStyle || styles" mat-icon-button [color]="widgetMeta.config.buttonColorType.value"
        class="toggler" (click)="onClick($event)" (dblclick)="onDoubleButtonClicked()"
        (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)">
        <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
      </button>
      <button *ngSwitchCase="'Fab'" [ngStyle]="widgetMeta.effectStyle || styles" mat-fab [color]="widgetMeta.config.buttonColorType.value"
        class="toggler" (click)="onClick($event)" (dblclick)="onDoubleButtonClicked()"
        (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)">
        <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
      </button>
      <button *ngSwitchCase="'Mini-fab'" [ngStyle]="widgetMeta.effectStyle || styles" mat-mini-fab [color]="widgetMeta.config.buttonColorType.value"
        class="toggler" (click)="onClick($event)" (dblclick)="onDoubleButtonClicked()"
        (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)">
        <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
      </button>
      <button *ngSwitchDefault [ngStyle]="widgetMeta.effectStyle || styles" mat-button [color]="widgetMeta.config.buttonColorType.value"
        class="toggler" (click)="onClick($event)" (dblclick)="onDoubleButtonClicked()"
        (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)">
        <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
      </button>
    </div>
    <ng-template #buttonContent>
      <div fxLayoutAlign="center center">
        <mat-icon *ngIf="widgetMeta?.config?.buttonIcon?.value||false">
          {{widgetMeta.config.buttonIcon.iconName.value}}
        </mat-icon>
        <span *ngIf="!widgetMeta?.config?.buttonIcon?.hideText?.value">
          {{ widgetMeta.config.buttonText.value}}
        </span>
      </div>

    </ng-template>
    <ng-template #buttonInput>
      <mat-form-field (mousedown)="$event.stopPropagation()" (focusout)="onFocusOut($event)"
        [color]="widgetMeta.config.buttonColorType.value" class="button-Input-Width">
        <input #buttonEditInput matInput type="text" [(ngModel)]="widgetMeta.config.buttonText.value">
      </mat-form-field>
    </ng-template>
  </div>


  <div
    #outerCover
    *ngIf="!builderMode"
    [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
    class="outerCover"
    [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
    [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
  >


    <div
      [ngSwitch]="widgetMeta.config.buttonType.value"
      class="innerCover" *ngIf="securityMap.show"
      fxLayout fxLayoutAlign="center center"
    >
      <button *ngSwitchCase="'Stroked'" [ngStyle]="widgetMeta.effectStyle || styles" mat-stroked-button
        [color]="widgetMeta.config.buttonColorType.value" class="toggler" (click)="onClick($event)"
        (dblclick)="onDoubleButtonClicked()" (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)"
        [disabled]="widgetMeta.config.spin">
        <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
      </button>
      <button *ngSwitchCase="'Raised'" [ngStyle]="widgetMeta.effectStyle || styles" mat-raised-button
        [color]="widgetMeta.config.buttonColorType.value" class="toggler" (click)="onClick($event)"
        (dblclick)="onDoubleButtonClicked()" (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)"
        [disabled]="widgetMeta.config.spin">
        <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
      </button>
      <button *ngSwitchCase="'Flat'" [ngStyle]="widgetMeta.effectStyle || styles" mat-flat-button [color]="widgetMeta.config.buttonColorType.value"
        class="toggler" (click)="onClick($event)" (dblclick)="onDoubleButtonClicked()"
        (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)"
        [disabled]="widgetMeta.config.spin">
        <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
      </button>
      <button *ngSwitchCase="'Icon'" [ngStyle]="widgetMeta.effectStyle || styles" mat-icon-button [color]="widgetMeta.config.buttonColorType.value"
        class="toggler" (click)="onClick($event)" (dblclick)="onDoubleButtonClicked()"
        (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)"
        [disabled]="widgetMeta.config.spin">
        <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
      </button>
      <button *ngSwitchCase="'Fab'" [ngStyle]="widgetMeta.effectStyle || styles" mat-fab [color]="widgetMeta.config.buttonColorType.value"
        class="toggler" (click)="onClick($event)" (dblclick)="onDoubleButtonClicked()"
        (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)"
        [disabled]="widgetMeta.config.spin">
        <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
      </button>
      <button *ngSwitchCase="'Mini-fab'" [ngStyle]="widgetMeta.effectStyle || styles" mat-mini-fab [color]="widgetMeta.config.buttonColorType.value"
        class="toggler" (click)="onClick($event)" (dblclick)="onDoubleButtonClicked()"
        (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)"
        [disabled]="widgetMeta.config.spin">
        <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
      </button>
      <button *ngSwitchDefault [ngStyle]="widgetMeta.effectStyle || styles" mat-button [color]="widgetMeta.config.buttonColorType.value"
        class="toggler" (click)="onClick($event)" (dblclick)="onDoubleButtonClicked()"
        (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)"
        [disabled]="widgetMeta.config.spin">
        <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
      </button>
    </div>
    <ng-template #buttonContent >
      <div fxLayoutAlign="center center">
        <mat-icon *ngIf="widgetMeta?.config?.buttonIcon?.value||false">
          {{widgetMeta.config.buttonIcon.iconName.value}}
        </mat-icon>
        <span *ngIf="!widgetMeta?.config?.buttonIcon?.hideText?.value">
          {{ widgetMeta.config.buttonText.value}}
        </span>
      </div>
    </ng-template>
  </div>
</ng-container>
<ng-container *ngIf="!widgetMeta || !widgetMeta.config">{{ "button meta corrupted" }}</ng-container>
