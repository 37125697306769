import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragPlaceholder,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-attribute-selection',
  templateUrl: './attribute-selection.component.html',
  styleUrls: ['./attribute-selection.component.scss']
})
export class AttributeSelectionComponent implements OnInit {

  @Input() boxObjectAttributes: any[]
  @Input() listAttributes: any[]
  @Input() supportApplyButton: boolean
  @Input() allowReorder: boolean = true
  @Input() attributeDisplayName?: string;

  @Output() listAttributeSelected: EventEmitter<any> = new EventEmitter();

  filteredAttributes: any[] = []
  isSearching: boolean = false;

  constructor() { }

  ngOnInit(): void {
    // console.log("[ATTRIBUTE SELECTION] boxObjectAttributes", this.boxObjectAttributes)
    // console.log("[ATTRIBUTE SELECTION] listAttributes", this.listAttributes)
    // this.filteredAttributes = this.boxObjectAttributes
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log("changes", changes)
    if(changes.boxObjectAttributes?.currentValue) {
      this.filteredAttributes = changes.boxObjectAttributes.currentValue
      this.filteredAttributes.forEach(attr => {
        if(!attr.hasOwnProperty('isColumnSelected')) attr['isColumnSelected'] = true;
        if(this.containsObject(attr.__id, this.listAttributes) && attr['isColumnSelected']) attr['isColumnSelected'] = true
        else attr['isColumnSelected'] = false;
      })
      console.log("new list assigned", this.filteredAttributes)
      this.listAttributeSelected.emit(this.listAttributes)
    }
    // if(changes.listAttributes?.currentValue){
    //   console.log("listAttributeChanged", this.listAttributes)
    //   this.columnSelectionChanged()
    // }
  }

  columnSelectionChanged(){
    console.log("boxObjectAttributes", this.boxObjectAttributes)
    this.listAttributes = []
    this.boxObjectAttributes.forEach((e) => {
      let attr = e;
      if(e.isColumnSelected){ // && !this.containsObject(attr.__id, this.listAttributes)
        this.listAttributes.push(attr)
      }
      // else if (!e.isColumnSelected){  // && this.containsObject(attr.__id, this.listAttributes)
      //   let i = this.listAttributes.findIndex(a => a.__id == attr.__id)
      //   this.listAttributes.splice(i, 1)
      // }
    })
    console.log("returning selected", JSON.parse(JSON.stringify(this.listAttributes)))
    this.listAttributeSelected.emit(this.listAttributes)

    // reset the filteredAttributes
    this.filteredAttributes = this.boxObjectAttributes
  }

  containsObject(id:any, list:any) {
    var i;
    let res = false;
    for (i = 0; i < list.length; i++) {
      if (list[i].__id === id) {
        res = true;
        break;
      }
    }
    // console.log("id", id, res)
    return res;
  }

  attributeSelected(event, attribute){
    console.log("[attribute selected] attr", event.checked)

    let i = this.boxObjectAttributes.findIndex(a => a.__id == attribute.__id)
    this.boxObjectAttributes[i].isColumnSelected = event.checked

    console.log("boxObjectAttributes after selection", JSON.parse(JSON.stringify(this.boxObjectAttributes)))
    if(!this.supportApplyButton) this.columnSelectionChanged()
  }

  filterAttributeList(event){
    let txt = event.srcElement.value
    console.log("search text", txt)
    if (txt == '') {
      this.isSearching = false
      this.filteredAttributes = this.boxObjectAttributes
      this.filteredAttributes.forEach(attr => {
        if(this.containsObject(attr.__id, this.listAttributes)) attr['isColumnSelected'] = true
      })
    } else {
      this.isSearching = true
      this.filteredAttributes = []
      this.boxObjectAttributes.forEach(attr => {
        if (attr.__id.includes(txt) || attr.name.includes(txt)) {
          this.filteredAttributes.push(attr)
        }
      })
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    if(this.filteredAttributes.length != this.boxObjectAttributes.length) return
    moveItemInArray(this.boxObjectAttributes, event.previousIndex, event.currentIndex);
    const checkboxAttrIndex = this.boxObjectAttributes.findIndex((attr) => attr.__id === "__checkbox__")
    if (checkboxAttrIndex != -1) {
      const checkboxAttr = this.boxObjectAttributes.splice(checkboxAttrIndex, 1);
      this.boxObjectAttributes.unshift(checkboxAttr[0]);
    }
    this.filteredAttributes = this.boxObjectAttributes
    console.log("reordered", this.boxObjectAttributes)
    if(!this.supportApplyButton) this.columnSelectionChanged()
  }

}
