<spinner [selfSpin]="spinner" [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>
<div *ngIf="form?.theme?.themes?.length">
  <div>
    <div *ngFor="let theme of form?.theme?.themes;let i = index">
      <div class="card1" (click)="setTheme(theme)" [ngClass]="{'selected': selectedTheme?.code === theme?.code}">
        <div style="padding: 5px;">
        <div fxLayout="row" fxLayoutAlign="start start">
          <div fxLayout="column" fxFlex="20">
              <div style="height: 25px;" [fxLayoutAlign]="theme?.alignment ? theme?.alignment + ' start' : 'center start'"
              [ngStyle]="theme?.style ? theme.style : {'background-color': '#f5f5f5'}">
                 <mat-card class="innerCard" [ngStyle]="theme?.frameconfig?.style ? theme?.frameconfig?.style : {'background-color': '#fff'}"></mat-card>
              </div>
          </div>
          <div fxLayout="column" fxFlex="5">
          </div>
          <div fxLayout="column" fxFlex="65">
            {{theme.name}}
          </div>
          <div fxLayout="column" fxFlex="10">
              <mat-icon style="cursor: pointer;" [matMenuTriggerFor]="actionmenu" matTooltip="More Action">more_vert</mat-icon>
          </div>
        </div>
        </div>
      </div>

      <mat-menu #actionmenu="matMenu">
       <div>
        <button mat-button matTooltip="Edit Theme" (click)="editTheme(theme, i)">
          Edit <mat-icon >edit</mat-icon>
        </button>
       </div>
        <button mat-button color="warn" matTooltip="Delete Theme" (click)="deleteTheme(theme, i)">
          Remove <mat-icon >delete</mat-icon>
        </button>
      </mat-menu>
    </div>
  </div>
</div>

<div style="height: 6px"></div>
<div fxLayout="row" style="font-weight: 600;" fxLayoutAlign="center center" *ngIf="newThemeMap && !isNameEditMode" (click)="toggleEditMode()">
  Configure {{newThemeMap.name}}
</div>
<div fxLayout="row" style="font-weight: 600;" fxLayoutAlign="center center" *ngIf="newThemeMap && isNameEditMode" (click)="toggleEditMode()">
  <input [(ngModel)]="newThemeMap.name" (click)="$event.stopPropagation()" (blur)="toggleEditMode()" />
</div>

<div fxLayout="row" fxLayoutAlign="center center" *ngIf="newThemeMap">
  <mat-card  fxLayout="column"
   style="padding-left: 2px;padding-right: 2px;width: 90%;">
    <div fxLayout="row"  fxLayoutAlign="start center">
      <span  fxLayoutAlign="start center">
        Background: &nbsp;
        <input #colorInput class="colorInput"
         matInput type="color" name="color" [value]="newThemeMap?.['background-color']"
        (input)="colorChanged($event.target)">
        <button matTooltip="Reset color" mat-icon-button aria-label="Clear"
          (click)="backgroundColorReset()">
            <mat-icon class="resetIcon">close</mat-icon>
      </button>
      </span>
    </div>
    <div style="height: 2px"></div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <!-- <div fxLayout="column" > -->
        Background Image: &nbsp;
      <!-- </div> -->
      <div appearance="standard" (click)="openBackgroundPanel = !openBackgroundPanel"
         class="colorInputWrap"  fxLayout="row">
         <mat-icon class="formatIcon" fontIcon="imagesmode" matTooltip="Background image"></mat-icon>
        <mat-icon *ngIf="newThemeMap?.image?.url?.length"  style="font-size: .9rem; color: green;" fxLayoutAlign="center center" fontIcon="done"></mat-icon>
      </div>
      <button *ngIf="newThemeMap?.image?.url?.length" matTooltip="Reset image" mat-icon-button aria-label="Clear"
          (click)="backgroundImageReset()">
            <mat-icon class="resetIcon">close</mat-icon>
      </button>
    </div>
    <div style="height: 2px"></div>
    <div fxLayout="row" *ngIf="openBackgroundPanel">
      <div fxLayout="column" fxFlex="80">
        <mat-form-field appearance="outline">
          <mat-label>
            Image URL
          </mat-label>
          <input matInput
          [(ngModel)]="newThemeMap.image.url" (change)="customPropertyChanged($event.target)">
          <button *ngIf="newThemeMap?.image?.url?.length" matSuffix mat-icon-button aria-label="Clear"
          (click)="backgroundImageReset()">
            <mat-icon>close</mat-icon></button>
        </mat-form-field>
      </div>
      <div fxLayout="column" fxFlex="20">
        <dropzone type="iconbutton" [noPreview]="true" (fileChange)="imageSelectionChange($event)"></dropzone>
      </div>
    </div>
    <div fxLayout="row">
      Opacity: &nbsp;
      <mat-slider style="min-width: 70px;margin-top: -12px;" min="1" max="100" showTickMarks discrete [displayWith]="formatLabel">
        <input matSliderThumb [(ngModel)]="newThemeMap.opacity" (input)="opacityChanged()">
      </mat-slider>
    </div>
    <!-- <div style="height: 2px"></div>
    <div fxLayout="row">
      Alignment
    </div>
    <div fxLayout="row">
    <mat-button-toggle-group class="layoutToggle" #group="matButtonToggleGroup" (change)="alignmentChanged($event)">
      <mat-button-toggle class="layoutToggleButton" [checked]="newThemeMap.alignment == 'start' ? true : false" value="start" aria-label="Align left" matTooltip="Align left">
        <mat-icon class="layoutIcon">format_align_left</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle class="layoutToggleButton" [checked]="newThemeMap.alignment == 'center' ? true : false" value="center" aria-label="Align center" matTooltip="Align center">
        <mat-icon class="layoutIcon">format_align_center</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle class="layoutToggleButton" [checked]="newThemeMap.alignment == 'end' ? true : false" value="end" aria-label="Align right" matTooltip="Align right">
        <mat-icon class="layoutIcon">format_align_right</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
    </div> -->
  </mat-card>
</div>
<div style="height: 8px"></div>
<div fxLayout="row" fxLayoutAlign="center center" *ngIf="newThemeMap">

    <!-- form frame config -->
    <mat-card  fxLayout="column"
    style="padding-left: 2px;padding-right: 2px;width: 90%;">
     <div fxLayout="row" style="font-weight: bold;">
       Frame Configuration
     </div>
     <div fxLayout="row"  fxLayoutAlign="start center">
       <span  fxLayoutAlign="start center">
          Color: &nbsp;
         <input #colorInput class="colorInput"
          matInput type="color" name="color" [value]="newThemeMap?.frameconfig?.['background-color']"
         (input)="colorChanged($event.target, 'frameconfig')">
         <button matTooltip="Reset color" mat-icon-button aria-label="Clear"
           (click)="backgroundColorReset('frameconfig')">
             <mat-icon class="resetIcon">close</mat-icon>
       </button>
       </span>
     </div>

     <div style="height: 2px"></div>
    <!-- <div fxLayout="row" fxLayoutAlign="start center">
        Background Image: &nbsp;
      <div appearance="standard" (click)="openFrameBackgroundPanel = !openFrameBackgroundPanel"
         class="colorInputWrap"  fxLayout="row">
         <mat-icon class="formatIcon" fontIcon="imagesmode" matTooltip="Background image"></mat-icon>
        <mat-icon *ngIf="newThemeMap?.frameconfig?.image?.url?.length"  style="font-size: .9rem; color: green;" fxLayoutAlign="center center" fontIcon="done"></mat-icon>
      </div>
      <button *ngIf="newThemeMap?.frameconfig?.image?.url?.length" matTooltip="Reset image" mat-icon-button aria-label="Clear"
          (click)="backgroundImageReset('frameconfig')">
            <mat-icon class="resetIcon">close</mat-icon>
      </button>
    </div>
    <div style="height: 2px"></div>
    <div fxLayout="row" *ngIf="openFrameBackgroundPanel">
      <div fxLayout="column" fxFlex="80">
        <mat-form-field appearance="outline">
          <mat-label>
            Image URL
          </mat-label>
          <input matInput
          [(ngModel)]="newThemeMap.frameconfig.image.url" (change)="customPropertyChanged($event.target, 'frameconfig')">
          <button *ngIf="newThemeMap?.frameconfig?.image?.url?.length" matSuffix mat-icon-button aria-label="Clear"
          (click)="backgroundImageReset('frameconfig')">
            <mat-icon>close</mat-icon></button>
        </mat-form-field>
      </div>
      <div fxLayout="column" fxFlex="20">
        <dropzone type="iconbutton" [noPreview]="true" (fileChange)="imageSelectionChange($event, 'frameconfig')"></dropzone>
      </div>
    </div> -->

     <div fxLayout="row">
       Opacity: &nbsp;
       <mat-slider style="min-width: 70px;margin-top: -12px;" min="1" max="100" showTickMarks discrete
       [displayWith]="formatLabel">
         <input matSliderThumb [(ngModel)]="newThemeMap.frameconfig.opacity" (input)="opacityChanged('frameconfig')">
       </mat-slider>
     </div>

     <div fxLayout="column">
      <div class="heading">
        Margin
      </div>
      <div fxLayout fxLayoutAlign="space-around center">
        <div fxLayoutAlign="center center" fxFlex="15">{{ "Left " + newThemeMap.frameconfig.margin?.left + "%" }}</div>
        <mat-slider
          fxFlex="70"
          class="slider hide-start-thumb hide-end-thumb"
          color="primary"
          [displayWith]="displayThumbText.bind(this)"
          [step]="1"
          [showTickMarks]="true"
          [discrete]="true"
          [max]="100"
          [min]="0"
        >
          <input [ngModel]="newThemeMap.frameconfig?.margin?.left" matSliderStartThumb (dragStart)="dragStart($event, 'left')" (valueChange)="marginChange($event, 'left', newThemeMap.frameconfig.margin)">
          <input [ngModel]="newThemeMap.frameconfig?.margin?.right" matSliderEndThumb (dragStart)="dragStart($event, 'right')" (valueChange)="marginChange($event, 'right',  newThemeMap.frameconfig.margin)">
        </mat-slider>
        <div fxLayoutAlign="center center" fxFlex="15">{{ "Right " + (newThemeMap.frameconfig.margin?.right ? (100 - (newThemeMap.frameconfig.margin?.right || 0)) : 0) + "%" }}</div>
      </div>
      <div fxLayout fxLayoutAlign="space-around center">
        <div fxLayoutAlign="center center" fxFlex="15">{{ "Top " + newThemeMap.frameconfig.margin?.top + "%" }}</div>
        <mat-slider
          fxFlex="70"
          class="slider hide-start-thumb hide-end-thumb"
          color="primary"
          [displayWith]="displayThumbText.bind(this)"
          [step]="1"
          [showTickMarks]="true"
          [discrete]="true"
          [max]="100"
          [min]="0"
        >
          <input [ngModel]="newThemeMap.frameconfig?.margin?.top" matSliderStartThumb (dragStart)="dragStart($event, 'top')" (valueChange)="marginChange($event, 'top', newThemeMap.frameconfig.margin)">
          <input [ngModel]="newThemeMap.frameconfig?.margin?.bottom" matSliderEndThumb (dragStart)="dragStart($event, 'bottom')" (valueChange)="marginChange($event, 'bottom',  newThemeMap.frameconfig.margin)">
        </mat-slider>
        <div fxLayoutAlign="center center" fxFlex="15">{{ "Bottom " + (newThemeMap.frameconfig.margin?.bottom ? (100 - (newThemeMap.frameconfig.margin?.bottom || 0)) : 0) + "%" }}</div>
      </div>
    </div>
   </mat-card>
</div>


<div fxLayout="row" fxLayoutAlign="center center" *ngIf="newThemeMap" style="padding-top: 15px;">
  <button matTooltip="Cancel" mat-button color="warn" (click)="clearTheme()">
    <mat-icon>close</mat-icon> Cancel
  </button>
  <div fxFlex="5"></div>
  <button matTooltip="Done" mat-raised-button color="primary" (click)="addNewTheme()">
    <mat-icon>check</mat-icon> Save
  </button>

</div>
<mat-divider *ngIf="newThemeMap"></mat-divider>

<div style="height: 10px;"></div>
<div fxLayout="row"  fxLayoutAlign="center center" *ngIf="!this.newThemeMap">
  <button matTooltip="Add new Theme" mat-stroked-button color="primary" (click)="createNewTheme()">
    <mat-icon>add</mat-icon> Theme
  </button>
</div>



