<!-- style="border: 1px solid black" -->
<div class="outerCover"
  *ngIf="builderMode"
  [class.selectedNow]="selectedWidgetId == widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId == widgetMeta.id ? false : hoveredNow"
  (mouseenter)="widgetMouseenter()" (mouseleave)="widgetMouseleave()" (click)="onClick()"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <widget-drag-handle [class.invisible]="selectedWidgetId !== widgetMeta.id && !hoveredNow"></widget-drag-handle>
  <div class="innerCover">
    <!-- *ngIf="!widgetMeta.config.viewOnly || !widgetMeta.config.viewOnly.value"; -->
    <div>
      <mat-form-field
        disabled
        fxFlexFill
        [floatLabel]="widgetMeta?.config?.nofloat?.value ? 'never' : ''"
        [appearance]="this.widgetMeta.config.appearance && this.widgetMeta.config.appearance.value ? this.widgetMeta.config.appearance.value : 'standard'"
        [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
        (mousedown)="$event.stopPropagation()"
      >
        <mat-label>{{ widgetMeta.config?.placeholder?.value }}</mat-label>
        <!-- disabled -->
        <!-- [class]="getFormFieldClass()" -->
        <input
          [ngStyle]="styles"
          matInput type="text"
          (keyup)="userInputDetected()"
          [ngModel]="widgetMeta.config?.textContent?.value"
          [required]="widgetMeta?.required ? widgetMeta.required : false"
          (ngModelChange)="widgetMeta.config?.textContent.value = $event"
        >
      </mat-form-field>
    </div>
    <!-- <div *ngIf="widgetMeta.config.viewOnly && widgetMeta.config.viewOnly.value">
      <mat-label [ngStyle]="styles">{{ widgetMeta.config?.textContent?.value }}</mat-label>
    </div> -->
  </div>
</div>

<div class="outerCover" *ngIf="!builderMode && (!widgetMeta.config.hidden || !widgetMeta.config.hidden.value)"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <!-- style="border: 1px solid red" -->
  <div
    class="innerCover"
    *ngIf="!widgetMeta.config?.viewOnly || !widgetMeta.config?.viewOnly.value"
  >
    <!-- style="border: 1px solid blue" -->
    <mat-form-field
      fxFlexFill
      [floatLabel]="widgetMeta?.config?.nofloat?.value ? 'never' : ''"
      [appearance]="this.widgetMeta.config.appearance && this.widgetMeta.config.appearance.value ? this.widgetMeta.config.appearance.value : 'standard'"
      [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
      (mousedown)="$event.stopPropagation()"
      >
      <!-- [class]="getFormFieldClass()" -->
      <!-- class="input no-underline text-field" -->
      <mat-label>{{ widgetMeta.config?.placeholder?.value }}</mat-label>
      <!-- padding-bottom: 0px;  -->
      <!-- style="border: 1px solid green" -->
      <input
        matInput #input type="text"
        [formControl]="fc"
        [ngStyle]="styles"
        [required]="widgetMeta?.required ? widgetMeta.required : false"
        (keyup)="userInputDetected()"
        [ngModel]="widgetMeta.config?.textContent?.value"
        (ngModelChange)="widgetMeta.config?.textContent.value = $event"
      >
        <!-- <mat-error *ngIf="form.controls[widgetMeta.id].hasError('required') && (form.controls[widgetMeta.id].dirty || form.controls[widgetMeta.id].touched)">This field is required</mat-error> -->
        <mat-error *ngIf="fc.invalid && fc.hasError('required')">This field is required</mat-error>
        <!-- [placeholder]="widgetMeta.config.placeholder.value" -->
        <!-- [required]="widgetMeta.config.required && widgetMeta.config.required.value" -->
        <mat-hint *ngIf="widgetMeta.note">
          <span style="font-size: small;margin-left: 10px; color: gray;" *ngIf="widgetMeta.note?.message">Note: {{widgetMeta.note.message}}</span>
          <a *ngIf="widgetMeta.note?.knowMoreLink" style="font-size: small;" [href]="widgetMeta.note.knowMoreLink" target="_blank"> &nbsp;know more</a>
        </mat-hint>
    </mat-form-field>
  </div>

  <div *ngIf="widgetMeta.config?.viewOnly && widgetMeta.config.viewOnly.value"
    class="innerCover label-z-index content" [ngStyle]="styles">
    <mat-label [ngStyle]="styles">{{ widgetMeta.config?.textContent?.value }}</mat-label>
  </div>
</div>
