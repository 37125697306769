import { Component, NgZone, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthServiceService } from '../../services/auth-service.service';
import { ConnectionService } from "../../../modules/organization/connection.service";
import { MatInput } from '@angular/material/input';

declare const google: any

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss']
})
export class ProfileMenuComponent implements OnInit {

  private _profile: any;
  get profile() { return this._profile }
  set profile(val) { this._profile = val }


  photoUrl: string;
  internalUser: boolean = false;
  showSimulation: boolean = false;
  userEmail: any;
  email: any;
  isSimulating: boolean = false;

  noPhotoUrl: string =
    'https://storage.googleapis.com/jivrus-web-images/avatar/no-user-photo.jpg';

  get authService() { return this._authService; }
  get router() { return this._router }
  get connectionService() {
    return this._connectionService;
  }

  constructor(
    private _authService: AuthServiceService,
    private _router: Router,
    private ngZone: NgZone,
    private _connectionService: ConnectionService,
    private cd: ChangeDetectorRef,
    ) {
  }

  ngOnInit(): void {
    this.authService.profileLoaded.subscribe(profile => {
      this.profile = profile
      this.email = this.profile.email;
      this.photoUrl = this.profile?.image_url || ''
      this.checkDomain();
    })
  }

  async checkDomain(){
    let domain = this.email?.substring(this.email.indexOf('@') + 1);
    if(domain == "jivrus.com"){
      this.internalUser = true;
    }
  }

  async setSimulation(){
    this.connectionService.preAuthenticatedToken = ''
    await this.authService.setSimulation(this.userEmail);
    this.userEmail = "";
    this.router.navigate(['/home'])
    this.isSimulating = true;
    console.log(this.authService.simulationStatus, this.isSimulating)
  }

  async resetSimulation(){
    this.isSimulating = false;
    this.connectionService.preAuthenticatedToken = ''
    await this.authService.resetSimulation();
    this.router.navigate(['/home'])
    console.log(this.authService.simulationStatus, this.isSimulating)
  }

  signOut() {
    let that = this;
    this.connectionService.selectedWorkSpace = null;
    if (this.authService.authenticationType == 'google') {
      google.accounts.id.revoke(this.profile.email, done => {
        console.log("User signed out", done);
        that.ngZone.run(() => {
          sessionStorage.clear();
          that.authService.profile = undefined
          that.authService.loggedIn = false;
          this.connectionService.preAuthenticatedToken = ''
          that.router.navigate([''])
        })
      });
    } else {
      sessionStorage.clear();
      that.authService.profile = undefined
      that.authService.loggedIn = false;
      this.connectionService.preAuthenticatedToken = ''
      that.ngZone.run(() => that.router.navigate(['']))
    }
  }

}
