<mat-spinner
  *ngIf="spinner"
  color="warn"
  class="centerOfAll"
  [diameter]="50"
  fxLayoutAlign="center center"
></mat-spinner>
<div style="padding: 1rem;">
  <div fxLayout="row"><app-workspace *ngIf="authService.loggedIn" (dataRefresh)="refreshList()" [parentPage]="'bloom'"></app-workspace></div>

  <display-banner
    [logo]="'bloom/appiworks-bloom-logo.png'"
    [Title]="'Unleash your creativity with Bloom'"
    [Tagline]="'Visualize, integrate, and customize your perfect app solutions'">
  </display-banner>

  <div fxLayout="row" *ngIf="!authService.loggedIn" fxLayoutAlign="center center">
    <div  class="build_button_div" (click)="navigateToSignUp()">
        <div class="build_button" fxLayout="row">
          <div fxLayout="column" fxLayoutAlign="center start">
            <div style="font-size: large; font-weight: bold;" fxLayout="row"
            fxLayoutAlign="start center"> Build Now</div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div fxFlex="2"></div>
    <div fxFlex="96" fxLayoutAlign="space-between center">
      <button
        *ngIf="authService.loggedIn && panelMetaReady"
        mat-button
        fxLayoutAlign="start start"
        routerLink="/home"
        color="primary"
      >
        <mat-icon color="primary">arrow_back</mat-icon>
        <span style="margin-top: 10px; margin-left: 4px">Back to Home</span>
      </button>

      <button *ngIf="authService.loggedIn && panelMetaReady" mat-flat-button color="primary" style="border-radius: 12px;" (click)="onNewBloomClicked()">
        {{'Create new Bloom'|translate}}
      </button>
    </div>
    <div fxFlex="2"></div>
  </div>


  <h1 class="heading" *ngIf="authService.loggedIn && panelMetaReady"><b>List of Blooms</b></h1>

  <div fxLayout="row" *ngIf="authService.loggedIn && panelMetaReady">
    <div fxLayout="column" fxFlex="2"></div>
    <div fxLayout="column" fxFlex="96">
      <mat-card appearance="outlined">
        <app-list-panel
          #bloomListPanel
          style="width: 100%"
          [builderMode]="false"
          [panelMeta]="bloomListPanelMeta"
          [refreshData]="refreshPanel"
          (rowData)="selectedRowData($event)"
        >
        </app-list-panel>
      </mat-card>
    </div>
    <div fxLayout="column" fxFlex="2"></div>
  </div>

  <div *ngIf="authService.loggedIn && !spinner">
    <div fxLayout="row" >
      <div fxLayout="column" fxFlex="2"></div>
      <div fxLayout="column" fxFlex="96" fxLayoutAlign="center center">
        <h1 style="margin-bottom: 0px;"><b>Shared with me</b></h1>
      </div>
      <div fxLayout="column" fxFlex="2"></div>
    </div>

    <div fxLayout="row" *ngIf="sharedBlooms?.length == 0">
      <div fxLayout="column" style="color: brown;" *ngIf="!sharedBloomSpinner">
         No blooms shared with you.
      </div>
      <div fxLayoutAlign="center center">
        <mat-spinner
      *ngIf="!spinner && sharedBloomSpinner"
      color="warn"
      [diameter]="30"
      ></mat-spinner>
      </div>

    </div>

    <div fxLayout="row" *ngIf="sharedBlooms.length > 0" >
      <div fxLayout="column" fxFlex="2"></div>
      <mat-card appearance="outlined" fxFlex="96">
        <app-list-panel
          #bloomListPanel
          style="width: 100%"
          [builderMode]="false"
          [panelMeta]="sharedBloomListMeta"
          [inputData]="sharedBloomsList"
          [refreshData]="refreshPanel"
          (rowData)="selectedSharedRowData($event)"
        >
        </app-list-panel>
      </mat-card>
        <!-- <div fxLayout="column" fxFlex="2"></div> -->
        <!-- <div fxLayout="column" fxFlex="96">
            <mat-card appearance="outlined" class="list-card-head" fxLayout="row wrap"
                layout-wrap fxLayoutAlign="start center"
                  style="word-break: break-all;width: 100%" >
                <div fxLayout="column wrap" style="text-align: center;word-break: break-word;" fxFlex="5">
                    #
                </div>
                <div fxFlex="5" fxLayout="column wrap" style="word-break: break-word;">

                    </div>
                <div fxFlex="20" fxLayout="column wrap" fxLayoutAlign="center start" style="word-break: break-word;">
                    Name
                </div>
                <div fxFlex="25" fxLayout="column wrap" fxLayoutAlign="center start" style="word-break: break-word;">
                </div>
                <div fxFlex="20" fxLayoutAlign="center start" fxLayout="column wrap" style="word-break: break-word;">
                </div>
                <div fxFlex="20" fxLayout="column wrap" fxLayoutAlign="center start" style="word-break: break-word;">
                </div>
                <div fxFlex="5" fxLayout="column wrap" fxLayoutAlign="center start" style="word-break: break-word;">
                Action
                </div>
              </mat-card>
        </div> -->
        <!-- <div fxLayout="column" fxFlex="2"></div> -->
      </div>

    <!-- <div fxLayout="row" *ngIf="sharedBlooms.length > 0" >
        <div fxLayout="column" fxFlex="2"></div>
        <div fxLayout="column" fxFlex="96">
            <div *ngFor="let bloom of sharedBlooms;let i = index">
              <mat-card appearance="outlined" class="list-card" fxLayout="row wrap"
                layout-wrap fxLayoutAlign="start center"
                  style="word-break: break-all;width: 100%" >
                <div fxLayout="column wrap" style="text-align: center;word-break: break-word;" fxFlex="5">
                    {{i + 1}}
                </div>
                <div fxFlex="5" fxLayout="column wrap" style="word-break: break-word;">
                    <img  class="shared-bloom-logo"
                    src="{{bloom.logoUrl || 'https://plchldr.co/i/100x100?&bg=ddd&fc=444&text=' + bloom.name[0]}}" />
                </div>
                <div fxFlex="20" fxLayout="column wrap" fxLayoutAlign="center start" style="word-break: break-word;">
                    {{ bloom.name}}
                </div>
                <div fxFlex="25" fxLayout="column wrap" fxLayoutAlign="center start" style="word-break: break-word;">
                </div>
                <div  fxFlex="35" fxLayoutAlign="center start" fxLayout="column wrap" style="word-break: break-word;">

                </div>
                <div matTooltip="Status" fxFlex="5" fxLayout="column wrap" fxLayoutAlign="center start" style="word-break: break-word;">
                  <mat-icon style="margin: 0px;cursor: pointer;" (click)="openBloom(bloom.code, bloom.page_structure_id)">
                    edit</mat-icon>
                </div>
                <div fxFlex="5" fxLayout="column wrap" fxLayoutAlign="center start" style="word-break: break-word;">
                      <mat-icon style="margin: 0px;cursor: pointer;" (click)="setPermissionAndOpenBloom(bloom)">
                            visibility</mat-icon>
                </div>
              </mat-card>
            </div>
        </div>
        <div fxLayout="column" fxFlex="2"></div>
    </div> -->
    <br/>
  </div>
  <div class="error" *ngIf="error && authService.loggedIn">
    <div class="no-bloom" *ngIf="isNoBloom">
      <mat-card appearance="outlined">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1rem" style="width: 100%;">
          <div style="text-align: center; width: 100%; font-size: 1.2rem;">Create your first bloom now!</div>
          <div fxLayout="row"fxLayoutAlign="center center">
            <button mat-flat-button color="primary" (click)="onNewBloomClicked()">
              {{'New Bloom'|translate}}
            </button>
          </div>
        </div>
      </mat-card>
    </div>

    <div class="auth-error" *ngIf="authError">
      <mat-card appearance="outlined">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1rem" style="width: 100%;">
          <div style="text-align: center; width: 100%;">Workspace not loaded. Try signing in.</div>
          <button mat-raised-button class="signin-button" [routerLink]="['/']">
              Sign in
          </button>
        </div>
      </mat-card>
    </div>

    <div *ngIf="genericError">
      <mat-card appearance="outlined">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1rem" style="width: 100%;">
          <div style="text-align: center; width: 100%;">{{ genericErrorMessage }}</div>
        </div>
      </mat-card>
    </div>
  </div>

  <div>
    <div fxFlex="2"></div>
    <div fxLayout fxLayoutAlign="start start" fxFlex="98">
      <button *ngIf="authService.loggedIn && panelMetaReady" mat-flat-button color="primary" style="border-radius: 12px; margin-top: 5px;" (click)="onNewBloomClicked()">
        {{'Create new Bloom'|translate}}
      </button>
    </div>
  </div>
</div>
