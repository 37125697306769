<spinner [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>
<div mat-dialog-content>
<div>
    <div fxLayoutAlign="center center" *ngIf="baseMap">
       <h2 style="margin: 5px 0px 5px 0px;"> Attributes Configuration </h2>
    </div>
</div>

<div>
    <div fxLayout="row" fxLayoutAlign="center center"> <h3 style="margin: 5px 0px 5px 0px;"> Object '{{object.name}}' </h3> </div>
    <div fxLayout="row" fxLayoutAlign="center center">
        <div fxLayout="column" fxFlex="5"></div>
        <div fxLayout="column" fxFlex="90">
            <div>
                <div fxLayout="column" fxFlex="5"></div>
                  <div fxLayout="column" fxFlex="90">
                    <app-list-panel *ngIf="object.attributes && !object.addNewAttribute"
                    [panelMeta]="attributePanelMeta"  (rowData)="selectedAttribute($event, object)"
                    [inputData]="object.attributes" [builderMode]="builderMode">
                    </app-list-panel>
                  </div>
                  <div fxLayout="column" fxFlex="5"></div>
            </div>

            <div *ngIf="object.addNewAttribute">
                <div fxLayout="column" fxFlex="5"></div>
                  <div fxLayout="column" fxFlex="90">
                    <div fxLayoutAlign="center center"> Create attributes </div>
                    <attribute-parameter-inputs (inputParameter)="object.attributesInput = $event.attributes" [attribute]="mongoDbAttribute"> </attribute-parameter-inputs>
                  </div>
                  <div fxLayout="column" fxFlex="5"></div>
            </div>

            <div fxLayout="row wrap" *ngIf="object.addNewAttribute" fxLayoutAlign="center center">
                <div fxLayout="column" fxFlex="5"></div>
                <div fxLayout="column" fxFlex="90">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <div fxLayout="column" fxFlex="30">
                        </div>

                        <div fxLayout="column" fxFlex="30">
                            <button mat-stroked-button (click)="object.addNewAttribute = false;cancelAttribute(object)"  color="primary"  type="button">
                                Cancel
                              </button>
                        </div>
                        <div fxFlex="2"></div>
                        <div fxLayout="column" fxFlex="30">
                            <button mat-flat-button (click)="addNewAttributes(object)" color="primary" type="button">
                                Save
                              </button>
                        </div>

                   </div>
                </div>
                <div fxLayout="column" fxFlex="5"></div>
            </div>

            <div *ngIf="!object.addNewAttribute">
                <div fxLayout="column" fxFlex="5"></div>
                  <div fxLayout="column" fxFlex="30">
                    <button *ngIf="!object.attributes || object.attributes.length == 0"
                    mat-raised-button color="primary" (click)="object.addNewAttribute = true;">
                      <mat-icon >add</mat-icon> New Attribute</button>
                    <button *ngIf="object.attributes?.length > 0" mat-stroked-button color="primary" (click)="object.addNewAttribute = true;">
                        <mat-icon>add</mat-icon> New Attribute</button>
                  </div>
                  <div fxLayout="column" fxFlex="65"></div>
            </div>

        </div>
        <div fxLayout="column" fxFlex="5"></div>
    </div>

</div>
</div>
