import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { FlexAlignStyleBuilder } from '@ngbracket/ngx-layout';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subject } from 'rxjs';
import { MetaService } from 'src/app/bloom/services/meta-service';

import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { PageService } from 'src/app/bloom/services/page-service.service';


@Component({
  selector: 'app-period',
  templateUrl: './period.component.html',
  styleUrls: ['./period.component.css']
})
export class PeriodComponent extends BaseWidgetComponent implements OnInit, OnChanges {

  timeFormat = '24';
  contextMenuActions: {};
  range = new UntypedFormGroup({
    start: new UntypedFormControl(''),
    end: new UntypedFormControl(''),
  });
  timer = {
    start: this.timeFormat == '12' ? '12:00 am' : '00:00',
    end: this.timeFormat == '12' ? '11:59 pm' : '23:59'
  }


  private destroy:any = new Subject();
  private _periodType = 'date';

  get periodType() { return this._periodType }
  set periodType(value) { this._periodType = value }

  @Output() dateReceived: EventEmitter<any> = new EventEmitter();

  value = "";
  hoveredNow: boolean = false;

  @ViewChild('menuTrigger') periodMenuTrigger: MatMenuTrigger

  constructor(
    public metaService: MetaService,
    public pageService: PageService,
    public resourcePermissionService: ResourcePermissionService
  ) {
    super(metaService, pageService, resourcePermissionService)
    console.log("in period constructor", this.widgetMeta)
  }

  ngOnInit(): void {

    super.ngOnInit()

    console.log("in period onInit", this.widgetMeta)
    this.destroy = this.metaService.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        this.action(contextActions)
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy.unsubscribe();
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedWidgetId && (changes.selectedWidgetId.currentValue !== this.widgetMeta.id)) {
      if (this.periodMenuTrigger && this.periodMenuTrigger.menuOpen) {
        this.periodMenuTrigger.closeMenu()
      }
    }
    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }
    if(changes.widgetMeta?.currentValue){
      this.setContextActions()
    }
  }


  //----------------------------------- FUNCTIONS ---------------------------------------

  setContextActions(){
    this.contextMenuActions = {
      actions: [
        "edit",
      ],
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  action(event: any) {
    console.log("action received", event)
    if (event.actionType == "delete") {
      this.onDelete()
    }
  }

  onClick() {
    if (!this.builderMode) {
      return;
    }

    console.log("date widget clicked in widget")
    this.widgetSelection.emit(this.widgetMeta.id)
  }

  onDelete() {
    console.log("widget ID", this.widgetMeta.id, "will be deleted")
    this.widgetDeletion.emit(this.widgetMeta.id)
    this.periodMenuTrigger?.closeMenu();
  }

  inputDetected(event: any) {
    console.log("date change detected", event.value)
    let dateInput: any = {
      dataBindConfig: this.widgetMeta?.dataBindConfig,
      widgetId: this.widgetMeta.id,
      value: this.value
    }

    // if(this.value !== ''){
    //   this.dateReceived.emit(dateInput)
    // }
  }
  startDateTimeChange($event) {
    this.dateTimeValueChange('start', $event);
  }

  endDateTimeChange($event) {
    this.dateTimeValueChange('end', $event);
  }

  dateTimeValueChange(controlName: string, value: string) {
    this.range.controls[controlName].setValue(value);
  }

}
