import { Component, NgZone, OnInit, ChangeDetectorRef, ViewChild, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { environment } from 'src/environments/environment';
import { EventEmitter } from 'stream';

@Component({
  selector: 'menu-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class MenuProductsComponent implements OnInit {

  baseURL: any = environment.LOGO_BASE_URL
  constructor(
    private authService: AuthServiceService,
    private themeService: ThemeService,
    private connectionService: ConnectionService,
    private cd: ChangeDetectorRef,
    public router: Router,
    ) {
  }
  ngOnInit(): void {

  }


  openConnections(){
    this.themeService.closeMenu.next(null)
  }

  onConnectionClicked() {
    this.themeService.closeMenu.next(null)
    this.router.navigate(['connection'], {
      queryParams: {
        workspaceid: this.connectionService.workSpaceId,
        authorization: encodeURIComponent(
          this.connectionService.preAuthenticatedToken
        ),
      },
    });
  }
  onBloomClicked() {
    this.themeService.closeMenu.next(null)
    this.router.navigate(['bloom']);

  }

  onFlowClicked() {
    this.themeService.closeMenu.next(null)
    this.router.navigate(['flow']);
  }

  onFormClicked() {
    this.themeService.closeMenu.next(null)
    this.router.navigate(['form']);
  }

  onStarchClicked() {
    this.themeService.closeMenu.next(null)
    this.router.navigate(['starch']);
  }

}
