import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  cache: any = {};
  defaultTtl: number = 600000; // Default TTL in milliseconds (10 minutes)
  isLocalStorage: boolean = false;

  constructor() {}

  /**
   * Sets the flag to enable or disable the use of localStorage for caching.
   * @param {boolean} bool - A boolean flag indicating whether to use localStorage (optional, default is false).
   */
  setLocalStorage(bool?: boolean) {
    this.isLocalStorage = bool || false;
  }

  /**
   * Sets the default time to live (TTL) for cached data.
   * @param {number} ttl - The time to live (in milliseconds) for cached data.
   */
  setTimeToLive(ttl: number): void {
    this.defaultTtl = ttl;
  }

  /**
   * Retrieves the stored object from the cache or localStorage based on the provided key and options.
   * @param {string} key - The key to identify the cached data.
   * @param {any} option - Additional options for retrieving data (optional).
   * @returns {any} - The stored object or null if not found.
   */
  getStoredObject(key: string, option?: any) {
    let data = this.cache[key] || null;
    try{
      if (localStorage && !data && (this.isLocalStorage || option?.isLocalStorage)) {
        let localDataString = localStorage.getItem(key);
        if (localDataString) data = JSON.parse(localDataString);
      }
    } catch(e){}
    return data;
  }

  /**
   * Retrieves the data from the stored object based on the provided key and options.
   * @param {string} key - The key to identify the cached data.
   * @param {any} option - Additional options for retrieving data (optional).
   * @returns {any} - The data or null if not found.
   */
  getData(key: string, option?: any) {
    let map = this.getStoredObject(key, option);
    // console.log("[CACHE] getData", map)
    if (map?.data) return map.data;
    else return null;
  }

  /**
   * Sets the data in the cache with the specified key, data, and options.
   * @param {string} key - The key to identify the cached data.
   * @param {any} data - The data to be cached.
   * @param {any} option - Additional options for caching data (optional).
   */
  setData(key: string, data: any, option?: any) {
    const expiration = Date.now() + this.defaultTtl;
    let storeData = { data: data, expiration };
    this.cache[key] = storeData;
    if (localStorage !== undefined && (this.isLocalStorage || option?.isLocalStorage)) {
      // console.log("[CACHE] key in set", key, this.isLocalStorage, option?.isLocalStorage)
      try {
        // Attempt to set the item in localStorage
        localStorage.setItem(key, JSON.stringify(storeData));
      } catch (e) {
        // Handle QuotaExceededError
        if (e instanceof DOMException && e.name === 'QuotaExceededError') {
          console.error(
            '[CACHE] LocalStorage quota exceeded. Clearing localStorage.',
            localStorage.length
          );

          try {
            this.deleteLocalStorageExpiredCache();
            console.log('[CACHE] LocalStorage after clear', localStorage.length);
            localStorage.setItem(key, JSON.stringify(storeData));
          } catch (e) {
            //if this storage fails clear all
            console.error('[CACHE] all Error:', e);
            localStorage.clear();
            localStorage.setItem(key, JSON.stringify(storeData));
          }

        } else {
          console.error('[CACHE] Error:', e);
        }
      }
    }
  }

  /**
   * Checks if the data associated with the provided key is valid and not expired.
   * @param {string} key - The key to identify the cached data.
   * @returns {boolean} - True if the data is valid, false otherwise.
   */
  isValidData(key: string) {
    try {
      if (this.isCacheValid(key)) return true;
      else return false;
    } catch (e) {
      return false;
    }
  }

  /**
   * Checks if the cache associated with the provided key is valid and not expired.
   * @param {string} cacheKey - The key to identify the cache entry.
   * @returns {boolean} - True if the cache is valid, false otherwise.
   */
  isCacheValid(cacheKey: string): boolean {
    let cacheItem = this.getStoredObject(cacheKey);
    let bool = false;
    if (!cacheItem) return bool;

    //if exp time is greater that now, valid
    if (cacheItem?.expiration > Date.now()) {
      bool = true;
      //if exp time is lesser that now, not valid valid delete stored data
    } else if (cacheItem?.expiration && cacheItem.expiration < Date.now()) {
      this.clearCacheKey(cacheKey);
    }

    // console.log("[CACHE] bool", bool)
    return bool;
  }

  /**
   * Clears the cache entry associated with the given key and removes it from localStorage if present.
   * @param {string} key - The key to identify the cache entry.
   */
  clearCacheKey(key: string): void {
    if(!key || key == '') return;
    // Delete the cache entry
    delete this.cache[key];
    // Remove the entry from localStorage if it exists
    if (localStorage && localStorage?.getItem(key)) localStorage.removeItem(key);
  }

  /**
   * Deletes expired cache entries both from the in-memory cache and localStorage.
   */
  deleteExpiredCache() {
    this.deleteLocalExpiredCache();
    this.deleteLocalStorageExpiredCache();
  }

  /**
   * Deletes expired cache entries from the in-memory cache.
   */
  deleteLocalExpiredCache() {
    for (const key in this.cache) {
      let cacheItem = this.cache[key];
      if (cacheItem && cacheItem.expiration < Date.now()) {
        this.clearCacheKey(cacheItem);
      }
    }
  }

  /**
   * Deletes cache entries based on the specified key prefix from both in-memory cache and localStorage.
   * @param {string} keyPrefix - The prefix used to identify cache entries to be deleted.
   */
  deleteCacheBasedOnKeyPrefix(keyPrefix: string) {
    if(!keyPrefix || keyPrefix == '') return;
    for (const key in this.cache) {
      if (key.startsWith(keyPrefix)) {
        delete this.cache[key];
      }
    }

    for (const key in localStorage) {
      if (key.startsWith(keyPrefix)) {
        if (localStorage?.getItem(key)) localStorage.removeItem(key);
      }
    }
  }

  /**
   * Deletes expired cache entries from localStorage.
   */
  deleteLocalStorageExpiredCache() {
    for (const key in localStorage) {
      let cacheItem = localStorage[key];
      if (cacheItem) cacheItem = JSON.parse(cacheItem);
      if (cacheItem && cacheItem.expiration < Date.now()) {
        this.clearCacheKey(key);
      }
    }
  }

  /**
   * Generates a cache key based on the provided URL, options, payload, and custom options.
   * @param {string} url - The URL to be included in the cache key.
   * @param {any} options - Additional options for the cache key generation.
   * @param {any} payload - The payload to be included in the cache key.
   * @param {any} chOptions - Custom options for cache key generation.
   * @returns {string} - The generated cache key.
   */
  getCacheKey(
    url: string,
    options?: any,
    payload?: any,
    chOptions?: any
  ): string {
    let cacheKey = '';
    if (chOptions?.keyPrefix) cacheKey += chOptions.keyPrefix;
    cacheKey += url;

    let cOptions = JSON.parse(JSON.stringify(options));

    if (cOptions?.headers?.Authorization && chOptions?.avoidAuthKey)
      delete cOptions.headers.Authorization;

    if (cOptions?.headers?.traceid)
      delete cOptions.headers.traceid;

    if (cOptions) {
      const headersKey = JSON.stringify(cOptions);
      cacheKey += '_' + headersKey;
    }

    if (payload) {
      console.log('payload added');
      const payloadKey = JSON.stringify(payload);
      cacheKey += '_' + payloadKey;
    }

    return cacheKey;
  }
}
