import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { TemplateEngine } from 'src/app/core/common/TemplateEngine';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { WebSocketService } from 'src/app/core/services/SocketService';
import { FlowService } from 'src/app/flow/flow.service';
import { environment } from 'src/environments/environment';
import { BoxCache } from 'src/app/core/boxInstances/BoxCache';
import { v4 as uuidv4 } from 'uuid';
import { PollingService } from 'src/app/core/services/Polling.service';

@Component({
  selector: 'flow-execution',
  templateUrl: './flow-execution.component.html',
  styleUrls: ['./flow-execution.component.scss']
})
export class FlowExecutionComponent implements OnInit {
  urlQueryString: string = "";
  queryBody: any = {};
  isBrowser: boolean;
  isExecute:boolean
  instance:any;
  isExecuted:boolean = false;
  isExecuting:boolean = false;
  // currentFlow: any;
  boxObject:any = BoxCache.boxObject;
  flow:any;

  @Input() editFlow: any;
  @Input() publishedFlow: any;
  @Input() isEdit: boolean = false;
  instanceCode: string;

  constructor(
    // @Inject(MAT_DIALOG_DATA)
    // public dialogRef: any,
    // public dialog: MatDialogRef<FlowExecutionComponent>,
    public http: HttpClient,
    private spinner: SpinnerService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) platformId: Object,
    private websocketService: WebSocketService,
    private pollingService: PollingService,
    public fs: FlowService,
    ) {
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;
   }

  // flow: any = this.dialogRef?.flowMap;
  headerKey: any = "Authorization";
  headerValue: any;
  body: any = {};
  webhookUrl: any = '';
  isErrorOnWebhook: boolean = false;
  response: any;
  statusCode: number | undefined;

  ngOnInit(): void {
    if(!this.isBrowser) return;
    this.flow = this.isEdit ? this.editFlow : this.publishedFlow;
    this.initialize();
  }

  async ngOnChanges(changes: any){
    if(changes.isEdit?.currentValue != changes.isEdit?.previousValue){
      console.log("changed in flow:", changes, this.isEdit);

      if(!this.isEdit && !this.publishedFlow) await this.fetchPublishedFlow();
      this.flow = this.isEdit ? this.editFlow : this.publishedFlow;
      this.initialize();
    }
  }

  initialize(){
    console.log("flow now--->", this.flow);
    this.isExecute = true;
    this.fs.flowExecuted = false;
    this.isExecuting = false;

    if(this.flow.trigger_type == 'webhook'){
      if (this.flow?.trigger?.is_authenticate) this.headerValue = JSON.parse(JSON.stringify(this.flow?.trigger?.authenticate_map?.secret_key));
      if (this.flow?.trigger?.webhook_url) this.webhookUrl = `${environment.SERVER_BASE_URL}/flow/webhook/${this.flow._id}`;
      if (this.flow?.trigger?.payload_data.length > 0) {
        for (var i = 0; i < this.flow?.trigger?.payload_data.length; i++) {
          this.body[this.flow?.trigger?.payload_data[i]?.__id] = "";
        }
      }
      if (this.flow?.trigger?.query_data.length > 0) {
        this.urlQueryString = "";
        let length = this.flow?.trigger?.query_data.length;
        for (var i = 0; i < this.flow?.trigger?.query_data.length; i++) {
          let joiner = i > 0 ? "&" : i == 0 ? "?" : "";
          console.log("joiner i", i, joiner)
          this.urlQueryString = this.urlQueryString + joiner + this.flow?.trigger?.query_data[i]?.["__id"] + "=${" + this.flow?.trigger?.query_data[i]?.["__id"] + "}";
          this.queryBody[this.flow?.trigger?.query_data[i]?.__id] = "";
        }
      }
    }
  }

  async fetchPublishedFlow(){
    try{
      this.publishedFlow = await this.fs.getFlow(this.flow?.published_flow_id)
      console.log("published flow fetched", this.publishedFlow)
    }catch(e){
      console.error("could not fetch published flow", e)
    }
    return this.publishedFlow
  }



  execute(){
    this.isExecute = true;
    this.fs.flowExecuted = false;
  }

  closePopup() {
    this.isExecute = false;
  }

  ngViewAfterInit(){}

  startPolling(): void {
    this.pollingService.startPolling(this.pollingFunction.bind(this));
  }

  stopPolling(): void {
    this.pollingService.stopPolling();
    this.websocketService.setEvent({data: "", action: "ends"});
  }

  async pollingFunction() {
    let response = await this.fs.getInstanceFromCode(this.instanceCode);
    console.log('Polling function executed', response);
    let instance = JSON.parse(JSON.stringify(response));
    this.instance = instance;
    this.websocketService.setEvent({data: instance});
  }

  async executeFlow(){

    this.fs.flowExecuted = false;
    this.isExecuting = true;
    this.fs.isExecuting = true;
    this.websocketService.setEvent({data: "", action: "starts"});
    console.log("executeFlow body", this.body);
    await this.sendWebhook();
    this.isExecuting = false;
    this.fs.isExecuting = false;
    this.fs.flowExecuted = true;
  }



  async sendWebhook() {

    try {
      this.instanceCode = uuidv4();
      var res: any;
      this.startPolling();


      if(this.flow?.trigger_type == "webhook") {
        var options: any = {};

        if (this.flow?.trigger?.query_data.length > 0) {
          this.urlQueryString = this.replaceQueryString()
        }
        if (this.flow?.trigger?.is_authenticate) {
          options.headers = {
            Authorization: this.headerValue
          }
        }
        this.webhookUrl = this.webhookUrl + this.urlQueryString;
        console.log("this.webhookUrl", this.webhookUrl)
        if(this.webhookUrl.includes("?")) {
          this.webhookUrl = this.webhookUrl + `&instanceId=${this.instanceCode}`;
        } else this.webhookUrl = this.webhookUrl + `?instanceId=${this.instanceCode}`;


        let modifiedObject: any = {};
        for (let key in this.body) {
          if (this.body.hasOwnProperty(key)) {
          let newKey = key.replace(/^system\./, ""); // Remove 'system.' if it exists
            modifiedObject[newKey] = this.body[key];
          }
        }

        if (this.flow?.trigger?.method == "GET") {
          res = await this.http.get(this.webhookUrl, options).toPromise();
        } else if (this.flow?.trigger?.method == 'PUT') {
          res = await this.http.put(this.webhookUrl, modifiedObject, options).toPromise();
        } else {
          res = await this.http.post(this.webhookUrl, modifiedObject, options).toPromise();
        }
      } else {
        let flowId = this.isEdit ? this.flow?._id : this.publishedFlow._id;
        res = await this.http.post(`${environment.SERVER_BASE_URL}/flow/${flowId}?instanceId=${this.instanceCode}`, this.body, options).toPromise();
      }

      this.response = res;
      this.statusCode = res?.status;
      console.log("[TEST HOOK] response: ", res.status, this.response);
      if(this.response?.result?.instanceId) var instanceRes = await this.fs.getInstance(this.response?.result?.instanceId);

      this.instance = instanceRes?.[0] || "";
      this.fs.flowExecuted = true;

      console.log("this.instance", this.instance)
      this.stopPolling()

    } catch (e) {
      console.log("[TEST HOOK] error: ", e)
      this.isErrorOnWebhook = true;
      this.response = e;
      throw e;
    }
   // Scroll to the "Webhook executed" section
   setTimeout(() => {
    const executionDetails = this.document.getElementById('executionDetails');
    if (executionDetails) {
      executionDetails.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, 0);
}

replaceQueryString(){
  var te = new TemplateEngine()
  let modifiedObject: any = {};
  for (let key in this.queryBody) {
    let value = this.queryBody[key];
    if (this.queryBody.hasOwnProperty(key)) {
    let newKey = key.replace(/^system\./, ""); // Remove 'system.' if it exists
      modifiedObject[newKey] = value;
    }
  }
  let string = this.urlQueryString.replace(/system\./g, "");

  let result = te.fillAny(string, modifiedObject);
  return result
}



  deleteQueryParameter(index: number): void {
    this.flow.trigger.query_data.splice(index, 1);
  }

  deleteBodyParameter(index: number): void {
    this.flow.trigger.payload_data.splice(index, 1);
  }

  addNewQueryParameter(): void {
    this.flow.trigger.query_data.push({
      dataType: "string",
      name: "",
      __id: ""
    });
  }

  addNewBodyParameter(): void {
    this.flow.trigger.payload_data.push({
      dataType: "string",
      name: "",
      __id: ""
    });
  }

headers: { key: string; value: string }[] = [];

addNewHeader() {
  if (this.headerKey.trim() && this.headerValue.trim()) {
    this.headers.push({ key: this.headerKey.trim(), value: this.headerValue.trim() });
    this.headerKey = '';
    this.headerValue = '';
  }
}

copyToClipboard(data: any): void {
  const text = JSON.stringify(data, null, 2);
  const textarea = document.createElement('textarea');
  textarea.style.position = 'fixed';
  textarea.style.opacity = '0';
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  try {
    document.execCommand('copy');
    console.log('JSON data copied to clipboard');
  } catch (err) {
    console.error('Failed to copy JSON data to clipboard', err);
  } finally {
    document.body.removeChild(textarea);
  }
}

deleteHeader(index: number) {
  this.headers.splice(index, 1);
}
}
