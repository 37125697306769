<spinner [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>
<diV>

    <br/>
    <div fxLayout="row">
        <div fxLayout="column" fxFlex="5"></div>
        <div fxLayout="column" fxLayoutAlign="center start" fxFlex="90">
            <div fxLayout="row">
                <!-- <mat-icon>account_tree</mat-icon>  -->
                <h1 style="margin-bottom: 0px;"><b>{{ navigation?.extras?.state?.title || flowTitle || 'Flow Executions' }}</b></h1>
            </div>
        </div>
        <div fxLayout="column" fxFlex="5"></div>
    </div>
    <div>
        <ng-content></ng-content>
    </div>
    <br/>
    <div fxLayout="row">
        <div fxLayout="column" fxFlex="5"></div>
        <div fxLayout="column"  fxFlex="90">
            <div fxLayout="row" fxLayoutAlign="center center" fxFlex="100%">
                <div fxLayout="column" fxFlex="30">
                </div>
                <div fxFlex="20">
                    <button mat-stroked-button (click)="showSummary = !showSummary;loadSummary()" color="primary">Show summary</button>
                </div>
                <div fxLayout="column" fxFlex="5">
                    From
                </div>
                <div fxLayout="column" fxFlex="15">
                    <mat-form-field appearance="outline">
                        <input matInput [(ngModel)]="triggerDateFrom" [matDatepicker]="pickerFrom">
                        <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                        <mat-datepicker  #pickerFrom></mat-datepicker>
                    </mat-form-field>
                </div>
                <div fxLayout="column" fxFlex="2"></div>
                <div fxLayout="column" fxFlex="3">
                    To
                </div>
                <div fxLayout="column" fxFlex="15">
                    <mat-form-field  appearance="outline">
                        <input matInput [(ngModel)]="triggerDateTo" [matDatepicker]="pickerTo">
                        <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                        <mat-datepicker  #pickerTo></mat-datepicker>
                    </mat-form-field>
                </div>
                <div fxLayout="column" fxLayoutAlign="center center"  fxFlex="10">
                    <button style="height: 31px;" mat-stroked-button color="primary" (click)="getInstances()"  type="button">
                        list
                      </button>
                </div>
            </div>
        </div>
        <div fxLayout="column" fxFlex="5"></div>
    </div>
    <br/>
    <div fxLayout="row" *ngIf="flowSummary.length && showSummary" fxLayoutAlign="center center" >
        <app-list-panel style="width: 80%;" [panelMeta]="flowSummaryPanelMeta" [inputData]="flowSummary" [builderMode]="builderMode">
        </app-list-panel>
    </div>

    <div fxLayout="row" *ngIf="!flowInstances || flowInstances.length == 0" fxLayoutAlign="center center">
      There are no executions in the selected period. You can change the period and try again
    </div>

    <div fxLayout="row" *ngIf="flowInstances.length > 0" >
        <div fxLayout="column" fxFlex="5"></div>
        <div fxLayout="column" fxFlex="90">
            <div   *ngFor="let inst of flowInstances;let i = index">
                <mat-card   appearance="outlined"   class="list-card step-card" fxLayout="row wrap"
                 layout-wrap fxLayoutAlign="start center">
                <div fxLayout="column wrap" class="align-text-center break-word" fxFlex="5">
                    {{i + 1}}
                 </div>
                 <div fxFlex="5" fxLayout="column wrap" class="break-word">
                    <img *ngIf="inst.trigger_type === 'app_event'"  [src]="inst.box_logo_url" class="image-size" />
                    <mat-icon  class="image-size" *ngIf="inst.trigger_type === 'schedule'" >  schedule</mat-icon>
                    <mat-icon  class="image-size" *ngIf="inst.trigger_type === 'webhook'">  webhook</mat-icon>
                    </div>
                <div fxFlex="20" fxLayout="column wrap" fxLayoutAlign="center start" class="break-word">
                    {{ inst.trigger_type === 'schedule' ? "Schedule" : inst.trigger_type === 'webhook' ? "Webhook" : inst.box_id}} </div>
                <div fxFlex="25" fxLayout="column wrap" fxLayoutAlign="center start" class="break-word">
                    {{ inst.event_name}}
                </div>
                <div matTooltip="Triggered At" fxFlex="20" fxLayoutAlign="center start" fxLayout="column wrap" class="break-word">
                    {{inst.triggered_at | date:'medium'}} </div>
                <div matTooltip="Finished At" fxFlex="20" fxLayout="column wrap" fxLayoutAlign="center start" class="break-word">
                    {{inst.finished_at | date:'medium'}} </div>
                <div fxFlex="5" fxLayout="column wrap" fxLayoutAlign="center start" class="break-word">
                    <button mat-icon-button matTooltip="View steps executions" (click)="inst.isStepInfo = !inst.isStepInfo">
                        <mat-icon color="primary" style="text-decoration: none">expand_more</mat-icon>
                    </button>
                </div>
              </mat-card>
              <div fxLayoutAlign="center center" fxLayout="row wrap" fxLayoutGap="5" *ngIf="inst.isStepInfo">
                <div fxLayout="column" fxFlex="5">
                </div>
                <mat-card  appearance="outlined"  fxLayout="column" fxFlex="90">
                    <div fxLayout="row" fxLayoutAlign="center center" >
                        <h2 style="margin-bottom: 0px;">Steps Executed</h2>
                    </div>
                    <mat-card  appearance="outlined"  class="list-card-step step-card" fxLayout="row wrap"  *ngFor="let step of inst.steps;let j = index"
                        layout-wrap fxLayoutAlign="start center">
                        <div fxLayout="column wrap" class="align-text-center break-word" fxFlex="5">
                            {{j + 1}}
                        </div>
                        <div fxFlex="5" fxLayout="column wrap" class="break-word">
                            <img [src]="step.box_logo_url" class="image-size-step" />
                        </div>
                        <div fxFlex="15" fxLayout="column wrap" fxLayoutAlign="center start" class="break-word">
                            {{step.box_id}}
                        </div>
                        <div fxFlex="20" fxLayout="column wrap" fxLayoutAlign="center start" class="break-word">
                            {{step.action_name}}
                        </div>
                        <div fxFlex="10" fxLayout="column wrap" fxLayoutAlign="center start" class="break-word">
                        </div>
                        <div fxFlex="20" matTooltip="Action At" fxLayout="column wrap" fxLayoutAlign="center start" class="break-word">
                            {{step.action_at | date:'medium'}}
                        </div>
                        <div fxFlex="20" fxLayout="column wrap" class="align-text-center break-word">
                            {{step.status}}
                        </div>
                        <div fxFlex="5" fxLayout="column wrap" fxLayoutAlign="center start" class="break-word" *ngIf="step.data_flow === 'push'">
                            <button mat-icon-button matTooltip="View steps executions details" (click)="step.isStepInfo = !step.isStepInfo">
                                <mat-icon color="primary" style="text-decoration: none">expand_more</mat-icon>
                            </button>
                        </div>

                        <div fxLayoutAlign="center center" fxLayout="row wrap" fxLayoutGap="5" *ngIf="step.isStepInfo">
                            <div fxLayout="column" fxFlex="5">
                            </div>
                            <mat-card  appearance="outlined"  fxLayout="column" fxFlex="90">
                                <app-list-panel
                                    [panelMeta]="{listPanelTitle: 'Steps Details'}"
                                    [inputData]="step.output?.result?.data || []"
                                    [builderMode]="false">
                                </app-list-panel>
                            </mat-card>
                            <div fxLayout="column" fxFlex="5">
                            </div>
                        </div>
                    </mat-card>


                </mat-card >
                <div fxLayout="column" fxFlex="5">
                </div>
              </div>
            </div>
        </div>
        <div fxLayout="column" fxFlex="5"></div>
      </div>



</diV>
