import { MetaService } from './../../../services/meta-service';
import { Component, OnInit, ViewChild, OnChanges, SimpleChanges, KeyValueDiffers } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { UntypedFormControl, } from '@angular/forms';

import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { ImageInput } from 'src/app/bloom/models/widgetClasses/imageinput';
import { Subject } from 'rxjs';
// import type { SnackBarValues } from 'src/app/shared/snackbar/snackbar.module';
import { SnackbarComponent } from 'src/app/shared/snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';


@Component({
  selector: 'app-imageinput',
  templateUrl: './imageinput.component.html',
  styleUrls: ['./imageinput.component.css']
})
export class ImageInputComponent extends BaseWidgetComponent implements OnInit, OnChanges {

  contextMenuActions: {};
  isMulti: boolean = false
  hoveredNow: boolean = false;
  timer: UntypedFormControl = new UntypedFormControl('');
  publisherEmail: string = '';

  imageSrc: string
  storePath: string = "bloom/image-input/"
  existingImages: any[]
  oldMeta: any

  @ViewChild('menuTrigger') imageInputMenuTrigger: MatMenuTrigger
  eventTrigger: any;
  spinner: boolean
  private destroy:any = new Subject();
  differ: any;
  //------------------------------constructor---------------------------------
  constructor(
    private adminService: AdminService,
    private meta: MetaService,
    private differs: KeyValueDiffers,
    private _snackBar: MatSnackBar,
    public metaService: MetaService,
    public pageService: PageService,
    public resourcePermissionService: ResourcePermissionService
  ) {
    super(metaService, pageService, resourcePermissionService)

  }

  //---------------------------------ngONInit----------------------------------------
  ngOnInit(): void {
    this.differ = this.differs.find(this.widgetMeta).create();
    this.meta.fetchedBloomMeta.subscribe((res: any) => {
      this.publisherEmail = res.publisher_email;
      console.log(this.publisherEmail);
    })

    super.ngOnInit()
    console.log("in time onInit", this.widgetMeta)
    this.storePath = this.storePath + this.widgetMeta.id;
    this.destroy = this.meta?.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        this.action(contextActions)
      }
    })

    this.eventTrigger = ImageInput.imageInputEvent$.subscribe((data) => {

      if(!data)return;
      if(this.widgetMeta.id == data.id){
        console.log("image input src event triggered--->", data);
        this.widgetMeta.config.src.value = this.widgetMeta.config.src.value;
      }

    })

  }

  ngDoCheck(): void {
    // console.log("oldMeta", JSON.parse(JSON.stringify(this.oldMeta || {})))
    // console.log("widgetMeta", JSON.parse(JSON.stringify(this.widgetMeta || {})))
    // let isDiff = this.differ.diff(this.widgetMeta)
    // if(isDiff){
    //   console.log("oldMeta", JSON.parse(JSON.stringify(this.oldMeta || {})))
    //   console.log("widgetMeta", JSON.parse(JSON.stringify(this.widgetMeta || {})))
    //   console.log("differed", isDiff)
    // }
    // else{
    //   console.log("no diff")
    // }
    if((!this.oldMeta && this.widgetMeta) || (JSON.stringify(this.oldMeta) != JSON.stringify(this.widgetMeta))){
      console.log("oldMeta", JSON.parse(JSON.stringify(this.oldMeta || {})))
      console.log("widgetMeta", JSON.parse(JSON.stringify(this.widgetMeta || {})))
      this.oldMeta = JSON.parse(JSON.stringify(this.widgetMeta))
      let iterableRes = this.isIterable(this.widgetMeta.config.src.value)
      if(iterableRes.isIterable){
        console.log("inside isIterable", iterableRes)
        this.isMulti = true
        // this.existingImages = this.isIterable(this.widgetMeta.config.src.value).value
        this.existingImages = JSON.parse(JSON.stringify(iterableRes.value))
      }else{
        this.existingImages = []
        if(this.widgetMeta.config.src.value) this.existingImages.push(this.widgetMeta.config.src.value)
      }
      console.log("existing images set", this.existingImages)
    }
  }

  ngOnDestroy(){
    this.eventTrigger?.unsubscribe();
    this.destroy?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedWidgetId && (changes.selectedWidgetId.currentValue !== this.widgetMeta.id)) {
      if (this.imageInputMenuTrigger && this.imageInputMenuTrigger.menuOpen) {
        this.imageInputMenuTrigger.closeMenu()
      }
    }
    if(changes?.widgetMeta?.currentValue){
      console.log("image url", changes.widgetMeta.currentValue.config.src.value)
      // this.imageSrc = changes.widgetMeta.currentValue.config.src.value
      this.widgetMeta.config.src.value = changes.widgetMeta.currentValue.config.src.value;

      this.setContextActions()
    }
    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }
  }


  //----------------------------------- FUNCTIONS ---------------------------------------

  isIterable(data){
    // console.log("data", JSON.parse(JSON.stringify(data)))
    let res = {
      isIterable: false,
      value: []
    }
    if(typeof data == 'object' && Array.isArray(data)){
      res.isIterable = true
      res.value = data.filter(item => !!item )
      return res
    }else if(typeof data == 'object'){
      let keys = Object.keys(data)
        for(let i = 0; i < keys.length; i++){
          if(isNaN(parseInt(keys[i]))) {
            res.isIterable = false
            return res
          }else if(data[keys[i]]){
            res.value.push(data[keys[i]])
          }
        }
        res.isIterable = true
        return res
    }else if(typeof data == 'string'){
      try{
        let obj = JSON.parse(data)
        let keys = Object.keys(obj)
        for(let i = 0; i < keys.length; i++){
          if(isNaN(parseInt(keys[i]))) {
            res.isIterable = false
            return res
          }else if(obj[keys[i]]){
            res.value.push(obj[keys[i]])
          }
        }
        res.isIterable = true
        return res
      }catch(e){
        return res
      }
    }else{
      return res
    }

  }

  setContextActions(){
    this.contextMenuActions = {
      actions: [
        "edit",
      ],
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  action(event: any) {
    console.log("action received", event)
    if (event.actionType == "delete") {
      this.onDelete()
    }
  }

  onClick() {
    if (!this.builderMode) {
      return;
    }

    console.log("time widget clicked in widget")
    this.widgetSelection.emit(this.widgetMeta.id)
  }

  onDelete() {
    console.log("widget ID", this.widgetMeta.id, "will be deleted")
    this.widgetDeletion.emit(this.widgetMeta.id)
    this.imageInputMenuTrigger.closeMenu();
  }

  async userInputDetected(event: any) {
    console.log("image input detected --->", event)
    console.log("existing images", this.existingImages)
    this.spinner = true;
    // let imageUrls: string[] = []
    // if(this.isIterable(this.widgetMeta.config.src.value)){
    //   imageUrls = imageUrls.concat(this.widgetMeta.config.src.value)
    // }else if(this.widgetMeta.config.src.value){
    //   imageUrls[0] = this.widgetMeta.config.src.value
    // }
    if(!event){
      this.widgetMeta.config.src.value = "";
      let userInput: any = {
        dataBindConfig: this.widgetMeta?.dataBindConfig,
        widgetId: this.widgetMeta.id,
        value: ""
      }
      this.spinner = false;
      this.userInputReceived.emit(userInput);
    }

    let data = []
    if(typeof event == 'object' && Array.isArray(event)){
      data = event
    }else{
      data[0] = event
    }
    console.log("data array formed", data)

    this.spinner = true
    for(let i = 0; i < data.length; i ++){
      let res
      try{
        res = await this.adminService.fileUpload(data[i].image || data[i], this.publisherEmail)

        console.log("image uploaded", res)
        if(typeof data[i].index == 'number' && data[i].index > -1) this.existingImages[data[i].index] = res
        else if (this.widgetMeta.config.multi.value) this.existingImages.push(res)
        else if (data[i].index) { this.existingImages[data[i].index] = res }  // when an image is changed at given index
        else if (this.existingImages.length == 0 || !data[i].hasOwnProperty('index')) { this.existingImages[0] = res }  // first image

        console.log("existing images after assigning new", JSON.parse(JSON.stringify(this.existingImages)))

      }catch(e){
        console.log("failed to upload screenshot image", e)
        this.openSnackBar({snackBarMessage: 'Failed to upload image'}, e)
      }
    }
    this.spinner = false

    console.log("file(s) uploaded", this.existingImages)
    this.emitOutput()
  }

  itemRemoved(data){
    console.log("urlListChanged changed", data)
    console.log("old array", JSON.parse(JSON.stringify(this.existingImages)))
    this.existingImages = JSON.parse(JSON.stringify(data))
    console.log("new array", JSON.parse(JSON.stringify(this.existingImages)))
    this.emitOutput()
  }

  emitOutput(){
    if(this.existingImages.length == 0) {
      this.widgetMeta.config.src.value = ""
    }else if(this.existingImages.length == 1) {
      this.widgetMeta.config.src.value = this.existingImages[0]
    }else {
      this.widgetMeta.config.src.value = this.existingImages
    }

    let userInput: any = {
      dataBindConfig: this.widgetMeta?.dataBindConfig,
      widgetId: this.widgetMeta.id,
      value: this.widgetMeta.config.src.value
    }
    this.userInputReceived.emit(userInput);
  }

  openSnackBar(snackBarObj: any, error: any){
    this._snackBar.openFromComponent(SnackbarComponent, {
      data: {
        title: error?.error?.error?.name || error.message || '',
        description: error?.error?.error?.message || error?.error?.error || error?.error?.message || '',
        recommendation: snackBarObj?.snackBarRecommendation || '',
        message: snackBarObj.snackBarMessage,
        iconname: snackBarObj.snackBarIcon,
        isError: true
      },
      duration: snackBarObj.snackBarDuration || undefined,
      horizontalPosition: 'end',
    });
  }

}
