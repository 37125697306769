<div
  class="outerCover"
  *ngIf="builderMode"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [class.selectedNow]="selectedWidgetId == widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId == widgetMeta.id? false: hoveredNow"
  (mouseenter)="widgetMouseenter()"
  (mouseleave)="widgetMouseleave()"
  (click)="onClick()"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <widget-drag-handle [class.invisible]="(selectedWidgetId !== widgetMeta.id && !hoveredNow)"></widget-drag-handle>

  <div class="innerCover" fxLayout="row" fxLayoutGap="5px">
    <div [ngSwitch]="periodType" style="width: 100%;">
      <mat-form-field *ngSwitchCase="'date'" style="width: 95%;">
        <mat-label>{{ widgetMeta.config.placeholder.value }}</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
          <input matStartDate formControlName="start" placeholder="Start date">
          <input matEndDate formControlName="end" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
      </mat-form-field>
      <div *ngSwitchCase="'time'" fxLayout="row" fxLayoutGap="5px">
        <mat-form-field style="width: 95%;">
          <mcc-timer-picker mccConnectedTimerPicker [mccConnectedTimerPickerOrigin]="inputTrigger"
            mccTimerPickerFormat="{{timeFormat}}"></mcc-timer-picker>
          <input matInput mccTimerPickerOrigin placeholder="{{ widgetMeta.config.placeholder.value }}"
            #inputTrigger="mccTimerPickerOrigin" [(ngModel)]="timer.start" />
          <button mat-icon-button matSuffix>
            <mat-icon>schedule</mat-icon>
          </button>
        </mat-form-field>
        <mat-icon style="align-self: center;">remove</mat-icon>
        <mat-form-field style="width: 95%;">
          <mcc-timer-picker mccConnectedTimerPicker [mccTimerPickerMin]="timer.start"
            [mccConnectedTimerPickerOrigin]="inputTrigger1" mccTimerPickerFormat="{{timeFormat}}"></mcc-timer-picker>
          <input matInput mccTimerPickerOrigin placeholder="{{ widgetMeta.config.placeholder.value }}"
            #inputTrigger1="mccTimerPickerOrigin" [(ngModel)]="timer.end" />
          <button mat-icon-button matSuffix>
            <mat-icon>schedule</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div *ngSwitchCase="'datetime'" fxLayout="row" fxLayoutGap="5px" [formGroup]="range">
        <mat-form-field style="width: 95%;">
          <input matInput formControlName="start">
          <div matSuffix datetimepicker timeFormat="24" (valueChange)="startDateTimeChange($event)"></div>
        </mat-form-field>
        <mat-icon style="align-self: center;">remove</mat-icon>
        <mat-form-field style="width: 95%;">
          <input matInput formControlName="end">
          <div matSuffix datetimepicker timeFormat="24" (valueChange)="endDateTimeChange($event)"></div>
        </mat-form-field>
      </div>
      <div *ngSwitchDefault></div>
    </div>
    <button style="align-self: center;" mat-icon-button [matMenuTriggerFor]="menutype" (click)="$event.stopPropagation()">
      <mat-icon>expand_more</mat-icon>
    </button>
  </div>

  <mat-menu #menutype hasBackdrop="true" yPosition="above" xPosition="before">
    <button mat-menu-item (click)="periodType='date'">Date</button>
    <button mat-menu-item (click)="periodType='datetime'">Date Time</button>
    <button mat-menu-item (click)="periodType='time'">Time</button>
  </mat-menu>
</div>

<div *ngIf="!builderMode" class="outerCover" appearance="standard"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <div class="innerCover">
    <div [ngSwitch]="periodType" style="width: 100%;">
      <mat-form-field *ngSwitchCase="'date'" style="width: 95%;">
        <mat-label>{{ widgetMeta.config.placeholder.value }}</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
          <input matStartDate formControlName="start" placeholder="Start date">
          <input matEndDate formControlName="end" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
      </mat-form-field>
      <div *ngSwitchCase="'time'" fxLayout="row" fxLayoutGap="5px">
        <mat-form-field style="width: 95%;">
          <mat-label>{{ widgetMeta.config.placeholder.value }}</mat-label>
          <input matInput type="time" [formControl]="timer">
        </mat-form-field>
        <mat-icon style="align-self: center;">remove</mat-icon>
        <mat-form-field style="width: 95%;">
          <mat-label>{{ widgetMeta.config.placeholder.value }}</mat-label>
          <input matInput type="time">
        </mat-form-field>
      </div>
    <div *ngSwitchDefault></div>
  </div>
</div>
