import { Component, Input, OnInit, OnChanges, Output, ViewChild, EventEmitter, SimpleChanges, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MetaService } from '../../../services/meta-service';
import { Router, ActivatedRoute } from '@angular/router'
import { Subject } from 'rxjs';

import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { PLATFORM_ID } from '@angular/core';
import { Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { PageService } from 'src/app/bloom/services/page-service.service';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.css']
})
export class LinkComponent extends BaseWidgetComponent implements OnInit, OnChanges {

  contextMenuActions: any;
  styles: any;
  // @ViewChild('menuTrigger') linkMenuTrigger: MatMenuTrigger
  @ViewChild('textEditInput', { static: false }) private _textEditInput: ElementRef;
  textEditMode: boolean = false;
  prevent: boolean = false;
  timer: any = 0;
  private destroy:any = new Subject();
  isBrowser: boolean;

  constructor(
    public metaService: MetaService,
    private _router: Router,
    public pageService: PageService,
    public resourcePermissionService: ResourcePermissionService,
    @Inject(PLATFORM_ID) platformId?: Object
    ) {
      super(metaService, pageService, resourcePermissionService)
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;
    // super call should be the first statement in any derived class constructor
    //if property injections from parent needs to be referenced, (same copy), it needs to be passed into super. e.g. super(router)

  }

  ngOnInit(): void {
    super.ngOnInit()
    this.destroy = this.metaService.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        this.action(contextActions)
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes.selectedWidgetId && (changes.selectedWidgetId.currentValue !== this.widgetMeta.id)) {
    //   if (this.linkMenuTrigger && this.linkMenuTrigger.menuOpen) {
    //     this.linkMenuTrigger.closeMenu()
    //   }
    // }
    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }
    if(changes.widgetMeta?.currentValue){
      this.setContextActions()
    }
  }

  //------------------METHODS-----------------------

  setContextActions(){
    this.contextMenuActions = {
      actions: [
        "edit",
        "bold",
        "underline",
        "italic",
        "color",
        "fontSize",
        "fontFamily",
        "class"
      ]
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  action(event) {
    console.log("action is", event)
    switch (event.actionType) {
      case "delete":
        this.onDelete()
        break;
      case "updateStyles":
        if (event?.data) {
          this.widgetMeta = event.data;
          super.generateStyles()
          // this.newWidgetMeta.emit(this.widgetMeta)
        }
        break;
        default:
        break;
    }
  }

  onClick(event: any) {
    console.log("link clicked")
    let currentBloomCode;
    this.metaService.get_bloomMeta.subscribe((data) => {
      currentBloomCode = data;
      console.log(currentBloomCode)
    });
    // event.stopPropagation()
    if (!this.builderMode) {

      //if same site navigation, use angular router

      //if cross site navigation, replace window.loaction.href
      // window.location.href = this.widgetMeta.config.linkUrl.value
      if(this.isValidUrl(this.widgetMeta.config.linkUrl.value)){
        window.open(this.widgetMeta.config.linkUrl.value, "_blank")
        console.log("can navigate to:", this.widgetMeta.config.linkUrl.value)
      }else{
        this._router.navigate(['p', currentBloomCode.code, this.widgetMeta.config.linkUrl.value]);
        console.log("can navigate to:", this.widgetMeta.config.linkUrl.value)
      }

    }
    // else {
    //   //do not navigate, let context menu open
    //   if (this.linkMenuTrigger.menuOpen) {
    //     this.linkMenuTrigger.closeMenu()
    //   } else {
    //     this.linkMenuTrigger.openMenu()
    //   }
    // }

    this.widgetSelection.emit(this.widgetMeta.id)
  }
  isValidUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  onOuterClick() {
    console.log("link clicked in widget")
    if (!this.builderMode) {
      return;
    }
    this.widgetSelection.emit(this.widgetMeta.id)
  }

  onDelete() {
    console.log("widget ID", this.widgetMeta.id, "will be deleted")
    this.widgetDeletion.emit(this.widgetMeta.id)
    // this.linkMenuTrigger.closeMenu();
  }
  onDoubleClicked() {
    console.log('Double Click');
    if (!this.builderMode) {
      this.textEditMode = false;
      return;
    }
    clearTimeout(this.timer);
    // this.prevent = true;
    this.turnOnEditMode();
  }

  turnOnEditMode() {
    this.textEditMode = true;
    setTimeout(() => { this._textEditInput.nativeElement.focus(); }, 0);
  }
  onFocusOut(event: any) {
    this.textEditMode = false;
  }
}
