
<div *ngIf="isExecute && flow" class="canvas-wrap container-with-thin-scrollbar">

<div *ngIf="!isExecuting && !fs.flowExecuted">
  <div fxLayout="row" class="mat-h3" fxLayoutAlign="center center"> Execute your {{flow.trigger_type}} {{ flow.trigger_type == 'webhook' ? flow.trigger?.method : "" }}</div>
<div *ngIf="flow.trigger_type == 'webhook'">
  <div fxLayout="row">
    <div fxLayout="column">
      <!-- Headers Section -->
      <div fxLayout="row" class="mat-h2" fxLayoutAlign="start center" style="margin-bottom: -10px;">
        Headers
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start center">
        <div fxLayout="column">
          <mat-form-field class="full-width" appearance="standard">
            <mat-label>{{headerKey}}</mat-label>
            <input matInput placeholder="Value" [(ngModel)]="headerValue"  class="input-field">
          </mat-form-field>
        </div>
      </div>
      <!-- List of Headers -->
      <div fxLayout="row" *ngFor="let header of headers; let i = index" fxLayoutAlign="start center">
        <div fxLayout="row wrap" id="header-key-value">
          <mat-form-field class="full-width" appearance="standard">
            <mat-label>{{header.key}}</mat-label>
            <input matInput placeholder="Value" [(ngModel)]="header.value" class="input-field">
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div fxLayout="row" >
    <div fxLayout="column">
      <div fxLayout="row" class="mat-h2" fxLayoutAlign="start center" style="margin-bottom: -10px;">
        Query Parameters
      </div>
      <div fxLayout="row" *ngFor="let data of flow.trigger.query_data; let i = index" fxLayoutAlign="start center">
        <div fxLayout="row wrap" id="body-key-value">
          <mat-form-field class="full-width" appearance="standard">
            <mat-label>{{data.__id}}</mat-label>
            <input matInput placeholder="Value" [(ngModel)]="queryBody[data.__id]" class="input-field">
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div fxLayout="row" *ngIf="flow.trigger?.method != 'GET'">
    <div fxLayout="column">
      <div fxLayout="row" class="mat-h2" fxLayoutAlign="start center" style="margin-bottom: -10px;">
        Body Parameters
      </div>
      <div fxLayout="row wrap" *ngFor="let data of flow.trigger.payload_data; let i = index" fxLayoutAlign="start center">
        <div fxLayout="column" id="body-key-value" class="">
          <mat-form-field class="full-width" appearance="standard">
            <mat-label>{{data.__id}}</mat-label>
            <input matInput placeholder="Value" [(ngModel)]="body[data.__id]" class="input-field">
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>


<br>
<div fxLayout="row" fxLayoutAlign="center center">
  <button id="scrollTarget" style="cursor: pointer;" (click)="executeFlow()" color="primary"
   mat-raised-button matTooltip="Execute flow">
    <mat-icon class="smallIcon">play_arrow</mat-icon>Execute
  </button>
</div>
</div>

<!-- <div fxLayout="row" *ngIf="fs.isExecuting"  fxLayoutAlign="center center">
  <mat-spinner [diameter]="20" color="accent"></mat-spinner>
</div> -->

<div fxLayout="row"  *ngIf="fs.flowExecuted">
  <div class="mat-h3"> Flow executed </div>
</div>

<div *ngIf="fs.isExecuting || fs.flowExecuted">
  <div fxLayout="row" fxLayoutAlign="start center">
    <div class="mat-h2"> Step Result </div>
    <mat-spinner *ngIf="fs.isExecuting " [diameter]="20" color="accent"></mat-spinner>
  </div>

  <div *ngIf="instance">
        <div fxLayout="column" fxFlex="5"></div>
        <div fxLayout="column" fxFlex="90">
          <mat-accordion  multi="true">
            <div *ngFor="let step of instance.steps; let i = index">
            <mat-expansion-panel >
              <mat-expansion-panel-header style="padding: 10px;">
                <mat-panel-title>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon style="color: green;" *ngIf="step.status == 'success'; else elseIcon"
                      >check_circle</mat-icon>
                      <ng-template #elseIcon>
                        <mat-icon style="color:brown;">cancel</mat-icon>
                      </ng-template>

                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxLayout="column" fxLayoutAlign="center center">
                          Step {{i + 1}}
                        </div> &nbsp;
                        <div fxLayout="column" fxLayoutAlign="center center">
                          <div fxLayout="row" fxLayoutAlign="center center" *ngIf="step?.step_type == 'action' && step?.box_name">
                            {{step?.box_name}} &nbsp;
                            <img
                              *ngIf="boxObject[step.box_id]?.logo"
                              [src]="boxObject[step.box_id]?.logo"
                              class="image-size"
                            />
                          </div>
                          <div fxLayout="row" fxLayoutAlign="center center" *ngIf="step?.step_type == 'processor'">
                            {{step.processor_type | titlecase }} &nbsp;
                            <img
                              *ngIf="boxObject[step.box_id]?.logo"
                              [src]="boxObject[step.box_id]?.logo"
                              class="image-size"
                            />
                          </div>

                         </div>
                      </div>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div fxLayout="row">
                <div fxLayout="column" fxFlex="5">
                </div>
                <div fxLayout="column" fxFlex="90">
                 <div fxLayout="row">
                  {{step.step_type | titlecase}} &nbsp;
                 </div>
                 <div fxLayout="row" fxLayoutAlign="start center" *ngIf="step.step_type == 'action'">
                  {{step.box_name}} &nbsp;
                  <img
                    *ngIf="boxObject[step.box_id]?.logo"
                    [src]="boxObject[step.box_id]?.logo"
                    class="image-size"
                  />
                 </div>
                 <div fxLayout="row" fxLayoutAlign="start center" *ngIf="step.step_type == 'processor'">
                  {{step.processor_type | titlecase}} &nbsp;
                  <img
                    *ngIf="boxObject[step.box_id]?.logo"
                    [src]="boxObject[step.box_id]?.logo"
                    class="image-size"
                  />
                 </div>
                 <div fxLayout="row" fxLayoutAlign="start center" *ngIf="step?.step_type == 'processor'">
                  {{step.processor_sub_type | titlecase }} &nbsp;
                </div>
                 <div fxLayout="row" *ngIf="step.step_type == 'action'">
                  Count: {{step.count}}
                 </div>
                 <div fxLayout="row" style="color: darkgreen;font-weight: 800;" *ngIf="step.status == 'success'; else elseStatus">
                    Success
                 </div>
                 <ng-template #elseStatus>
                   <div fxLayout="row" style="color:brown;font-weight: 800;">
                      Failure
                   </div>
                 </ng-template>
                </div>
                <div fxLayout="column" fxFlex="5">
                </div>
              </div>
            </mat-expansion-panel>
            </div>
          </mat-accordion>
        </div>
        <div fxLayout="column" fxFlex="5"></div>
    </div>

</div>


<div *ngIf="fs.flowExecuted ">
  <div class="mat-h2"> Raw response </div>
</div>

<div *ngIf="fs.flowExecuted" id="executionDetails" style="margin-left: 10px" fxLayout="row">
  <mat-accordion *ngIf="response" style="width: 100%;">
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title fxLayout="row" matTooltip="Click to see the details of execution">
          {{flow.trigger_type}} executed
        </mat-panel-title>
        <!-- <mat-panel-description fxLayout="row">
          Status Code: {{ response?.status ?? 'N/A' }}
        </mat-panel-description> -->
        <mat-icon matTooltip="Click to see the details of execution">expand_more </mat-icon>
      </mat-expansion-panel-header>
      <div fxLayout="row">
        <div fxLayout="column" fxFlex="5">
        </div>
        <div fxLayout="column" fxFlex="90">
          <div style="font-size: small;" *ngIf="flow.trigger_type == 'webhook'">{{ this.flow?.trigger?.webhook_url }}</div>
          <h2 style="margin-bottom: 10px; font-size: 14px;">Response Code: {{ response?.status ?? 'N/A' }}</h2>
          <h2 style="margin-bottom: 10px; font-size: 14px;" *ngIf="response">Response</h2>
          <!-- <pre *ngIf="response">{{ response | json }}</pre> -->
          <div fxLayout="row" fxLayoutAlign="end end" style="    margin-bottom: -40px;">
            <button mat-icon-button color="accent" (click)="copyToClipboard(response?.result || response)">
            <mat-icon>content_copy</mat-icon>
          </button></div>
          <div class="textureBackground">
            <nxt-json-view [levelOpen]="2" [data]="response?.result || response"></nxt-json-view>
          </div>
        </div>
        <div fxLayout="column" fxFlex="5">
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
</div>


