import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { ThemeService } from './shared/services/theme.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Router, NavigationEnd, RoutesRecognized, ActivatedRoute } from '@angular/router';
import { MixpanelService } from './shared/services/mixpanel.service';
import { ConnectionService } from './modules/organization/connection.service';
import { environment } from 'src/environments/environment';
import { filter, pairwise } from 'rxjs/operators';
import { PageService } from './bloom/services/page-service.service';
import { SeoService } from './core/services/SeoService';
import { BoxCache } from './core/boxInstances/BoxCache';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  // styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'AppiWorks';
  routerSubscription: any;
  isBrowser: any;

  constructor(
    public themeService: ThemeService,
    private translate: TranslateService,
    public router: Router,
    public msp: MixpanelService,
    public orgService: ConnectionService,
    public pageService: PageService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) platformId: Object,
    public seoService: SeoService
  ) {
    translate.addLangs(['en', 'fr']);
    this.isBrowser = isPlatformBrowser(platformId);
    // if(!this.isBrowser) return;

    this.routerSubscription = router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const urls = event.urlAfterRedirects.split("?");
        if (orgService.userProfile?._id) {
          const trackMap: any = { type: "page" };
          trackMap.path = urls.length > 1 ? urls[1] : undefined;
          this.msp?.track(urls[0], trackMap);
        }
      }
    });

    router.events.pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        console.log('previous url', events[0].urlAfterRedirects);
        console.log('current url', events[1].urlAfterRedirects);
        pageService.previousUrl = events[0].urlAfterRedirects;
      });
  }

  ngOnDestroy() {
    // Unsubscribe from router events to prevent memory leaks
    this.routerSubscription?.unsubscribe();
  }

  ngOnInit() {
    if(this.isBrowser){
      this.seoService.updateTitleAndMeta();
      this.loadBoxData();
    }
    // Additional initialization logic if needed
  }

  private loadBoxData(): void {
    BoxCache.loadBoxes()
  }
}
