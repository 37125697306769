import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { workspaceModule } from '../shared/workspace/workspace.module';
import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';
import { DisplayBannerModule } from '../shared/display-banner/display-banner.module';
import { WidgetsModule } from '../bloom/widgets/widgets.module';
import { FieldPickerModule } from '../shared/field-picker/field-picker.module';
import { ListPanelModule } from '../bloom/specialized-panels/list-panel/list-panel.module';

import { FormRoutingModule } from './form-routing.module';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTabsModule} from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {MatRadioModule} from '@angular/material/radio';

import { FormBuilderComponent } from './form-builder/form-builder.component';
import { FormHomeComponent } from './form-home/form-home.component';
// import { FormToolbarComponent } from './form-toolbar/form-toolbar.component';
import { FormCanvasComponent } from './form-canvas/form-canvas.component';
import { CreateFormPopupComponent } from './create-form-popup/create-form-popup.component';
import { FormPageComponent } from './form-page/form-page.component';
import { QoutaPopupModule } from '../shared/qouta-popup/qouta.popup.module';
import { BloomWithoutRoutingModule } from '../bloom/bloom-without-routing.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PublishDialogComponent } from './publish-dialog/publish-dialog.component';
import { FormViewerCanvasComponent } from './form-viewer-canvas/form-viewer-canvas.component';
import { ResponsesComponent } from './responses/responses.component';
import { SuccessPageConfigComponent } from './success-page-config/success-page-config.component';
import { FormSettingsComponent } from './form-settings/form-settings.component';
import { NewFormComponent } from './new-form/new-form.component';
import { BuilderToolbarModule } from '../bloom/builder/builder-toolbar/builder-toolbar.module';
import { PanelModule } from '../bloom/page/panel/panel.module';
import { BuilderMenuToolsModule } from '../bloom/builder/builder-menu-tools/builder-menu-tools.module';
import { WidgetConfigurationModule } from '../bloom/widget-configuration/widget-configuration.module';
import { FormAutomateComponent } from './form-automate/form-automate.component';
import { BoxPickerModule } from '../shared/box-picker/box-picker.module';
import { AppsPopupModule } from '../shared/apps-popup/apps.popup.module';
import { FormActionPopupComponent } from './form-action-popup/form-action-popup.component';
import { FormAttributeSelectionComponent } from './form-attribute-selection/form-attribute-selection.component';
import { AttributeSelectionModule } from '../shared/query-components/attribute-selection/attribute-selection.module';
// import { BuilderToolbarModule } from '../bloom/builder/builder-toolbar/builder-toolbar.module';
import {MatMenuModule} from '@angular/material/menu';
import { CreateFormDetailComponent } from './create-form-detail/create-form-detail.component';
import { FormAttributeSelectionModule } from './form-attribute-selection/form-attribute-selection.module';
import { CreateFormDetailModule } from './create-form-detail/create-form-detail.module';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { TemplateDataCollectionModule } from '../bloom/builder/publish-config/collect-template-data/template-data-collection.module';
import { FormTemplatePopupComponent } from './form-template-popup/form-template-popup.component';
import { ConnectionSelectionModule } from '../shared/connection-selection/connection-selection.module';
import {MatDividerModule} from '@angular/material/divider';
import { SectionPopupComponent } from './section-popup/section-popupcomponent';
import { FormThemesComponent } from './form-themes/form-themes.component';
import { DragnDropFileModule } from '../shared/dropfile/dragndropfile.module';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { TrimUrlPipe } from '../core/pipes/TrimUrl.pipe';
import {MatChipsModule} from '@angular/material/chips';
import {MatSliderModule} from '@angular/material/slider';
import {MatStepperModule} from '@angular/material/stepper';
import { SpecializedPanelsModule } from '../bloom/specialized-panels/specialized-panels.module';

@NgModule({
  declarations: [
    FormHomeComponent,
    FormBuilderComponent,
    // FormToolbarComponent,
    FormCanvasComponent,
    CreateFormPopupComponent,
    FormPageComponent,
    PublishDialogComponent,
    FormViewerCanvasComponent,
    ResponsesComponent,
    SuccessPageConfigComponent,
    FormSettingsComponent,
    NewFormComponent,
    FormAutomateComponent,
    FormActionPopupComponent,
    FormTemplatePopupComponent,
    SectionPopupComponent,
    FormThemesComponent,
    TrimUrlPipe
    // FormAttributeSelectionComponent,
    // CreateFormDetailComponent
  ],
  imports: [
    CommonModule,
    FormRoutingModule,
    workspaceModule,
    SpinnerModule,
    DisplayBannerModule,
    WidgetsModule,
    FieldPickerModule,
    MatCardModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatTabsModule,
    RouterModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatButtonModule,
    MatTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    DragDropModule,
    QoutaPopupModule,
    BuilderToolbarModule,
    ClipboardModule,
    ListPanelModule,
    PanelModule,
    BuilderMenuToolsModule,
    WidgetConfigurationModule,
    BoxPickerModule,
    AppsPopupModule,
    AttributeSelectionModule,
    MatMenuModule,
    FormAttributeSelectionModule,
    CreateFormDetailModule,
    MatCheckboxModule,
    TemplateDataCollectionModule,
    ConnectionSelectionModule,
    MatDividerModule,
    DragnDropFileModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatSliderModule,
    MatStepperModule,
    SpecializedPanelsModule
    // BuilderToolbarModule
    // CreateFormModule
  ],
  exports: [
    FormHomeComponent,
    // FormAttributeSelectionComponent,
    // CreateFormDetailComponent
  ]
})
export class FormModule { }
