
<div>

<div (click)="$event.stopPropagation()"  fxLayout="row" fxLayoutAlign="center center">

  <div>
    <div class="build_button_div"  style="margin: 10px;" (click)="onConnectionClicked()">
      <div class="build_button" fxLayout="row">
        <div fxLayout fxLayoutAlign="center center" fxLayout="column" fxFlex="20">
          <img class="image" [src]="baseURL + 'connection/appiworks-connection-logo.png'">
        </div>
        <div fxLayout="column" fxLayoutAlign="center start" fxFlex="80">
          <div style="color: #3f51b5; font-size:  large;; font-weight: bold;" fxLayout="row" fxLayoutAlign="start center"> Connection</div>
          <div fxLayout="row" style="color: #6f7385;font-size: 12px;" fxLayoutAlign="start center">
            Connect and integrate with all your applications</div>
      </div>
      </div>
    </div>

    <div class="build_button_div"  style="margin: 10px;"  (click)="onStarchClicked()">
      <div class="build_button" fxLayout="row">
        <div fxLayout fxLayoutAlign="center center" fxLayout="column" fxFlex="20">
          <img class="image" [src]="baseURL + 'starch/appiworks-starch-logo.png'">
        </div>
        <div fxLayout="column" fxLayoutAlign="center start" fxFlex="80">
          <div style="color: #3f51b5; font-size:  large;; font-weight: bold;" fxLayout="row"
          fxLayoutAlign="start center"> Starch</div>
          <div fxLayout="row" style="color: #6f7385;font-size: 12px;" fxLayoutAlign="start center">
            Make your datastore with a custom object model</div>
      </div>
      </div>
    </div>

    <div class="build_button_div"  style="margin: 10px;" (click)="onFormClicked()">
      <div class="build_button" fxLayout="row">
        <div fxLayout fxLayoutAlign="center center" fxLayout="column" fxFlex="20">
          <img class="image" [src]="baseURL + 'form-builder/aw-form-builder-logo.png'">
        </div>
        <div fxLayout="column" fxLayoutAlign="center start" fxFlex="80">
          <div style="color: #3f51b5; font-size:  large;; font-weight: bold;" fxLayout="row"
          fxLayoutAlign="start center"> Form</div>
          <div fxLayout="row" style="color: #6f7385;font-size: 12px;" fxLayoutAlign="start center">
            Powerful forms with integrations created and published in a snap</div>
      </div>
      </div>
    </div>

    <div class="build_button_div"  style="margin: 10px;" (click)="onBloomClicked()">
      <div class="build_button" fxLayout="row">
        <div fxLayout fxLayoutAlign="center center" fxLayout="column" fxFlex="20">
          <img class="image" [src]="baseURL + 'bloom/appiworks-bloom-logo.png'">
        </div>
        <div fxLayout="column" fxLayoutAlign="center start" fxFlex="80">
          <div style="color: #3f51b5; font-size:  large;; font-weight: bold;" fxLayout="row" fxLayoutAlign="start center"> Bloom</div>
          <div fxLayout="row" style="color: #6f7385;font-size: 12px;" fxLayoutAlign="start center">
            Build your custom solutions with visual design</div>
      </div>
      </div>
    </div>

    <div class="build_button_div"  style="margin: 10px;" (click)="onFlowClicked()">
      <div class="build_button" fxLayout="row">
        <div fxLayout fxLayoutAlign="center center" fxLayout="column" fxFlex="20">
          <img class="image" [src]="baseURL + 'flow/appiworks-flow-logo.png'">
        </div>
        <div fxLayout="column" fxLayoutAlign="center start" fxFlex="80">
          <div style="color: #3f51b5; font-size:  large;; font-weight: bold;" fxLayout="row"
          fxLayoutAlign="start center"> Flow</div>
          <div fxLayout="row" style="color: #6f7385;font-size: 12px;" fxLayoutAlign="start center">
            Automate and manage process workflows at ease</div>
      </div>
      </div>
    </div>

  </div>
</div>

</div>
