<div class="cover">
  <div class="innerCover" *ngIf="(ready && !runSpinner) || loadingData">
    <!------------------------------------------------------ TABLE VIEW ------------------------------------------------->
    <ng-container *ngIf="getCurrentViewType() === 'table' && tableData.length > 0">

      <div class="table-header" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap=".2rem">
        <ng-container *ngFor="let attrId of attributeIds; let i = index; trackBy: trackByFn">
          <div fxFlex="{{ columnData[i].colWidth }}" (mouseenter) ="panelMeta.listAttributes[i].sortIconVisible = true;" (mouseleave) ="panelMeta.listAttributes[i].sortIconVisible = false;">
            <div fxLayout="row wrap" fxLayoutAlign="space-between center" style="position: relative;">
              <app-label
                [widgetMeta]="columnData[i].headerWidget"
                [builderMode]="false"
              ></app-label>
            
              <div *ngIf="panelMeta.listAttributes[i]?.['widgetType'] === 'checkbox'" class="header-checkbox-container" [ngStyle]="{'left': (attributeIds.length > 6) ? '9px' : 0 }">
                <app-checkbox
                  [widgetMeta]="widgetMetaMap[i][attrId]"
                  [builderMode]="false"
                  (userInputReceived)="headerCheckboxChange($event);"
                >
                </app-checkbox>
              </div>
              <div  *ngIf="panelMeta.listAttributes[i] && panelMeta?.listAttributes[i]?.sortIconVisible  && panelMeta.listAttributes[i].sortEnabled">
                <mat-icon  style="cursor: pointer;font-size: 20px;" *ngIf="!panelMeta.listAttributes[i].order || panelMeta.listAttributes[i].order === 'ASC'" (click)="customOrder(panelMeta.listAttributes[i], 'DESC')">south</mat-icon>
                <mat-icon  style="cursor: pointer;font-size: 20px;" *ngIf="panelMeta.listAttributes[i].order && panelMeta.listAttributes[i].order === 'DESC'" (click)="customOrder(panelMeta.listAttributes[i], 'ASC')">north</mat-icon>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <div *ngIf="errorOccurred && (!panelMeta.dataSource || panelMeta.dataSource !== 'custom')" style="color: red; text-align: center; margin: 1rem 0;">
        {{ "Error: " + error }}
      </div>



      <div
        class="row-box tr"
        *ngFor="let rowData of tableData; let rowIndex = index; trackBy: trackByFn"
        fxFlexFill fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap=".2rem"
      >
        <ng-container *ngFor="let attrId of attributeIds; let attrIndex = index; trackBy: trackByFn">
          <span class="td" fxFlex="{{ columnData[attrIndex].colWidth }}">
            <ng-container>
              <div
                style="width: 100%"
                [ngSwitch]="rowData[attrId]['widgetType']"
                (click)="doOnClickAction(rowIndex, attrId)"
                [matTooltipDisabled]="!navMap[attrId] || !navMap[attrId].enabled || !navMap[attrId]['type']"
                [matTooltip]="navMap[attrId] && navMap[attrId].tooltip ? navMap[attrId].tooltip : (navMap[attrId] && navMap[attrId].enabled) ? 'Navigate to ' + (navMap[attrId]['pageCode']) : ''"
                matTooltipPosition="above"
                [class.cursorPointer]="navMap[attrId] && navMap[attrId].enabled"
              >
                <div *ngIf="!isObjectValue(rowData[attrId]['value']) && rowData[attrId]['widgetType'] === 'image'" class="ListimageWidgetWrap" [ngStyle]="rowData[attrId]['styles']">
                  <app-image [widgetMeta]="widgetMetaMap[rowIndex][attrId]" [builderMode]="false"></app-image>
                  <div class="resizeBar" *ngIf="builderMode" fxLayout fxLayoutAlign="center center" fxLayoutGap=".3rem">
                    <mat-icon (click)="resizeImage(attrId, '+')">zoom_in</mat-icon>
                    <mat-icon (click)="resizeImage(attrId, '-')">zoom_out</mat-icon>
                  </div>
                </div>

                <div *ngIf="!isObjectValue(rowData[attrId]['value']) && rowData[attrId]['widgetType'] === 'link'">
                  <div fxLayout="row" >
                    <app-link [widgetMeta]="widgetMetaMap[rowIndex][attrId]" [builderMode]="builderMode"></app-link>
                  <div fxLayout="column" fxLayoutAlign="center center">
                    <mat-icon matTooltip="Copy to clipboard" style="font-size: 14px;cursor: pointer;" *ngIf="rowData[attrId].isShowExpand" [cdkCopyToClipboard]="rowData[attrId]['value']">content_copy</mat-icon>
                  </div>

                  </div>
                </div>

                <div *ngIf="!isObjectValue(rowData[attrId]['value']) && rowData[attrId]['widgetType'] === 'icon'">
                  <app-icon
                    [widgetMeta]="widgetMetaMap[rowIndex][attrId]"
                    [widgetOptions]="widgetOptions"
                    [builderMode]="builderMode"
                    (newWidgetMeta)="updateWidgetPrototype($event, attrId)"
                  >
                  </app-icon>
                </div>
                <div   *ngIf="!isObjectValue(rowData[attrId]['value']) && rowData[attrId]['widgetType'] === 'datetime'">

                  <div fxLayout="row" style="overflow-x: hidden; width: 100%;">
                    <app-datetime
                      [widgetMeta]="widgetMetaMap[rowIndex][attrId]"
                      [builderMode]="false"
                      [widgetOptions]="widgetOptions"
                      (newWidgetMeta)="updateWidgetPrototype($event, attrId)"
                    ></app-datetime>

                  </div>
                </div>
                <div  *ngIf="!isObjectValue(rowData[attrId]['value']) && rowData[attrId]['widgetType'] === 'label'"
                  style="position: relative; width: 100%"
                  (mouseenter)="rowData[attrId].isShowExpand = true; setFullText($event, rowData[attrId])"
                  (mouseleave)="rowData[attrId].isShowExpand = false"
                >
                  <div fxLayout="row" #labelWrap style="width: 100%; overflow-x: hidden; box-sizing: border-box;" [matTooltip]="rowData[attrId].isShowExpand ? mouseOverFullText : ''">
                    <app-label
                      [widgetMeta]="widgetMetaMap[rowIndex][attrId]"
                      [builderMode]="false"
                      [widgetOptions]="widgetOptions"
                      (newWidgetMeta)="updateWidgetPrototype($event, attrId)"
                    ></app-label>
                  </div>

                  <div
                    fxLayout="row" fxLayoutAlign="center center"
                    class="copyIconWrapper"
                    *ngIf="rowData[attrId].isShowExpand"
                  >
                    <mat-icon matTooltip="Copy to clipboard" style="font-size: 14px; cursor: pointer" [cdkCopyToClipboard]="rowData[attrId]['value']">content_copy</mat-icon>
                  </div>
                </div>

                <div *ngIf="!isObjectValue(rowData[attrId]['value']) && rowData[attrId]['widgetType'] === 'tags'">
                  <app-tags
                  [widgetMeta]="widgetMetaMap[rowIndex][attrId]"
                  [builderMode]="builderMode"
                  [widgetOptions]="widgetOptions"
                  (newWidgetMeta)="updateWidgetPrototype($event, attrId)">
                  </app-tags>
                </div>

                <div *ngIf="!isObjectValue(rowData[attrId]['value']) && rowData[attrId]['widgetType'] === 'button'">
                  <app-button
                  [widgetMeta]="widgetMetaMap[rowIndex][attrId]"
                  [builderMode]="builderMode"
                  [widgetOptions]="widgetOptions"
                  (newWidgetMeta)="updateWidgetPrototype($event, attrId)">
                  </app-button>
                </div>

                <div *ngIf="rowData[attrId]['widgetType'] === 'checkbox'">
                  <app-checkbox
                    [widgetMeta]="widgetMetaMap[rowIndex][attrId]"
                    [builderMode]="false"
                    (userInputReceived)="rowCheckboxSelected($event, rowIndex);emitCheckboxChange()"
                  >
                  </app-checkbox>
                </div>

                <div
                  style="cursor: pointer;"
                  (mouseenter)="rowData[attrId].isShowExpand = true"
                  (mouseleave)="rowData[attrId].isShowExpand = false"
                  matTooltip="{{ getNumberOfFields(rowData[attrId]['value']).tooltext }}"
                  (click)="openListPanel(rowData[attrId]['value'], attrId)"
                >
                  {{ getNumberOfFields(rowData[attrId]['value']).text }}
                </div>
              </div>
            </ng-container>
          </span>

        </ng-container>
      </div>
    </ng-container>


    <!---------------------------------------------------- CARD VIEW ---------------------------------------------------->

    <ng-container *ngIf="getCurrentViewType() === 'card'" >

      <div *ngIf="errorOccurred && (!panelMeta.dataSource || panelMeta.dataSource !== 'custom')" style="color: red; text-align: center; margin: 1rem 0;">
        {{ "Error: " + error }}
      </div>

      <div class="dataCardWrap" fxLayout="row wrap" fxLayoutGap=".3rem" fxFlexFill fxLayoutAlign="{{panelMeta.alignment || 'flex-start'}} center">
        <ng-container *ngFor="let rowData of tableData;let rowIndex = index; trackBy: trackByFn">
          <div
            class="dataCard"
            [class.cursorPointer]="panelMeta.navigationSettings && panelMeta.navigationSettings.enabled"
            [matTooltip]="panelMeta.navigationSettings?.tooltip || ''"
            #dataCard
            fxLayout="column" fxLayoutAlign="start start"
            [fxFlex]="getCardWidth()"
            (click)="cardClicked($event, rowIndex)"
          >
            <!-- fxFlex.xs="75" fxFlex.gt-xs="40" fxFlex.gt-sm="30" fxFlex.gt-md="25" fxFlex.gt-lg="18" -->
            <ng-container *ngFor="let attrId of attributeIds; let attrIndex = index; trackBy: trackByFn">

              <div class="td full-width" *ngIf="getWidgetType(attrId) !== 'icon'">
                <div
                  [ngSwitch]="getWidgetType(attrId)"
                  class="full-width widgetsCover"
                  (click)="doOnClickAction(rowIndex, attrId)"
                  [matTooltipDisabled]="!navMap[attrId] || !navMap[attrId].enabled || !navMap[attrId]['type']"
                  [matTooltip]="navMap[attrId] && navMap[attrId].tooltip ? navMap[attrId].tooltip : (navMap[attrId] && navMap[attrId].enabled) ? 'Navigate to ' + (navMap[attrId]['pageCode']) : ''"
                  [ngStyle]="{'cursor': navMap[attrId] && navMap[attrId].enabled ? 'pointer' : 'inherit' }"
                >
                  <!-- <div *ngSwitchCase="'image'"
                    fxLayoutAlign="center center"
                    class="imageWidgetWrap full-width"
                    #imageWidgetWrap
                    [ngStyle]="rowData[attrId]['styles']"
                  >
                    <app-image
                      *ngIf="!builderMode"
                      [widgetMeta]="widgetMetaMap[rowIndex][attrId]"
                      [builderMode]="builderMode"
                      [cardImage]="true"
                    >
                    </app-image>
                    <app-image
                      *ngIf="builderMode"
                      [widgetMeta]="widgetMetaMap[rowIndex][attrId]"
                      [builderMode]="builderMode"
                      [cardImage]="true"
                      (newWidgetMeta)="updateWidgetPrototype($event, attrId)"
                      (raiseContextMenuActions)="raiseContextMenuActions($event, rowIndex, attrId)"
                      #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="contextmenu" (menuOpened)="menuOpened(widgetMetaMap[rowIndex][attrId])"
                    >
                    </app-image>
                  </div>
                  <div *ngSwitchCase="'link'">
                    <app-link [widgetMeta]="widgetMetaMap[rowIndex][attrId]"
                      [builderMode]="false">
                    </app-link>
                  </div>
                  <div *ngSwitchCase="'label'"
                    style="width: 100%;"
                    (mouseenter)="rowData[attrId].isShowExpand = true; setFullText($event, rowData[attrId])"
                    (mouseleave)="rowData[attrId].isShowExpand = false"
                  >
                    <div fxLayout="row" #labelWrap style="width: 100%; overflow-x: hidden; box-sizing: border-box;" [matTooltip]="rowData[attrId].isShowExpand ? mouseOverFullText : ''">
                      <app-label
                        style="width: 100%;"
                        *ngIf="!builderMode"
                        [widgetMeta]="widgetMetaMap[rowIndex][attrId]"
                        [builderMode]="false"
                        [widgetOptions]="widgetOptions"
                        (newWidgetMeta)="updateWidgetPrototype($event, attrId)"
                      ></app-label>
                      <app-label
                        style="width: 100%;"
                        *ngIf="builderMode"
                        [widgetMeta]="widgetMetaMap[rowIndex][attrId]"
                        [builderMode]="false"
                        [widgetOptions]="widgetOptions"
                        (newWidgetMeta)="updateWidgetPrototype($event, attrId)"

                        (raiseContextMenuActions)="raiseContextMenuActions($event, rowIndex, attrId)"
                        #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="contextmenu" (menuOpened)="menuOpened(widgetMetaMap[rowIndex][attrId])"
                      ></app-label>
                    </div>
                  </div>
                  <div *ngSwitchCase="'datetime'">

                      <app-datetime
                      [widgetMeta]="widgetMetaMap[rowIndex][attrId]"
                      [builderMode]="false"
                      [widgetOptions]="widgetOptions"
                      (newWidgetMeta)="updateWidgetPrototype($event, attrId)"
                    ></app-datetime>
                  </div>
                  <div *ngSwitchCase="'tags'">
                    <app-tags
                    [widgetMeta]="widgetMetaMap[rowIndex][attrId]"
                    [builderMode]="builderMode"
                    [widgetOptions]="widgetOptions"
                    (newWidgetMeta)="updateWidgetPrototype($event, attrId)">
                    </app-tags>
                  </div>
                  <mat-menu
                    #contextmenu="matMenu" id="mymenu" class="myMenu" [hasBackdrop]="false"
                    [xPosition]="'after'" [yPosition]="'above'"
                    [overlapTrigger]="false" (click)="$event.stopPropagation()"
                  >
                    <app-context-menu #contextMenuComponent *ngIf="widgetContextMenuActions[rowIndex] && widgetContextMenuActions[rowIndex][attrId]" [actionConfig]="widgetContextMenuActions[rowIndex] ? widgetContextMenuActions[rowIndex][attrId] : { actions: [] }" (contextChanged)="contextChanged($event)"
                      [widgetMeta]="panelMeta?.listWidgetSet[attrId]" [panelId]="panelMeta.id">
                    </app-context-menu>
                  </mat-menu> -->

                  <app-widget-builder
                    [widgetMeta]="widgetMetaMap[rowIndex][attrId]"
                    [panelMeta]="panelMeta"
                    [pageMeta]="pageMeta"
                    [builderMode]="builderMode"
                    [contextMenuBackdrop]="true"
                    [widgetOptions]="widgetOptions"
                    (newWidgetMeta)="updateWidgetPrototype($event, attrId)"
                    style="width: 100%; height: 100%;"
                  >
                  </app-widget-builder>
                  <!-- (applyImageHeight)="applyImageHeight($event, widget, wid)" -->
                </div>
              </div>
            </ng-container>
            
            <div class="td full-width icon-wrap" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap=".7rem">
              <ng-container *ngFor="let attrId of attributeIds; let attrIndex = index; trackBy: trackByFn">
                <div class="td" *ngIf="getWidgetType(attrId) === 'icon'">
                  <div
                    class="widgetsCover"
                    (click)="doOnClickAction(rowIndex, attrId)"
                    [matTooltipDisabled]="!navMap[attrId] || !navMap[attrId].enabled || !navMap[attrId]['type']"
                    [matTooltip]="navMap[attrId] && navMap[attrId].tooltip ? navMap[attrId].tooltip : (navMap[attrId] && navMap[attrId].enabled) ? 'Navigate to ' + (navMap[attrId]['pageCode']) : ''"
                    [ngStyle]="{'cursor': navMap[attrId] && navMap[attrId].enabled ? 'pointer' : 'inherit' }"
                  >
                    <app-icon
                      [widgetMeta]="widgetMetaMap[rowIndex][attrId]"
                      [widgetOptions]="widgetOptions"
                      [builderMode]="builderMode"
                      (newWidgetMeta)="updateWidgetPrototype($event, attrId)"
                    >
                    </app-icon>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>

      </div>
    </ng-container>




    <!------------------------------------------------------ BOARD VIEW ------------------------------------------------------>

    <ng-container *ngIf="getCurrentViewType() === 'board'">

      <div *ngIf="errorOccurred  && (!panelMeta.dataSource || panelMeta.dataSource !== 'custom')" style="color: red; text-align: center; margin: 1rem 0;">
        {{ "Error: " + error }}
      </div>

      <div class="pillarWrap" id="pillar-wrap" cdkDropListGroup fxFlexFill fxLayout="row" fxLayoutAlign="flex-start center"
        fxLayoutGap=".5rem">
        <ng-container *ngFor="let groupValue of widgetMetaMap.groupValues; trackBy: trackByFn">
          <div
            class="pillar" [id]="groupValue"
            cdkDropList [cdkDropListData]="widgetMetaMap[groupValue]" (cdkDropListDropped)="drop($event)"
          >
            <div class="pillarTitle">{{ groupValue }}</div>

            <div class="boardCardWrap">
              <ng-container *ngFor="let rowData of widgetMetaMap[groupValue]; let rowIndex = index; trackBy: trackByFn">
                <div cdkDrag [cdkDragData]="rowData" class="boardCard" fxLayout="column" fxLayoutAlign="start start">
                  <ng-container *ngFor="let attrId of attributeIds; let attrIndex = index; trackBy: trackByFn">
                    <div class="td fieldInCard">
                      <div
                        [ngSwitch]="rowData[attrId]['type']"
                        (click)="doOnClickAction(rowIndex, attrId, groupValue)"
                        [matTooltipDisabled]="!navMap[attrId] || !navMap[attrId].enabled || !navMap[attrId]['type']"
                        [matTooltip]="navMap[attrId] && navMap[attrId].tooltip ? navMap[attrId].tooltip : (navMap[attrId] && navMap[attrId].enabled) ? 'Navigate to ' + (navMap[attrId]['pageCode']) : ''"
                        matTooltipPosition="above"
                        [class.cursorPointer]="navMap[attrId] && navMap[attrId].enabled"
                      >
                        <div *ngSwitchCase="'image'" style="margin: auto;" fxLayoutAlign="center center">
                          <app-image [widgetMeta]="widgetMetaMap[groupValue][rowIndex][attrId]"
                          class="imageWidgetWrap" [ngStyle]="rowData[attrId]['styles']"
                          [builderMode]="false">
                          </app-image>
                          <div class="resizeBar" *ngIf="builderMode" fxLayout fxLayoutAlign="center center" fxLayoutGap=".3rem">
                            <mat-icon (click)="resizeImage(attrId, '+')">zoom_in</mat-icon>
                            <mat-icon (click)="resizeImage(attrId, '-')">zoom_out</mat-icon>
                          </div>
                        </div>
                        <app-link *ngSwitchCase="'link'" [widgetMeta]="widgetMetaMap[groupValue][rowIndex][attrId]"
                          [builderMode]="false">
                        </app-link>
                        <app-icon
                          *ngSwitchCase="'icon'"
                          [widgetMeta]="widgetMetaMap[groupValue][rowIndex][attrId]"
                          [widgetOptions]="widgetOptions"
                          [builderMode]="false"
                        >
                        </app-icon>
                        <app-label
                          *ngSwitchDefault
                          [widgetMeta]="widgetMetaMap[groupValue][rowIndex][attrId]"
                          [widgetOptions]="widgetOptions"
                          [builderMode]="false"
                        ></app-label>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <div fxLayoutAlign="center center" *ngIf="!tableData || !tableData.length">
      <div  style="color:brown"> {{panelMeta.noDataMessage}} </div>
    </div>

    <br>
  </div>
  <div *ngIf="(runSpinner && !loadingData) || loadingData"  class="spinnerWrap" style="width: 100%;">
    <!-- <spinner [type]="'skeleton'" style="width: 100%;" [selfSpin]="true" [skeletonSpinnerGridMap]="skeletonSpinnerGridMap"></spinner> -->
    <spinner [type]="'skeleton'" style="width: 100%;" [selfSpin]="true" [skeletonPresetCode]="'list-table-view-loader'"></spinner>
  </div>
</div>
