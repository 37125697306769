<!-- min-width: 15rem; max-height: 25rem;  -->
<div class="attribute-selection-cover" style="padding: 0 .5rem .5rem .5rem;" fxLayout="column" fxLayoutAlign="space-between center">
  <div *ngIf="boxObjectAttributes?.length > 10" style="width: 100%; padding-top: 0;" fxLayoutAlign="center center">
    <mat-form-field appearance="outline" style="width: 100%; margin-bottom: -1.25em;" (click)="$event.stopPropagation()">
      <input type="text" matInput #filterInput placeholder="Search for {{ attributeDisplayName ? attributeDisplayName + 's' : 'attributes'}}" (input)="filterAttributeList($event)">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>

  <div
    [ngStyle]="{'overflow-y': boxObjectAttributes?.length > 10 ? 'scroll' : 'auto' }"
    style="width: 100%; margin: 0 0 .5rem 0; height: 15rem; max-width: 15rem; overflow-x: auto"
    cdkDropList (cdkDropListDropped)="drop($event)"
  >
    <div
      *ngFor="let attr of filteredAttributes; let i = index"
      cdkDrag [cdkDragDisabled]="isSearching || attr.__id === '__checkbox__'"
      style="width: 100%;"
      fxLayoutAlign="space-between center"
      class="attrItem"
    >
      <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
      <div *ngIf="allowReorder" fxFlex="10" class="handle-wrap" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon class="handle" fxLayoutAlign="center center" cdkDragHandle>drag_indicator</mat-icon>
      </div>
      <mat-checkbox
        fxFlex="90" style="padding: 0 5px;" color="primary"
        (click)="$event.stopPropagation()"
        [checked]="attr['isColumnSelected']"
        (change)="attributeSelected($event, attr)"
        [disabled]="attr.__id === '__checkbox__'"
      >
        {{attr.name || attr.__id}}
      </mat-checkbox>
    </div>
  </div>

  <ng-container *ngIf="this.supportApplyButton">
    <div style="width: 100%;">
      <button mat-stroked-button color="primary" class="actionButton" (click)="filteredAttributes = boxObjectAttributes"> Cancel </button>
      <button mat-raised-button color="primary" (click)=" columnSelectionChanged()"> Apply </button>
    </div>
  </ng-container>
</div>
