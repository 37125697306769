import { Component, Inject, NgZone, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FlowService } from "../flow.service";
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { SmsService } from 'src/app/shared/services/sms-service.service';
import { MatDialog } from '@angular/material/dialog';
import { QoutaPopupComponent } from 'src/app/shared/qouta-popup/qouta-popup.component';
import { formatDate, isPlatformBrowser } from '@angular/common';
import { FlowCollaborationComponent } from '../flow-collaboration/flow-collaboration.component';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { DeleteDialogComponent } from 'src/app/shared/delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-flow-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss']
})
export class ListingComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;

  flows: any = [];
  sharedFlows: any[] = []
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10, 20, 30,35,40]; // Choose the page size options here
  pageSize = this.pageSizeOptions[0];
  totalFlows = 0;
  menu: any;
  maxVisibleLogos: number = 4;
  showAllLogos: boolean = false;
  showMoreLogos: boolean = false;
  error = false;
  authError: boolean = false;
  spinner: boolean = false
  isBrowser: boolean;

  constructor(
    public router: Router,
    public fs: FlowService,
    public authService: AuthServiceService,
    public resourcePermissionService: ResourcePermissionService,
    // private spinner: SpinnerService,
    private _snackBar: MatSnackBar,
    private smsService: SmsService,
    private dialog: MatDialog,
    private themeService: ThemeService,
    private ngZone: NgZone,
    private connectionService: ConnectionService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
   }


  formatDate(date: any): string {
    date = date?.replace("|date", "");
    const selectedDateFormat = this.themeService.currentLocale.dateFormat;
    return formatDate(date, selectedDateFormat, 'en');
  }

  async ngOnInit() {
    if(!this.isBrowser) return;
    if (
      !this.connectionService.selectedWorkSpace &&
      !this.authService.loggedIn
    ) {
      this.authService.authCheck();
      this.authService.authCheckPositive.subscribe(async (authStatus) => {
        //if logged in
        if (authStatus) {
          this.spinner = true;
          console.log('logged in');
          this.themeService.loadTheme();
          this.themeService
            .getExistingTheme(this.authService.profile._id)
            .then((res: any) => {
              if (res?.data != null) {
                console.log(res);
                this.themeService.settings_Id = res?.data[0]._id;
                let theme = res.data[0].themeSetting.theme;
                this.themeService.setTheme(theme);
                this.themeService.currentLocale = res.data[0].localeSetting;
                this.themeService.textDirection =
                  res.data[0].themeSetting.direction;
                if (res.data[0].themeSetting.direction == 'rtl') {
                  this.themeService.enableDirMode('rtl');
                }
              }
            });
            console.log("[CALLING] getWorkspaceId from flow listing oninit")
            await this.connectionService.getWorkSpaceId();
            this.getFlows();
            this.getSharedFlows()
        } else {
          this.error = true;
          this.authError = true;
          this.spinner = false;
          return;
        }
      });
    } else if (this.connectionService.selectedWorkSpace) {
      this.spinner = true
      console.log("workspace already selected")
      await this.getFlows();
      this.getSharedFlows()
      this.spinner = false
    } else {
      this.spinner = true
      console.log("authService.loggedIn", this.authService.loggedIn)
      console.log("connectionService.selectedWorkSpace", this.connectionService.selectedWorkSpace)
      let res = await this.connectionService.getWorkSpaceId()
      console.log("workspace set", res)
      await this.getFlows();
      this.getSharedFlows()
      this.spinner = false
    }
  }

  navigateToSignUp(){
    this.router.navigate(['sign-up']);
  }

  toggleLogos() {
    this.showMoreLogos = !this.showMoreLogos;
  }
  async getFlows() {
    this.spinner = true
    const pageIndex = this.paginator ? this.paginator.pageIndex : 0;
    const pageSize = this.paginator ? this.paginator.pageSize : this.pageSize;
    this.flows = await this.fs.getAllFlows(pageIndex + 1, pageSize, true);
    this.totalFlows = await this.fs.totalFlowCount;
    console.log("list flow", this.flows);
    // if (this.flows.length == 0) this.router.navigate(["flow", "new"]);
    // this.spinner = false;
    this.spinner = false
  }

  async getSharedFlows(){
    this.sharedFlows = await this.resourcePermissionService.getSharedResources(
      this.authService.profile._id,
      'flow'
    );
    console.log("shared flows : ",this.sharedFlows)
  }

  async refreshFlow() {
    this.spinner = true;
    try {
      await this.getFlows();
      await this.getSharedFlows();
    } catch (e) {
      console.log(e)
    }
    console.log("list of flows", this.flows);
    this.spinner = false;
  }

  // activeChanged(event, i){
  //   console.log("enabledChanged", event,"for flow", this.flows[i])
  //   this.flows[i].isActive = event.checked
  //   // this.updateFlow(this.flows[i])

  //   // 1. change active status in the published flow associated with this flow
  //   // 2. if not published, open publish dialog
  //   // 3.
  // }

  async updateFlow(flowMap) {

    let result: any;
    try {
      this.spinner = true;
      flowMap.created_at = flowMap.created_at + "|date";
      flowMap.modified_at = new Date().toISOString() + "|date";
      result = await this.fs.updateFlow(flowMap);
      console.log("result", result)
      this.spinner = false;
    } catch (er) {
      console.log("er: ", er);
      this.spinner = false;
    }
  }

  async updateActiveState(flowMap: any){
    let publishedFlow;
    this.spinner = true
    try{
      publishedFlow = await this.fs.getFlow(flowMap.published_flow_id)
      console.log("published flow fetched", publishedFlow)

      publishedFlow['isActive'] = flowMap.isActive

      let res = await this.updateFlow(publishedFlow)
      console.log("published flow updated")
      res = await this.updateFlow(flowMap)
      console.log("draft flow updated")
      this.spinner = false
    }catch(e){
      console.log("published flow could not be found", e)
    }
  }

  editFlow(id: any) {
    this.router.navigate(["flow", "edit", id]);
  }

  async deleteFlow(flow: any) {
    let data = await this.openDeleteDialog(flow);
    if(data){
      let deletionIndex = this.flows.findIndex(f => f._id == flow._id)
        console.log("flow to delete", flow, "index", deletionIndex)
        if(deletionIndex > -1) this.flows.splice(deletionIndex, 1)
        console.log("flow list after deletion", this.flows)
    }
  }

  openDeleteDialog(flow: any) {
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(DeleteDialogComponent, {
        width: '400px',
        data: {
          dataObj: flow,
          resource: "Flow",
          resourceName: flow.name
        },
      });

      dialogRef.afterClosed().subscribe((deleteConfirmation) => {
        console.log('deletion deleteConfirmation', deleteConfirmation);
        if (deleteConfirmation) {
          resolve(true);
        } else {
          console.log('flow deletion cancelled');
        }
      });
    });
  }

  createNewFlow() {
    let eligibility = this.smsService.checkEligibility("FLOW");
    console.log("isEligible", eligibility)
    if(!eligibility.isEligible){
      let dialogRef = this.dialog.open(QoutaPopupComponent, {
            width: "500px",
            data: {
              type: "FLOW"
            },
          });
      return ;
    }
    this.router.navigate(["flow", "new"]);
  }

  async cloneFlow(flow, index) {
    this.spinner = true
    console.log(flow)
    let user = await this.authService.getWorkspaceUser()
    let flowPayload = JSON.parse(JSON.stringify(flow))
    flowPayload.workspace_id = user?.last_workspace;
    flowPayload.modified_at = new Date().toISOString() + "|date";
    flowPayload.created_at = new Date().toISOString() + "|date";
    flowPayload.name = JSON.parse(JSON.stringify("Copy of " + flow.name))
    delete flowPayload._id;
    if (flowPayload.trigger_type == "webhook") {
      delete flowPayload.trigger?.webhook_url
    }
    let result;
    try {
      result = await this.fs.createFlow(flowPayload);
    } catch (e) {
      console.log(e)
    }
    console.log(result)
    if (result) {
      let clonedFlow;
      try {
        clonedFlow = await this.fs.getFlow(result);
      } catch (e) {
        console.log(e)
      }
      if (flowPayload.trigger_type == "webhook") {
        let webhook_url = `${environment.SERVER_BASE_URL}/flow/webhook/${result}`
        console.log("webhook url", webhook_url)
        clonedFlow.trigger.webhook_url = webhook_url;
        let updatedFlow = await this.updateFlow(clonedFlow)
        console.log(updatedFlow)
      }
      this.flows.splice(index + 1, 0, clonedFlow)
    }
    this.spinner = false
    this._snackBar.open('Flow ' + flow.name + ' is successfully cloned', "", { duration: 3000 })
  }

  async shareFlowData(flow){
    console.log("Flow to be shared : ",flow);
    let dialogRef = this.dialog.open(FlowCollaborationComponent, {
      width: '90vw',
      height: '90vh',
      panelClass: "collabdiolog",
      data: {
        resource: flow,
        resourceType: 'flow'
      },
    });
    var diologResult = await dialogRef.afterClosed().toPromise();
  }

  async pageChanged(event: any) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    await this.getFlows();
    await this.getSharedFlows()
  }
}
