import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SetupComponent } from './builder/setup/setup.component';
import { FlowInstanceComponent } from './flow-instance/flow-instance.component';
import { ListingComponent } from './listing/listing.component';
import { CreationComponent } from './builder/creation/creation.component';

const routes: Routes = [
  {
    path: '',
    component: ListingComponent,
    data: { title: 'Flows - AppiWorks', description: 'Explore your flows' }
  },
  {
    path: 'new',
    component: CreationComponent,
  },
  {
    path: 'create',
    component: SetupComponent,
  },
  {
    path: 'edit/:id',
    component: SetupComponent,
  },
  {
    path: 'instance/:id',
    component: FlowInstanceComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FlowRoutingModule { }
