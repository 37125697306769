import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { MatDialog } from '@angular/material/dialog';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { BoxService } from '../../services/box.service';
import { WidgetRegistry } from 'src/app/bloom/models/WidgetRegistry';

@Component({
  selector: 'attribute-parameter-inputs',
  templateUrl: './attribute-parameter-inputs.component.html',
  styleUrls: ['./attribute-parameter-inputs.component.scss']
})
export class AttributeInputsComponent implements OnInit {
 

  constructor(
    public con: ConnectionService,
    private dialog: MatDialog,
    public boxService: BoxService,
    private spin: SpinnerService
  ) { }

  inputMap: any = [];
  inputAttributes: any[] = [];
  columnHeader: any = []

  @Input() attribute: any;
  @Input() existingInputs:any;
  @Output() "inputParameter" = new EventEmitter<any>();
  // @Output() "enableExecute" = new EventEmitter<any>();

  async ngOnInit() {
    this.addAttribute();
    this.constructHeader();
    this.spin.hide();
  }

  removeAttribute(i: number){
    this.inputAttributes.splice(i, 1);
    this.inputMap.splice(i, 1);
  }

  async addAttribute(){
    let array = await this.constructParams();
    let obj = {};
    array.forEach(element => {
      obj[element.key] = "";
    });
    this.inputMap.push(obj);
    
    this.inputAttributes.push(array)// = this.inputAttributes.concat(array);
  }

  constructHeader(){
    let list = this.attribute.array.list || [];
    for (var i = 0; i < list.length; i++) {
      let listAttMap = this.attribute.array[list[i]];
      this.columnHeader.push(listAttMap?.name || "Input")
    }
  }


  async constructParams(){
   
    let list = this.attribute.array.list || [];
    let array= []
    for (var i = 0; i < list.length; i++) {
      let paramMap:any = {};
      let widget
      let listAttMap = this.attribute.array[list[i]];
      paramMap.key = list[i];
      paramMap.name = listAttMap?.name || "Input";
      widget = JSON.parse(JSON.stringify(WidgetRegistry.getWidget('input')));
      widget.config.placeholder.value = listAttMap?.name || "Input";
      widget.config.nofloat.value = true;
      if(i == 0) {
        widget.config.focus = {};
        widget.config.focus.value = true;
      }
        
      paramMap.widget = widget;
      
      array.push(paramMap)
    }
    return array;
    
  }

  attributeInputReceived(event, param, i){
    this.inputMap[i][param.key] = typeof event == 'object' ?  event.value : event;
    this.inputParameter.emit({[this.attribute.__id] : this.inputMap})
  }

}
