import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { FlowService } from '../flow.service';

@Component({
  selector: 'app-flow-instance',
  templateUrl: './flow-instance.component.html',
  styleUrls: ['./flow-instance.component.scss']
})
export class FlowInstanceComponent implements OnInit {
  @Input() flowId: string;
  @Input() flowTitle: string;
  selectedFlowId: any;
  flowInstances: any = [];
  triggerDateFrom = new Date(new Date().setDate(new Date().getDate() - 7));
  triggerDateTo = new Date();
  navigation: any;
  builderMode: boolean = false;
  flowSummaryPanelMeta = {
      listPanelTitle: "Flow Summary",
      viewTypes: {
        defaultView: "table"
      }
  }
  flowSummary: any = []
  showSummary:boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fs: FlowService,
    private spinner: SpinnerService
  ) { 
    this.navigation = this.router.getCurrentNavigation();
  }

  ngOnInit(): void {
    this.route.params.subscribe(async params => {
      if(params['id']) {
        this.spinner.show();
        this.selectedFlowId = params['id'];
        // let options = {
        //   filter: `triggered_at>${this.triggerDateFrom.toISOString()}|date,triggered_at<${this.triggerDateTo.toISOString()}|date`
        // }
        // this.flowInstances = await this.fs.getInstances(this.selectedFlowId, options);
        
        // console.log("flowSummary", this.flowSummary)
        // console.log("this.flowInstances", this.flowInstances);
        this.getInstances();
        this.spinner.hide();
      }
    })
    if (this.flowId) {
      this.selectedFlowId = this.flowId;
      this.getInstances();
    }
  }

  async loadSummary(){
    this.spinner.show();
    if(this.showSummary){
      this.flowSummary = await this.fs.getSummary(this.selectedFlowId)
    }
    this.spinner.hide();
  }

  async getInstances(){
    console.log("triggerDateFrom", this.triggerDateFrom)
    console.log("triggerDateTo", this.triggerDateTo)
    
    this.spinner.show();
    let options = {
      filter: `triggered_at>${this.triggerDateFrom.toISOString()}|date,triggered_at<${this.triggerDateTo.toISOString()}|date`
    }
    this.flowInstances = await this.fs.getInstances(this.selectedFlowId, options);
    console.log("flowInstances ", this.flowInstances);
    this.spinner.hide();
  }

}
