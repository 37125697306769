import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service'
import { TokenUtil } from 'src/app/core/services/TokenUtil.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BoxInstance } from 'src/app/core/boxInstances/BoxInstance';
import { HttpCacheService } from 'src/app/core/services/HttpCacheService';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class BoxService {

  constructor(
    private token: TokenUtil,
    private http: HttpClient,
    public connectionService: ConnectionService,
    public authService: AuthServiceService,
    private _snackBar: MatSnackBar,
    private httpCacheService: HttpCacheService
  ) { }


  async getAttributes(boxId: string, action: string, connectionId?: string, boxToken?: any, attributeInputMap?: any, boxObject?: any) {
    var object: any
    if(action.split("/").length > 1) {
      object = action.split("/")[0]
    } else if(boxObject){
      object = boxObject
    }
    var boxUrl = `/${boxId}/getattributes`; //${object}/
    // let boxToken = undefined;
    if (!boxToken && connectionId) boxToken = await this.connectionService.getBoxConfigToken(connectionId)

    var payload = {
      parameters: {
        "object": object,
        "options": attributeInputMap
      }
    }
    console.log("[BOX-HTTP] get boxUrl", boxUrl);
    console.log("[BOX-HTTP] get boxToken", boxToken);
    console.log("[BOX-HTTP] get payload", payload);

    try {
      let response: any = await this.execute(boxUrl, payload, "post", boxToken);
      console.log("[BOX-HTTP] get getattributes response:", response);
      let result = response?.result || null;
      console.log("[BOX-HTTP] getattributes:", result);
      return result;
    } catch (e) {
      console.error("[BOX-HTTP] Error on get attributes:", JSON.stringify(e))
      throw e;
    }
  }

  async getAction(boxId: string, actionId: string, key?: any, config?: any, token?: any, contype?: any) {
    var boxUrl = `/${boxId}/`;
    let boxToken =  undefined;
    console.log("contype", contype)
    if(contype && contype == 'token'){
      boxToken = key;
    } else {
      if (key) boxToken = await this.connectionService.getBoxConfigToken(key)
    }

    // if (connectionId) boxToken = await this.connectionService.getBoxConfigToken(connectionId)

    let isSaveFunction = false;
    try {
      let response: any;
      if (actionId.includes("/")) {
        var object = actionId.split("/")[0];
        var fun = actionId.split("/")[1];

        if(fun == 'save') {
          isSaveFunction = true;
          fun = 'update';
        }
        boxUrl = boxUrl + object + "/function/" + fun;
        response = await this.execute(boxUrl, null, "get", boxToken);
      } else if (config?.box_object_id) {
        boxUrl = boxUrl + config.box_object_id + "/function/" + actionId;
        response = await this.execute(boxUrl, null, "get", boxToken)
      } else {
        boxUrl = boxUrl + "function/" + actionId;
        response = await this.execute(boxUrl, null, "get", boxToken);
      }
      console.log("[BOX-HTTP] get action response:", response);
      let action = response?.result || null;
      action.__id = actionId;
      if (actionId.includes("/")) {
        if(isSaveFunction) action.name = "Save"
        action.name = action.name + " " + object.charAt(0).toUpperCase() + object.substr(1).toLowerCase();
      }
      console.log("[BOX-HTTP] action:", action);
      return action;
    } catch (e) {
      console.error("[BOX-HTTP] Error on get action:", JSON.stringify(e))
      throw e;
    }
  }


  async getAllActions(boxId: string, key?: any, options?: any, contype?: any) {
    if(!boxId) throw new Error("box id not provided for fetching functions")
    var boxUrl = `/${boxId}/all/functions`;
    let boxToken = undefined;
    if(contype && contype == 'token'){
      boxToken = key;
    } else {
      if (key) boxToken = await this.connectionService.getBoxConfigToken(key)
    }

    console.log("boxToken", boxToken)

    try {
      let response: any = await this.execute(boxUrl, null, "get", boxToken);
      console.log("[BOX-HTTP] get actions response:", response);
      let actions = response?.functions || null;

      if(options && options.box_object){
        let objectActions = [];
        for (var i = 0; i < actions.length; i++) {
          var action = actions[i];
          if(action.__id.split("/").length == 2){
            if(action.__id.split("/")[0] == options.box_object && ["create","update"].indexOf(action.__id.split("/")[1]) != -1){
              action.objectId = options.box_object;
              objectActions.push(action);
            }
          }
        }
        actions = objectActions;
      }
      if (actions) actions = await this.checkAndAddFunctions(actions);
      console.log("[BOX-HTTP] actions:", actions);
      return actions;
    } catch (e) {
      console.error("[BOX-HTTP] Error on get all functions:", e)
      throw e;
    }
  }

  async getBoxActions(boxId: string, key?: any, contype?: any, isClearCache: boolean = false) {
    var boxUrl = `/${boxId}/functions`;
    let boxToken = undefined;

    if(contype && contype == 'token'){
      boxToken = key;
    } else {
      if (key) boxToken = await this.connectionService.getBoxConfigToken(key)
    }

    console.log("boxToken", boxToken)
    console.log("[BOX-HTTP] get actions box key:", key);

    const headers = {
      boxConfigToken: boxToken,
      Authorization: `Bearer ${this.token.getStatelessToken()}`,
      "Content-Type": "application/json"
    }
    let chOptions: any = {keyPrefix: "box_actions_", isLocalStorage: false, clearCache: isClearCache, "avoidAuthKey": true};
    let baseUrl = await BoxInstance.getInstance( boxUrl );

    try {
      // let response: any = await this.execute(boxUrl, null, "get", boxToken);
      let response: any = await this.httpCacheService.get(baseUrl + boxUrl, {headers: headers}, chOptions);
      console.log("[BOX-HTTP] get actions box response:", response);
      let actions = response?.functions || null;
      // if (actions) {
      //   actions = actions.filter((fn: any) => fn.dataFlow == 'push');
      // }
      console.log("[BOX-HTTP] actions:", actions);
      return actions;
    } catch (e) {
      console.error("[BOX-HTTP] Error on get box actions:", JSON.stringify(e))
      throw e;
    }
  }


  async getObjectActions(boxId: string, boxObjectId: string, key?: any, contype?: any) {
    var boxUrl = `/${boxId}/${boxObjectId}/functions`;
    let boxToken = undefined;

    if(contype && contype == 'token'){
      boxToken = key;
    } else {
      if (key) boxToken = await this.connectionService.getBoxConfigToken(key)
    }

    console.log("boxToken", boxToken)

    try {
      let response: any = await this.execute(boxUrl, null, "get", boxToken);
      console.log("[BOX-HTTP] get actions response:", response);
      let actions = response?.functions || null;
      // if (actions) {
      //   actions = actions.filter((fn: any) => fn.dataFlow == 'push');
      // }
      console.log("[BOX-HTTP] actions:", actions);
      return actions;
    } catch (e) {
      console.error("[BOX-HTTP] Error on getting actions:", e)
      throw e;
    }
  }

  async checkAndAddFunctions(actions: any[]) {
    var actionsKeys = [];
    var array = JSON.parse(JSON.stringify(actions));
    await array.forEach((e) => { if (e?.__id.includes("/create")) actionsKeys.push(e.__id) });

    var adFunctions = [];
    for (var i = 0; i < array.length; i++) {
      var action = array[i];

      if (action?.__id.indexOf("/update") != -1) { //
        var object = action.__id.split("/")[0];
        if (actionsKeys.indexOf(object + "/create") != -1) {
          var actionCopy = JSON.parse(JSON.stringify(action));
          actionCopy.__id = object + "/save";
          actionCopy.name = "Save " + object.charAt(0).toUpperCase() + object.substr(1).toLowerCase();
          adFunctions.push(actionCopy);
        }
      }
    }
    actions = actions.concat(adFunctions);
    return actions;
  }

  async execute(url: string, payload?: any, method?: string, boxToken?: any, headerOptions?: any) {
    console.log("execute: url", url)
    let boxUrl = await BoxInstance.getInstance(url);

    if(BoxInstance.isAbsoluteUrl(url)){
      boxUrl = '';
    }

    var options: any
    if (headerOptions) {
      console.log("header options", headerOptions)
      options = headerOptions;
    } else {
      options = {
        headers: {
          Authorization: `Bearer ${await this.token.getStatelessToken()}`,
          "Content-Type": "application/json"
        }
      }
      if (boxToken) options.headers.boxconfigToken = boxToken;
    }

    console.log("[BOX] execute url", boxUrl + url)

    console.log("after assigning header", JSON.parse(JSON.stringify(options)))
    if(!options?.headers?.traceid && options?.headers) {
      options.headers.traceid = uuid();
      console.log("[BOX] execute traceid", options.headers?.traceid);
    }
    console.log("[BOX] execute options for url %s :", boxUrl + url, JSON.parse(JSON.stringify(options)))
    console.log("[BOX] execute payload", payload ? JSON.parse(JSON.stringify(payload)) : payload)

    let response: any;
    try {
      console.log("inside try")
      if (method == "get") {
        console.log("inside get")

        response = await this.httpCacheService.get(boxUrl + url, options, {"avoidAuthKey": true});
        // response = await this.http.get(boxUrl + url, options).toPromise();
      } else {
        console.log("inside other")
        if(this.httpCacheService.getBoxMethodFromEndpoint(url) == "get" ) {
          console.log("get method")
          response = await this.httpCacheService.post(boxUrl + url, payload, options, {"avoidAuthKey": true});
        } else {
          console.log("not get method")
          console.log("payload", payload)
          let finalUrl = (boxUrl + url).slice(0)
          console.log("url", finalUrl)
          payload = JSON.parse(JSON.stringify(payload || {}))
          options = JSON.parse(JSON.stringify(options || {}))
          console.log("options", JSON.parse(JSON.stringify(options)))
          try{
            response = await this.http.post(finalUrl, payload, options).toPromise();
          }catch(e){
            console.log("error:", e)
            throw e
          }
        }

      }
      console.log("[BOX-HTTP] execution response: ", response);
      return response
    } catch (e) {
      console.log("[BOX-HTTP] execution: ", e)
      throw e;
    }
  }

  async getBoxObjects(connectionId, boxId, isClearCache: boolean = false) {
    this._snackBar.dismiss();
    let token = this.token.getStatelessToken()
    let boxConfigToken = await this.connectionService.getBoxConfigToken(connectionId)
    console.log("token:", token);
    console.log("box_configToken", boxConfigToken);
    console.log("SelectedBox.__id:", boxId);
    const headers = {
      boxConfigToken: boxConfigToken,
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    }
    var responseData: any = [];
    let chOptions: any = {keyPrefix: "box_objects_", isLocalStorage: false, clearCache: isClearCache, "avoidAuthKey": true};
    let url = await BoxInstance.getInstance( `/${boxId}/objects`);
    // await this.http.get(url + `/${boxId}/objects`, { headers }).toPromise().then(response => {

    console.log("will call httpCacheService.get()")
    let response = await this.httpCacheService.get(url + `/${boxId}/objects`, { headers: headers }, chOptions)
    console.log(response);
    for (var box in response) {
      responseData.push(response[box]);
    }
    console.log("responseData in get objects", responseData);

    return responseData[0];
  }


  async executeBoxObjectFunction( connectionId, boxId, actionId, payload?: any, token?: any) {
    let boxConfigToken = (!connectionId && token) ? token : await this.connectionService.getBoxConfigToken(connectionId);
    var url = `/${boxId}/${actionId}`;
    console.log('[PAYLOAD]', payload);
    console.log('url', url);
    var result:any;
    try{
      var res: any = await this.execute(url, payload, "post", boxConfigToken);
      result = res.result;
    }catch(err){
      console.log("Error Message:", err);
      result = {
        error: err.error.message,
        status: err.error.status
      }
    }

    console.log(result);
    return result
  }

  async executeBoxFunction(connectionId, boxId, boxFunctionId, payload?: any, token?: any) {
    let boxConfigToken = (token) ? token : await this.connectionService.getBoxConfigToken(connectionId);
    console.log("[box service] executeBoxFunction: boxConfigToken received", boxConfigToken)
    var url = `/${boxId}/${boxFunctionId}`;
    console.log('[box service] executeBoxFunction: function:', boxFunctionId);
    // console.log('url', url);
    var result:any;
    try{
      var res: any = await this.execute(url, payload, "post", boxConfigToken);
      result = res.result;
    }catch(err){
      console.log("Error Message:", err);
      result = {
        error: err.error?.message,
        status: err.error?.status
      }
    }

    console.log(result);
    return result
  }

  async getEvents(boxId, connectionId, isClearCache: boolean = false){
    let token = this.token.getStatelessToken()
    let boxConfigToken = await this.connectionService.getBoxConfigToken(connectionId)
    console.log("token:", token);
    console.log("box_configToken", boxConfigToken);
    console.log("SelectedBox.__id:", boxId);
    const headers = {
      boxConfigToken: boxConfigToken,
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    }

    let chOptions: any = {keyPrefix: "box_events_", isLocalStorage: false, clearCache: isClearCache, "avoidAuthKey": true};
    try{
      let url = await BoxInstance.getInstance( `/${boxId}/events`);
      var responseData: any =  await this.httpCacheService.get(url + `/${boxId}/events`, { headers: headers }, chOptions);
    }catch(err){
      console.log(err);
    }
    if(responseData){
      console.log(responseData);
      return responseData.events;
    }
  }

  async getBox(boxId: string, connectionId: string){
    console.log("getBox hit for", boxId, "and connectionId", connectionId)
    let token = this.token.getStatelessToken()
    let boxConfigToken = await this.connectionService.getBoxConfigToken(connectionId)
    console.log("token:", token);
    console.log("box_configToken", boxConfigToken);
    console.log("SelectedBox.__id:", boxId);
    const headers = new HttpHeaders()
      .set('boxConfigToken', boxConfigToken)
      .set('Authorization', `Bearer ${token}`);

    try{
      var responseData:any =  await this.http
      .get(
        `${environment.BOX_URL}/${boxId}`,
        { headers }
      ).toPromise();
    }catch(err){
      console.log(err);
    }
    if(responseData){
      console.log("box fetched", responseData);
      return responseData;
    }
  }

}
