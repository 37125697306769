import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { TestWebhookComponent } from './test-webhook/test-webhook.component';

@Component({
  selector: 'app-trigger-webhook',
  templateUrl: './trigger-webhook.component.html',
  styleUrls: ['./trigger-webhook.component.scss']
})
export class TriggerWebhookComponent implements OnInit {

  @Input() editMode:boolean = true;
  @Input() "hideRibbon": boolean = false;
  @Input() "flowMap": any;
  @Output() "onConfigure" = new EventEmitter<any>();
  @Output() "saveSetup" = new EventEmitter<any>();
  @Output() "sourceFields" = new EventEmitter<any>();

  copyKeyButton:string = "Copy";
  constructor(
    private dialog: MatDialog,
  ) { }
  // { dataType: "string", name: "Current Date Time", __id: "datetime" }

  ngOnInit(): void {
    if(!this.flowMap.trigger.payload_data){
      this.flowMap.trigger.payload_data = [];
    }
    if(!this.flowMap.trigger.query_data){
      this.flowMap.trigger.query_data = [];
    }
    if(!this.flowMap.trigger.authenticate_map) this.flowMap.trigger.authenticate_map = {}
    if(!this.flowMap.trigger.method)this.flowMap.trigger.method = "GET";

    if(this.flowMap?.trigger_type == 'webhook'){
      this.emitSourceFields()
    }
  }
  getWebhookName(): string {
    if (this.flowMap.trigger.method === 'POST') {
      return 'Webhook (POST)';
    } else if (this.flowMap.trigger.method === 'PUT') {
      return 'Webhook (PUT)';
    } else if (this.flowMap.trigger.method === 'GET') {
      return 'Webhook (GET)';
    }else if (this.flowMap.trigger.method === 'PATCH') {
      return 'Webhook (PATCH)';
    }

    return 'Webhook';
  }

  async ngOnChanges(changes: SimpleChanges) {
    // console.log("changes in action", changes)
    console.log("changes in this.flowMap", this.flowMap)
    this.getWebhookUrl()
  }

  getWebhookUrl(){
    console.log("getWebhookUrl hit", this.flowMap)
    if (this.flowMap.code && this.flowMap.published_flow_id) { // new published flow
      this.flowMap.trigger.webhook_url = `${environment.SERVER_BASE_URL}/flow/webhook/${this.flowMap.published_flow_id}`
    } else if (this.flowMap._id) { // new unpublished or old flow
      this.flowMap.trigger.webhook_url = `${environment.SERVER_BASE_URL}/flow/webhook/${this.flowMap._id}`
    }
  }

  addNewAttribute(){
    this.flowMap.trigger.payload_data.push({
      dataType: "string", name: "", __id: ""
    })
    this.emitSourceFields()
  }

  emitSourceFields(){
    let sourceFields = this.flowMap.trigger.payload_data.concat(this.flowMap.trigger.query_data);
    this.sourceFields.emit(sourceFields);
  }

  setAttributeId(data){
    data.__id = "system." + data.name;
    this.emitSourceFields()
  }

  removePayloadAttribute(payloadData, i){
    payloadData.splice(i, 1);
    this.emitSourceFields()
  }

  addNewQueryAttribute(){
    this.flowMap.trigger.query_data.push({
      dataType: "string", name: "", __id: ""
    })
    this.emitSourceFields()
  }

  removeQueryAttribute(queryData, i){
    queryData.splice(i, 1);
    this.emitSourceFields()
  }

  async testWebhook(){
    await this.saveSetup.emit();
    this.emitSourceFields()
    var dialog = this.dialog.open(TestWebhookComponent, {
      maxWidth: '500px',
      minWidth: "300px",
      maxHeight: '500px',
      data: {
        flowMap: this.flowMap
      }
    });
    var diologResult = await dialog.afterClosed().toPromise();
  }

}
