import { Component, OnInit, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, Inject, PLATFORM_ID } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subject } from 'rxjs';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { PageService } from 'src/app/bloom/services/page-service.service';

import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';

@Component({
  selector: 'app-richtextinput',
  templateUrl: './richtextinput.component.html',
  styleUrls: ['./richtextinput.component.css'],
})
export class RichTextinputComponent extends BaseWidgetComponent implements OnInit, OnChanges {
  contextMenuActions: {};
  quillEditorRef: any;
  selectedcolor: any;
  value = '';
  hoveredNow: boolean = false;
  private destroy:any = new Subject();

  @ViewChild('menuTrigger') richTextMenuTrigger: MatMenuTrigger;
  isBrowser: boolean;

  constructor(public pageService: PageService, public metaService: MetaService,
    public resourcePermissionService: ResourcePermissionService,
    @Inject(PLATFORM_ID) platformId: Object,
    ) {
      super(metaService, pageService, resourcePermissionService)
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;

    // console.log('in richtext constructor', this.widgetMeta);
  }

  ngOnInit(): void {
    if(!this.isBrowser) return;
    console.log('in richtext onInit', JSON.parse(JSON.stringify(this.widgetMeta)));
    this.value = this.widgetMeta.config?.contentHtml?.value || ''
    // this.value = this.widgetMeta.config?.contentHtml?.value || this.value
    this.emitUserInput()
    this.destroy = this.metaService.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        this.action(contextActions)
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy.unsubscribe();
  }


  ngOnChanges(changes: SimpleChanges): void {
    // if (changes.selectedWidgetId && (changes.selectedWidgetId.currentValue !== this.widgetMeta.id)) {
    //   if (this.richTextMenuTrigger && this.richTextMenuTrigger.menuOpen) {
    //     this.richTextMenuTrigger.closeMenu()
    //   }
    // }
    if(changes.widgetMeta && changes.widgetMeta.currentValue){
      console.log("rich text onChanges", JSON.parse(JSON.stringify(changes.widgetMeta.currentValue.config.contentHtml.value)))
      this.widgetMeta = changes.widgetMeta.currentValue
      this.setContextActions()
    }
    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }
  }


  //----------------------------------- FUNCTIONS ---------------------------------------

  contentChange(event: any) {
    // console.log("rich text input received", event)
    // console.log("old widget meta", JSON.parse(JSON.stringify(this.widgetMeta)))

    if(this.widgetMeta.config.contentHtml && this.widgetMeta.config.contentHtml.hasOwnProperty('value')){
      this.widgetMeta.config.contentHtml.value = event.html
    }
    if(this.widgetMeta.config.textContent && this.widgetMeta.config.textContent.hasOwnProperty('value')){
      this.widgetMeta.config.textContent.value = event.text
    }
    this.value = event.html
    console.log("editor value", this.value)
    this.emitUserInput()
    // this.widgetMeta.outputHtml = event.html
    // console.log("new widget meta", JSON.parse(JSON.stringify(this.widgetMeta)))
    // this.newWidgetMeta.emit(this.widgetMeta)
    this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
  }

  setContextActions(){
    this.contextMenuActions = {
      actions: [
        'edit'
      ],
    };
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  action(event: any) {
    console.log('action received', event);
    if (event.actionType == 'delete') {
      this.onDelete();
    }
  }

  onClick(event) {
    if (!this.builderMode) {
      return;
    }
    // if(!this.richTextMenuTrigger.menuOpen){
    //   this.richTextMenuTrigger.openMenu()
    // }

    console.log('rich text widget clicked in widget');
    this.widgetSelection.emit(this.widgetMeta.id);
  }

  innerClick(event) {
    console.log('editor clicked');
    // this.richTextMenuTrigger.closeMenu();
    // console.log('closed menu', this.richTextMenuTrigger);
  }

  onDelete() {
    console.log('widget ID', this.widgetMeta.id, 'will be deleted');
    this.widgetDeletion.emit(this.widgetMeta.id);
    // this.richTextMenuTrigger.closeMenu();
  }

  onBlur(editor) {
    // console.log("editor", editor)
    // console.log("meta", this.widgetMeta.value)
    // this.newWidgetMeta.emit(this.widgetMeta);
  }

  getEditorInstance(editorInstance: any) {
    this.quillEditorRef = editorInstance;
    const toolbar = editorInstance.getModule('toolbar');
    toolbar.addHandler('color', (value: any) => {
      console.log(value);
      if (!value) {
        this.selectedcolor = '#000000';
      } else {
        this.selectedcolor = value;
      }
      this.quillEditorRef.format('color', value);
    });
  }

  onColorChanged($event: any) {
    this.quillEditorRef.format('color', $event.target.value);
  }

  emitUserInput(){
    let userInput: any = {
      dataBindConfig: this.widgetMeta?.dataBindConfig,
      widgetId: this.widgetMeta.id,
      value: this.widgetMeta.config?.contentHtml?.value || this.value
    }
    // console.log("emitter data:", userInput)
    this.userInputReceived.emit(userInput)
  }
}
