import { Component, Input, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { FormService } from 'src/app/form/form.service';

@Component({
  selector: 'app-preview-dialog',
  templateUrl: './preview-dialog.component.html',
  styleUrls: ['./preview-dialog.component.css'],
})
export class PreviewDialogComponent implements OnInit, OnDestroy {

  safeUrl: any;

  screenSizeArray = [
    { width: 360, height: 640, icon: "phone_iphone", landscape: '-10.5%', tooltip: 'Mobile' },
    { width: 768, height: 1024, icon: "tablet_android", landscape: '-6.5%', tooltip: 'Small Tablet' },
    { width: 1024, height: 1366, icon: "tablet_mac", landscape: '-5%', tooltip: 'Tablet' },
    { width: 911, height: 512, icon: "laptop_mac", tooltip: 'Laptop' },
    { width: 1280, height: 720, icon: "desktop_mac", tooltip: 'Desktop' },
    { icon: "devices_other", tooltip: 'Responsive Displays' }
  ];

  screenSizeButtonToggleValue = this.screenSizeArray[1];

  screenOrientation: 'portrait' | 'landscape' = 'portrait';

  deviceArray = [
    { name: "Responsive", icon: "devices" },
    { name: "iPhone 5/SE", width: 320, height: 568, icon: "phone_iphone", landscape: '-11%' },
    { name: "iPhone 6/7/8", width: 375, height: 667, icon: "phone_iphone", landscape: '-10%' },
    { name: "iPhone 6/7/8 Plus", width: 414, height: 736, icon: "phone_iphone", landscape: '-9%' },
    { name: "iPhone XR", width: 414, height: 896, icon: "phone_iphone", landscape: '-7.5%' },
    { name: "iPhone 12 Pro", width: 390, height: 844, icon: "phone_iphone", landscape: '-7.5%' },
    { name: "iPad Air", width: 820, height: 1180, icon: "tablet_mac", landscape: '-5.5%' },
    { name: "iPad Mini", width: 768, height: 1024, icon: "tablet_mac", landscape: '-6.5%' },
    { name: "MacBook Air", width: 911, height: 512, icon: "laptop_mac" },
   { name: "MacBook Pro 13", width: 975, height: 609, icon: "laptop_mac" },
    { name: "MacBook Pro 15", width: 1091, height: 686, icon: "laptop_mac_big" },
    { name: "MacBook Pro 16", width: 1137, height: 705, icon: "laptop_mac_big" },
    { name: "iMac 24 inch", width: 1280, height: 720, icon: "desktop_mac" },
    { name: "iMac 27 inch", width: 1469, height: 823, icon: "desktop_mac" },
    { name: "BlackBerry Z30", width: 360, height: 640, icon: "phone_android", landscape: '-10.5%' },
    { name: "Nexus 4", width: 384, height: 640, icon: "phone_android", landscape: '-10.5%' },
    { name: "Nexus 5X", width: 412, height: 732, icon: "phone_android", landscape: '-9%' },
    { name: "Nexus 7", width: 600, height: 960, icon: "tablet_android", landscape: '-6.5%' },
    { name: "Nexus 10", width: 800, height: 1280, icon: "tablet_android", landscape: '-5%' },
    { name: "Laptop with HiDPI screen", width: 720, height: 450, icon: "laptop_windows" },
    { name: "Laptop with MDPI screen", width: 640, height: 400, icon: "laptop_windows" },
    { name: "4K Display", width: 1920, height: 1080, icon: "tv" },
    { name: "5K Display", width: 2048, height: 1152, icon: "tv" }
  ];

  selectedDevice: any = this.deviceArray[0];

  private _height: number = 1024;
  get height() { return this._height }
  set height(v: number) {
    if (v == null) { this._height = 0; return }
    this._height = v;
  }

  private _width: number = 768;
  get width() { return this._width }
  set width(v: number) {
    if (v == null) { this._width = 0; return }
    this._width = v;
  }

  private _deviceType: string = 'tablet_mac';
  get deviceType() { return this._deviceType }
  set deviceType(v: string) {
    this._deviceType = v;
  }

  constructor(
    private dialogRef: MatDialogRef<PreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private _sanitizer: DomSanitizer,
    private formService: FormService,
    private metaService: MetaService
  ) {
    console.log("data", data);
  }

  async ngOnInit() {
    this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
    console.log("preview url", this.safeUrl);
    if(this.data?.parentType == "form" && this.formService.formMeta.value) await this.formService.updateForm()
    this.metaService.previewMode = true
  }

  ngOnDestroy(): void {
    this.metaService.previewMode = false
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  buttonToggleChange(event: any) {
    this.screenOrientationReset();
    console.log(event);
    this.screenSizeButtonToggleValue = event.value;

    if (event.value.icon == 'devices_other') { return }
    this.selectedDevice = this.deviceArray[0];
    this._height = event.value.height;
    this._width = event.value.width;
    this.deviceType = event.value.icon;
  }

  deviceSelectChange() {
    if (this.selectedDevice == this.deviceArray[0]) { return }
    this.screenOrientationReset();
    this._height = this.selectedDevice.height;
    this._width = this.selectedDevice.width;
    this.deviceType = this.selectedDevice.icon;
  }

  screenOrientationChange() {
    console.log('screen Orientation Change');
    this.screenOrientation = this.screenOrientation == 'portrait' ? 'landscape' : 'portrait';
    this.changeOrientation();
  }

  screenOrientationReset() {
    console.log('screen Orientation Reset');
    console.log(this.screenOrientation);
    if (this.screenOrientation == 'landscape') {
      this.changeOrientation();
    }
    this.screenOrientation = 'portrait';
  }

  changeOrientation() {
    this._height = this._height + this._width;
    this._width = this._height - this._width;
    this._height = this._height - this._width;
  }
}
