import { HttpClient, HttpHeaders } from "@angular/common/http";
import { of } from "rxjs";
import { environment } from "src/environments/environment";
import { TokenUtil } from "../services/TokenUtil.service";
import axios from "axios";
import { QueryResolver } from "./QueryResolver";

export class BoxCache {

   static appData: any[] = [];
   static isCacheReady = false;

    static boxObject:any = {};
    static boxAuthTypeMap:any = {};


    static setBoxCache(boxes:any){
      // console.log("[CACHEDBOX] setBoxCache", boxes)
      if(boxes && boxes.length){
            for(var i=0 ; i < boxes.length; ++i ){
                let entity:any = boxes[i]
                let object = {}
                const { __id,  name, type, status, connectorType, organization, categories, displayName, logo
                } = entity;
                object =  { __id,  name, type, status, connectorType, organization, categories, displayName, logo
                }
                this.boxObject[entity["__id"]] = object;
            }
      }
      // console.log("[CACHEDBOX] after set", this.boxObject)
    }

    static setBoxAuthTypeCache(AuthMap:any){
      this.boxAuthTypeMap = AuthMap
    }

    static getCacheMap(): any {
      return this.boxObject;
    }

    static getboxAuthTypeMap(): any {
      return this.boxAuthTypeMap;
    }

    static async loadBoxes(){
      await new BoxCache().loadAllBoxes();
    }

    static async fetchFromAssets(){
      let assetsResults = await fetch('assets/app-data.json');
      let json = await assetsResults.json();
      console.log("fetchFromAssets", json)
      let boxes = json?.result?.data || null;;
      return boxes;
    }

    static async fetchBoxFromServer(){
      let boxes = null;
      try{
        let url = `${environment.BOX_URL}/mongodb/box/get`;
        var token = await new TokenUtil().getStatelessToken();
        var options:any = { headers: { boxconfigToken: environment.DB_BOX_TOKEN, "Content-Type": "application/json" } }
        options.headers.Authorization = `Bearer ${token}`;
        let body = {parameters: {
          "query": {
            "page" : `1|1000|1`
          }
        }}
        var res:any =  await axios.post(url, body, options)
        boxes = res?.data?.result?.data || null;
      } catch(error){
        console.error('Error fetching app data', error);
        throw error;
      }
      return boxes;
    }

    async loadAllBoxes() {
      let that = BoxCache;
      if (that.isCacheReady) {
        // Return cached data
        return of(that.appData);
      } else {
        try{
          let boxes = await that.fetchFromAssets();
          if(!boxes){
            boxes = await that.fetchBoxFromServer();
          }
          that.appData = boxes;
          console.log('box cache result', that.appData);
          BoxCache.setBoxCache(that.appData);
          that.isCacheReady = true;
        } catch(error){
          console.error('Error fetching app data', error);
          return of([]);
        }
      }
    }

    static getBoxes(searchObj?: any): any[] {
      if(searchObj && Object.keys(searchObj).length > 0){
        let params: any = {};
        let boxes = JSON.parse(JSON.stringify(this.appData));
        if(searchObj.page){
          params.page = QueryResolver.getPage(searchObj.page);
        }
        if(searchObj.sort){
          params.sort = QueryResolver.getOrder(searchObj.sort);
        }
        if(searchObj.filter){
          params.filter = QueryResolver.getFilter(searchObj.filter);
        }
        // console.log("searchObj", searchObj);
        // console.log("params", JSON.stringify(params))
        let filterdBoxes = QueryResolver.queryApps(boxes, params);
        return filterdBoxes;
      } else {
        return this.appData;
      };
    }

    static isCacheInitialized(): boolean {
      return this.isCacheReady;
    }
}
