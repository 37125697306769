<div class="canvas-wrap"
[fxLayoutAlign]="form?.theme?.selectedTheme?.alignment ? form?.theme?.selectedTheme?.alignment + ' start' : 'center start'"
[ngStyle]="form?.theme?.selectedTheme?.style ? form.theme.selectedTheme.style : {'background-color': '#f5f5f5'}"

>

<!-- ngClass.gt-md="form-canvas-gt-md"
    ngClass.md="form-canvas-md"
    ngClass.lt-md="form-canvas-lt-md"
    ngClass.xs="form-canvas-xs" -->
  <div
    [ngStyle]="style ? style : {}"
    class="form-canvas"

    [ngStyle]="getMergedStyles()"
    [ngClass]="{
      'form-success': isSuccessPage
    }"
  >
    <form-canvas></form-canvas>
  </div>
</div>
