import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { FormService } from '../form.service';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import * as confetti from 'canvas-confetti';
import { ENTER, COMMA, SEMICOLON, SPACE } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MailService } from 'src/app/shared/services/mail.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { AdminService } from 'src/app/modules/admin/admin.service';


@Component({
  selector: 'app-publish-dialog',
  templateUrl: './publish-dialog.component.html',
  styleUrls: ['./publish-dialog.scss']
})
export class PublishDialogComponent implements OnInit {

  count = 200;
  defaults = {
    origin: { y: 0.7 }
  };

  myConfetti:any;
  publishSuccess: boolean = false;
  currentBaseUrl: string = '';
  publishedUrl: string = '';
  form: any;
  oldPublishedUrl: string = ''
  disabled: boolean = false
  publishSpinner: boolean = false
  publishDisabled: boolean = false
  isBrowser: any;
  templatePublishChecked: any;
  embedHtml: any
  embedWidth: any = "100%";
  embedHeight:any = "1200px";
  publishEmail: any = {
    inviteSubject: "Form ",
    validEmails: [],
    inviteMessage: "I've invited you to fill in a Form: "
  }
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON, SPACE];
  isValidEmail: any;
  failedChipPasteInput: string;
  spinner: boolean;
  templateScreenshots: any;
  templateBanner: any;

  constructor(
    public dialogRef: MatDialogRef<PublishDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar,
    private router: Router,
    private formService: FormService,
    private ngZone: NgZone,
    public mailService: MailService,
    public authService: AuthServiceService,
    private adminService: AdminService,
    @Inject(PLATFORM_ID) platformId?: Object
    ) {
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;
  }

  ngOnInit(): void {
    if(!this.isBrowser) return;

    console.log("[publish dialog] form", this.data.form)
    this.currentBaseUrl = window.location.protocol + '//' + window.location.hostname
    this.currentBaseUrl = window.location.port ? this.currentBaseUrl + ":" + window.location.port : this.currentBaseUrl
    // this.publishedUrl = '/form/p/' + this.data.form.code
    this.form = this.data.form;
    if(this.form.template) this.addDefaultImages();
    this.oldPublishedUrl = this.form.publishedUrl || "";
    this.formService.updateForm();
    this.publishEmail.inviteSubject = `Invitation to submit Form ` + this.form.name;
    this.publishEmail.inviteMessage = this.publishEmail.inviteMessage + `${this.getPublishedUrl()}`
    this.createEmbedHtml();
    console.log("oldPublishedURL", this.oldPublishedUrl)
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }



  initConfetti(){
    var myCanvas = document.createElement('canvas');
      myCanvas.style.cssText = 'width: 100vh;position:absolute;top:30%;z-index: 9999; pointer-events: none;'
      var myCanvasId = document.getElementById('publish-confetti');
      myCanvasId.prepend(myCanvas)
      this.myConfetti = confetti.create(myCanvas, {
        resize: true,
      });
  }

  async publishClicked(){
    this.publishSuccess = false
    this.publishSpinner = true
    this.initConfetti();

    try {
      let email = this.form.created_by;
      if(this.form.template){
        if(this.templateScreenshots?.length > 0){
          let screenshotLinks = [];
          for (let index = 0; index < this.templateScreenshots.length; index++) {
            const element = this.templateScreenshots[index];
            let res = await this.adminService.fileUpload({file: this.templateScreenshots[index].payload}, email);
            screenshotLinks.push(res);
          }
          if(!this.form.templateData.screenshots) this.form.templateData.screenshots = [];
          this.form.templateData.screenshots = this.form.templateData.screenshots.concat(screenshotLinks);
        }

        if(this.templateBanner?.payload && !this.form.templateData.bannerImage){
          let res = await this.adminService.fileUpload({file: this.templateBanner?.payload}, email);
          this.form.templateData.bannerImage = res;
        }

      }
      this.templateScreenshots = [];
      this.templateBanner = null;
    } catch (e){
      this._snackBar.open("Image upload failed, continuing form publishing", "", {duration: 1000 })
    }

    console.log("form for publish", this.form)



    if(this.oldPublishedUrl){
      // 1. fetch the form meta matched by code and status = published
      let publishedMeta = await this.formService.getFormByCode(this.form.code, null, "published")

      // 2. update from current meta into published meta
      Object.keys(this.form).forEach(key => {
        if(key !== "status" && key !== "_id"){
          publishedMeta[key] = this.form[key]
        } else if (!publishedMeta[key] && key !== "status" && key !== "_id"){
          publishedMeta[key] = this.form[key]
        }
      })
      // publishedMeta = this.form
      // publishedMeta["status"] = "published"

      // 3. update the published form
      let res
      try{
        console.log("published publishedMeta", publishedMeta)
        if(this.form['templateData'] && this.form.template){
          publishedMeta['template_data'] = this.form['templateData']
          publishedMeta['template_status'] = 'published'
        }else{
          delete publishedMeta['template_status']
        }
        res = await this.formService.updateForm(publishedMeta)
        console.log("published form saved", res)
        this.publishedUrl = "/form/p/" + this.form.code
      }catch(e){
        console.log("error in saving published form", e)
      }
    } else {
      // create duplicate form record with status as published
      let copy = JSON.parse(JSON.stringify(this.form))
      copy["status"] = "published"
      console.log("this.form in publish", this.form)
      if(this.form['templateData'] && this.form.template){
        copy['template_data'] = this.form['templateData']
        copy['template_status'] = 'published'
      }else{
        delete copy['template_status']
      }

      delete copy._id

      let createRes;
      try{
        createRes = await this.formService.create_form(copy)
        console.log("created published form", createRes)
      }catch(e){
        console.log("error in creating published form", e)
      }

      // attach publishedUrl in draft and save back
      this.publishedUrl = "/form/p/" + this.form.code
      this.form["publishedUrl"] = this.publishedUrl
      let updateRes
      try{
        updateRes = await this.formService.updateForm(this.form)
        console.log("updated draft with published url")
        this.oldPublishedUrl = this.form.publishedUrl
      }catch(e){
        console.log("error in updating published form", e)
      }
    }

    this.publishSpinner = false
    this.disabled = false
    this.publishDisabled = false
    this.publishSuccess = true
    this.createEmbedHtml();
    this.publishEmail.inviteSubject = `Invitation to submit Form ` + this.form.name;
    this.publishEmail.inviteMessage = this.publishEmail.inviteMessage + `${this.currentBaseUrl}${this.publishedUrl}`
    this.popStars();
      setTimeout(() => {
        this.popStars();
      }, 2000);
    let successMessage = this.form.name + " is published"
    this._snackBar.open(successMessage, "", {duration: 1000 })
  }

  getPublishedUrl(){
    let url = this.currentBaseUrl + this.oldPublishedUrl;
    if(this.publishSuccess) url =  this.currentBaseUrl + this.publishedUrl;
    return url;
  }

  async templatePublishCheckedEvent(e){
    this.form.template = e.checked
    this.templatePublishChecked = e.checked;
    this.addDefaultImages();
  }

  addDefaultImages(){
    let screenshots = [];
    if(this.formService?.capturedScreenshots?.screenshots && (!this.form.templateData || !this.form.templateData?.screenshots || this.form.templateData.screenshots.length == 0)){
      this.templateScreenshots = [];
      for (const key in this.formService.capturedScreenshots.screenshots) {
        console.log("key", key)
        if(!this.form.templateData?.bannerImage && !this.templateBanner && ["main", "introduction"].includes(key)) {
          this.templateBanner = this.formService.capturedScreenshots.screenshots[key];
        }
        screenshots.push(this.formService.capturedScreenshots.screenshots[key]);
      }
      this.templateScreenshots = screenshots;
    }
  }

  templateDataReceived(data){
    if(data !== null){
      console.log("template data", data)
      this.form['template'] = true
      this.form['templateData'] = data
    }
  }

  cancel(){
    this.dialogRef.close(false);
  }
  navigateToNewPublished(){
    window.open(this.getPublishedUrl(), '_blank')
  }

  copySuccessful(event){
    console.log("URL copied to clipboard")
    this._snackBar.open("URL copied to clipboard", "", {duration: 1000 })
  }

  copied(){
    this._snackBar.open("URL copied", "", {duration: 1000 })
  }

  async sendEmail(){
    this.spinner = true;
    if (!this.publishEmail.validEmails || this.publishEmail.validEmails.length == 0) {
      this._snackBar.open("To field is empty. Add some emails and try again")._dismissAfter(3000);
      this.spinner = false
      return;
    }
    let userProfile = this.authService.profile;
    let mailOptions = {
      fromEmail: userProfile.email,
      toEmail: this.publishEmail.validEmails,
      body: `<p>${this.publishEmail.inviteMessage}</p>`,//,
      subject: this.publishEmail.inviteSubject
    }
    await this.mailService.sendSesEmail(mailOptions, {});
    this.spinner = false
    this._snackBar.open("Email sent", "", {duration: 1000 })
  }

  navigateToDraft(){
    let urlSplits = this.router.url.split('#')[0].split('/')
    let pageCode = urlSplits[urlSplits.length - 1]
    let bloomCode = urlSplits[urlSplits.length - 2]

    let url = this.currentBaseUrl + '/p/' + bloomCode + '?v=draft'
    window.open(url, '_blank')
  }

  fire(particleRatio, opts) {
    this.myConfetti(Object.assign({}, this.defaults, opts, {
      particleCount: Math.floor(this.count * particleRatio)
    }));
  }
  popStars(){
    this.fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    this.fire(0.2, {
      spread: 30,
    });
    this.fire(0.35, {
      spread: 40,
      decay: 0.91,
      scalar: 0.8
    });
    this.fire(0.1, {
      spread: 40,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    });
    this.fire(0.1, {
      spread: 70,
      startVelocity: 45,
    });
  }

  createEmbedHtml(){
    this.embedHtml = `<iframe src="${this.getPublishedUrl()}" width = "${this.embedWidth}" height = "${this.embedHeight}"></iframe>`;
    this.ngZone.run(() => {});
  }

  copyEmbedUrlToClipboard(){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.embedHtml;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this._snackBar.open("Code copied")._dismissAfter(2000)
  }

   /*paste values for domain and email
  splits the values by seperator and valid
  refer https://medium.com/@sriram_in/angular-material-chips-add-chip-on-paste-event-ebce64fcfabd
  @param event evet
  @param type access type
  */
  pasteChipValue(event: ClipboardEvent, validEmails: any): void {
    console.log("event", event)
    this.failedChipPasteInput = "";
    var failedInput = [];
    event.preventDefault();
    event.clipboardData
    .getData('Text')
    .split(/[\s,;:\n\t]+/) // seprates with comma,colon,semicolon,space,tab,new line
    .forEach(value => {
      if(value.trim()){
        let inputValue = value.trim();
          var isValid = CheckIsValidEmail(inputValue)
          if(!isValid) this.isValidEmail = isValid;
          if (isValid) {
            inputValue = inputValue.toLowerCase();
            validEmails.push(inputValue);
          } else {
            failedInput.push(inputValue)
          }
        }
    })
    if(failedInput.length > 0) this.failedChipPasteInput = failedInput.join(",")
  }

  addChipValue(event: MatChipInputEvent, validEmails: any): void {
    this.failedChipPasteInput = "";
    const input = event.input;
    const value = event.value;
    console.log("event", event)
    // Add our value
    if ((value || '').trim()) {
      var inputValue = value.trim();
        var isValid = CheckIsValidEmail(inputValue)
        this.isValidEmail = isValid;
        if (isValid) {
          inputValue = inputValue.toLowerCase();
          validEmails.push(inputValue);
          inputValue = "";
        }
    }
    // set input value
    if (input) {
      input.value = inputValue;
    }
  }

  removeChipValue(input: any, validEmails: any): void {
    const index = this.isValidEmail.indexOf(input);
    if (index >= 0) {
      validEmails.splice(index, 1);
    }
  }

}

function CheckIsValidEmail(email) {
  var mailformat = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
  return email.match(mailformat);
}
