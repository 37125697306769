import { DatePipe, formatDate} from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, Inject, LOCALE_ID } from '@angular/core';
import { FlexAlignStyleBuilder } from '@ngbracket/ngx-layout';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { WidgetService } from 'src/app/bloom/services/widget-service.service';
import { ExpressionUtility } from 'src/app/shared/built-in-expression/expressionUtility';

import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { DateTime } from 'src/app/bloom/models/widgetClasses/datetime';
import { Subject } from 'rxjs';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { ThemeService } from '../../../../shared/services/theme.service'
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { WidgetUtilityService } from 'src/app/bloom/services/widget-utility.service';


@Component({
  selector: 'app-datetime',
  templateUrl: './datetime.component.html',
  styleUrls: ['./datetime.component.css']
})
export class DateTimeComponent extends BaseWidgetComponent implements OnInit, OnChanges {

  contextMenuActions: {};

  // dateTime: FormControl = new FormControl('');
  // dateTimeInput: FormControl = new FormControl('');

  value = ""
  hoveredNow: boolean = false;
  setDateformat: any;
  setTimeformat: any;
  oldMeta: any

  private destroy:any = new Subject();
  displayValue: any;
  constructor(
    public expressionUtility: ExpressionUtility,
    // public widgetService: WidgetService,
    public widgetUtilityService: WidgetUtilityService,
    public metaService: MetaService,
    public themeService: ThemeService,
    @Inject(LOCALE_ID) private locale: string,
    public pageService: PageService,
    public resourcePermissionService: ResourcePermissionService
  ) {
    super(metaService, pageService, resourcePermissionService)
    console.log("in date time constructor", this.widgetMeta)
  }

  ngOnInit(): void {
    super.ngOnInit()
    // console.log("in date time onInit", this.widgetMeta)
    console.log(this.themeService.currentLocale);
    this.initValue()

    this.destroy = this.metaService.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        console.log("context changed")
        this.initValue()
        this.action(contextActions)
      }
    })
  }

  initValue(){
    console.log("initValue, widgetMeta", JSON.parse(JSON.stringify(this.widgetMeta)))
    console.log("this.value", this.value)
    let timeZone = this.themeService.currentLocale.timeZone;
    this.setDateformat = this.themeService.currentLocale.dateFormat
    this.setTimeformat = this.themeService.currentLocale.timeFormat

    if(!this.setDateformat) this.setDateformat = "MMM-dd-yyyy";
    if(!this.setTimeformat) this.setTimeformat = "hh:mm a";

    if (this.widgetMeta.config?.expressionConfig?.id == '__currentdatetime__') {
      this.value = this.expressionUtility.resolveExpression(this.widgetMeta.config?.expressionConfig);
    } else {
      this.value = JSON.parse(JSON.stringify(this.value || this.widgetMeta?.config?.dateTimeValue?.value || ""));
      if(this.value?.endsWith("|date")) this.value = this.value.slice(0, -5);    // discard the '|date' if exists
      this.value = this.value.substring(0, 16)  // discard the seconds and miliseconds from iso date string
    }
    console.log("value", this.value)

    

    if (this.widgetMeta.config.viewOnly.value && this.value) {
      if(!this.builderMode) {
        let isoDate = new Date(this.value).toISOString()
        let datePart;
        let timePart;
        datePart = formatDate(isoDate, this.setDateformat, this.locale, timeZone)
        timePart = formatDate(isoDate, this.setTimeformat, this.locale, timeZone)
        this.displayValue = datePart + ' ' + timePart
      } else {
        this.displayValue = this.formatDateForDateTimeLocal(new Date(this.value).toISOString())
      }
      console.log("display value created", this.displayValue)
    } else {
      this.value = this.value || new Date().toISOString()
      this.displayValue = this.formatDateForDateTimeLocal(new Date(this.value).toISOString())
      this.emitUserInput()
    }
    // console.log("this.displayValue", this.displayValue)
    return this.displayValue
  }

  /**
   * to show the existing value in html input of type = datetime-local
   * @param inputDate 
   * @returns formatted date string
   */
  formatDateForDateTimeLocal(inputDate) {
    // Create a new Date object from the ISO string
    const date = new Date(inputDate);

    // Get the date components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    // Get the time components
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // Format the date and time in the required format
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  ngOnDestroy(): void {
    this.destroy.unsubscribe();
    // this.eventTrigger.unsubscribe();
  }

  ngDoCheck(): void {
    // console.log("do check fired: old", JSON.parse(JSON.stringify(this.oldMeta || {})), "new", JSON.parse(JSON.stringify(this.widgetMeta || {})))
    if((!this.oldMeta && this.widgetMeta) || (JSON.stringify(this.oldMeta) != JSON.stringify(this.widgetMeta))){
      // console.log("change detected in widgetMeta")
      this.oldMeta = JSON.parse(JSON.stringify(this.widgetMeta))
      this.initValue()
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log("onChanges----------date time", JSON.parse(JSON.stringify(changes.widgetMeta?.currentValue)))

    if (changes.widgetMeta && changes.widgetMeta.currentValue) {
      this.widgetMeta = changes.widgetMeta.currentValue
      this.setContextActions()

      // console.log("vlaue change in datetime", this.widgetMeta.config)

      let value
      if(this.widgetMeta.config?.dateTimeValue?.value == '__currentdatetime__'){
        value = this.expressionUtility.resolveExpression(this.widgetMeta.config?.expressionConfig)
        this.value = value

      } else {  // consider the existing value is in ISO string
        let val = this.widgetMeta.config?.dateTimeValue?.value || this.value;
        // if(!val) return
        this.initValue()
      }
      // console.log("final value", this.value)
    }
    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }
  }

  setContextActions(){
    this.contextMenuActions = {
      actions: [
        "edit",
      ],
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  action(event: any) {
    console.log("action received", event)
    if (event.actionType == "delete") {
      this.onDelete()
    }
  }

  onClick() {
    if (!this.builderMode) {
      return;
    }

    console.log("date widget clicked in widget")
    this.widgetSelection.emit(this.widgetMeta.id)
  }

  onDelete() {
    console.log("widget ID", this.widgetMeta.id, "will be deleted")
    this.widgetDeletion.emit(this.widgetMeta.id)
  }

  // dateTimeValueChange(v: string) {
  //   this.dateTime.setValue(this.datePipe.transform(v, 'dd-MM-YYYY hh:mm:ss'));
  // }

  newInput(event){
    console.log("event", event.srcElement.value)
    this.value = event.srcElement.value
    this.widgetMeta.config.dateTimeValue.value = event.srcElement.value;
    this.emitUserInput();
  }

  emitUserInput() {
    console.log("emitUserInput", this.value)
    let dateInput: any = {
      dataBindConfig: this.widgetMeta?.dataBindConfig,
      widgetId: this.widgetMeta.id,
      value: ``
    }

    if(!this.value) {
      dateInput.value = ''
    }else if(this.value == '__currentdatetime__'){
      dateInput.value = `${new Date().toISOString()}|date`; //this.value
      // this.displayValue = this.formatDateToDatetimeLocal(new Date())
      // this.displayValue = new Date().toISOString().slice(0,16)
    }else{
      // console.log("is custom format ?", this.widgetMeta.config.customOutputFormat?.enabled)
      if(this.widgetMeta.config.customOutputFormat?.enabled){
        let isoDate = new Date(this.value).toISOString()
        // this.displayValue = this.formatDateToDatetimeLocal(new Date(this.value))
        // this.displayValue = new Date(this.value).toISOString().slice(0,16)

        let timeZone = this.themeService.currentLocale.timeZone;
        let datePart = formatDate(isoDate, this.widgetMeta.config.customOutputFormat?.customDateFormat, this.locale, timeZone)
        let timePart = formatDate(isoDate, this.widgetMeta.config.customOutputFormat?.customTimeFormat, this.locale, timeZone)

        // console.log("date part", datePart)
        // console.log("time part", timePart)

        let formattedDateTime = datePart + ' ' + timePart
        dateInput.value = formattedDateTime
        console.log("formatted datetime is", formattedDateTime)
      }else{
        console.log("current value", this.value)
        dateInput.value = `${new Date(this.value).toISOString()}|date`
        // this.displayValue = this.formatDateToDatetimeLocal(new Date(this.value))
        // this.displayValue = new Date(this.value).toISOString().slice(0,16)
        console.log("final ISO date", dateInput.value)
      }
    }
    console.log("dateInput", dateInput);
    this.userInputReceived.emit(dateInput)
  }
  


}
